import WorkInfo from "components/WorkInfo";
import Layout from "pages/Layout";

export default function MaintenanceLobbyPage() {
  return (
    <Layout>
      <WorkInfo header="維修保養單" type="MAINTENANCE" />
    </Layout>
  );
}
