import { CompanyActions } from './action';
import { CompanyState } from './state';

export const initialState: CompanyState = {
  pagination: {
    currentPage: 1,
    from: 1,
    lastPage: 1,
    perPage: 10,
    to: 1,
    total: 1,
  },
  changesMade: false,
};

export default function companyReducer(state: CompanyState = initialState, action: CompanyActions) {
  switch (action.type) {
    case '@@Pagination/setCompanyPagination':
      return {
        ...state,
        pagination: action.data,
      };
    case '@@Pagination/setCompanyCurrentPage':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.currentPage,
        },
      };
    case '@@Pagination/resetCompanyPagination':
      return {
        ...state,
        pagination: { ...initialState.pagination },
      };
    case '@@Pagination/setCompanyChangesMade':
      return {
        ...state,
        changesMade: action.changesMade,
      };

    default:
      return state;
  }
}
