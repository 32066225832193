import axios, { AxiosResponse } from "axios";
import { NewTemplateIcon, PrintIcon } from "components/IconsOnly";
import SignatureSection from "components/parts/Document/SignatureSection";
import ViewCoHeader, { ViewCoFooter } from "components/parts/ViewcoHeader";
import "css/Document.css";
import { pageStyle } from "css/printCss";
import { ITemplateById } from "models/responseModels";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ReactToPrint from "react-to-print";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setChangesMade } from "redux/Document/action";
import { setMessageModal } from "redux/Loading/action";
import { setSelectedDocumentModalOpen } from "redux/ServiceDocument/action";
import { resetTemplateData, setTemplateContentArray, setTemplateData } from "redux/Template/action";
import { IRootState } from "store";
import { PostContent } from "./DocumentById";

interface TemplateByIdProps {
  isSelectionMode?: boolean;
}

export default function TemplateById({ isSelectionMode = false }: TemplateByIdProps) {
  const printRef = useRef<HTMLTableElement>(null);
  const [allFields, setAllFields] = useState<Array<ITemplateById>>([]);
  const [isSignatureNeeded, setIsSignatureNeeded] = useState(false);
  const [saveDocumentModalOpen, setSaveDocumentModalOpen] = useState(false);
  const [postContent, setPostContent] = useState<{ docName: string; docTypeId: number; fields: PostContent[] }>({
    docName: "",
    docTypeId: -1,
    fields: [],
  });
  const pathname = window.location.pathname.split("/");
  const documentId = pathname[pathname.length - 1];
  const history = useHistory();
  const dispatch = useDispatch();
  const templates = useSelector((state: IRootState) => state.templates);
  const templateData = templates.templateData;
  const templateContentArray = templates.templateContentArray;
  const selectedDocument = useSelector((state: IRootState) => state.newDocument.selectedDocument);

  useEffect(() => {
    const fetchTemplateById = async () => {
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/doc`);
        if (isSelectionMode) {
          url.searchParams.set("id", String(selectedDocument.templateId));
        } else {
          url.searchParams.set("id", String(documentId));
        }
        const res = await axios.get(url.toString());
        const result = await res.data;
        dispatch(setTemplateData(result));
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    };
    fetchTemplateById();
  }, [dispatch, documentId, selectedDocument.templateId, isSelectionMode]);

  useEffect(() => {
    const fetchAllFields = async () => {
      try {
        let res: AxiosResponse;
        if (isSelectionMode) {
          res = await axios.get(`/doc-content/${selectedDocument.templateId}`);
        } else {
          res = await axios.get(`/doc-content/${documentId}`);
        }
        const result = res.data.data;
        if (isSelectionMode) {
          if (selectedDocument.docTypeId) {
            setPostContent({
              docName: "",
              docTypeId: selectedDocument.docTypeId,
              fields: result.map(
                (i: {
                  contentId: number;
                  docFieldId: number;
                  fieldName: string;
                  fieldOrder: number;
                  content: string;
                }) => {
                  return {
                    docFieldId: i.docFieldId,
                    fieldName: i.fieldName,
                    content: i.content ?? "",
                    contentId: i.contentId,
                  };
                }
              ),
            });
          }
        } else {
          setAllFields(result);
        }
        dispatch(setTemplateContentArray(result));
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    };
    fetchAllFields();
    return () => {
      setAllFields([]);
      setPostContent({ docName: "", docTypeId: -1, fields: [] });
      dispatch(resetTemplateData());
    };
  }, [dispatch]);

  const handleEdit = (idx: number, value: string) => {
    if (isSelectionMode) {
      const newArr = postContent.fields.slice();
      newArr[idx] = {
        ...newArr[idx],
        content: value,
      };
      setPostContent({ ...postContent, fields: newArr });
    } else {
      const newArr = allFields.slice();
      newArr[idx] = {
        ...newArr[idx],
        content: value,
      };
      setAllFields(newArr);
    }
  };

  const handleUpdateContent = async (idx: number, id: number, content: string) => {
    const newArr = templateContentArray.slice();
    newArr[idx] = { ...newArr[idx], contentId: id, content };
    if (!templateContentArray[idx].content) {
      //originally empty
      try {
        await axios.post(`/doc-content/${documentId}`, {
          docFields: [{ docFieldId: templateContentArray[idx].docFieldId, content }],
        });
        dispatch(setTemplateContentArray(newArr));
        dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      } catch (error) {
        dispatch(handleAxiosError(error));
        dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
      }
    } else if (!content) {
      //finally empty
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/doc-content`);
        url.searchParams.append("id", String(id));
        await axios.delete(url.toString());
        dispatch(setTemplateContentArray(newArr));
        dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      } catch (error) {
        dispatch(handleAxiosError(error));
        dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
      }
    } else {
      try {
        await axios.put(`/doc-content`, {
          content: [{ contentId: id, content }],
        });
        dispatch(setTemplateContentArray(newArr));
        dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      } catch (error) {
        dispatch(handleAxiosError(error));
        dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
      }
    }
  };

  const postDocumentToOrder = async () => {
    const pathname = window.location.pathname.split("/");
    const orderId = pathname[pathname.length - 1];
    try {
      const res = await axios.post(`/doc/${orderId}`, {
        docInfo: {
          name: postContent.docName,
          docTypeId: postContent.docTypeId,
        },
      });
      const documentId = await res.data.id;
      await axios.post(`/doc-content/${documentId}`, {
        docFields: postContent.fields.map((i) => {
          return { docFieldId: i.docFieldId, content: i.content };
        }),
      });
      dispatch(setChangesMade(true));
      setSaveDocumentModalOpen(false);
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      handleExit();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  const handleExit = () => {
    if (isSelectionMode) {
      dispatch(setSelectedDocumentModalOpen(false));
    } else {
      history.goBack();
    }
  };

  return (
    <Container className="p-4 flex-column-start viewDocumentPageContainer">
      <Row className="mb-3 flex-row-between viewDocumentPageTopRow">
        <Col md={4} className="d-flex align-items-center">
          <FaArrowCircleLeft size={32} onClick={handleExit} className="pointer" />
        </Col>
        <Col md={4}>
          <div className="d-flex justify-content-center">
            <h5 style={{ marginBottom: 0, textAlign: "center" }}>
              {`文件 - ` + templateData.formNo}
              <br />
              {templateData.name}
            </h5>
          </div>
        </Col>
        <Col md={4} className="buttonContainer">
          <div className="signatureButtonContainer mx-2">
            <div className="toggleButtonContainer" onClick={() => setIsSignatureNeeded(!isSignatureNeeded)}>
              <div
                className="fullToggleButton"
                style={{
                  left: isSignatureNeeded ? "20px" : "-26px",
                }}
              >
                <div style={{ height: "100%", marginRight: "8px" }}>ON</div>
                <div className="toggleButton" style={{ background: isSignatureNeeded ? "#306ef4" : "#888" }} />
                <div style={{ height: "100%", marginLeft: "8px" }}>OFF</div>
              </div>
            </div>
            <div className="toggleButtonText">簽名欄</div>
          </div>
          <ReactToPrint
            bodyClass={"contentContainer"}
            pageStyle={pageStyle}
            content={() => printRef.current}
            documentTitle={templateData.formNo}
            trigger={() => (
              <div className="printButtonContainer mx-2">
                <div className="flex-center printIconContainer">
                  <PrintIcon />
                </div>
                <div className="toggleButtonText">列印文件</div>
              </div>
            )}
          />
          {isSelectionMode && (
            <div
              className="printButtonContainer mx-2"
              onClick={() => {
                setSaveDocumentModalOpen(true);
              }}
            >
              <div className="flex-center newTemplateButtonContainer">
                <NewTemplateIcon />
              </div>
              <div className="toggleButtonText">儲存文件</div>
            </div>
          )}
        </Col>
      </Row>
      <div className="A4paper">
        <table ref={printRef} id="viewcoTable" style={{ width: "100%" }}>
          <thead>
            <div id="viewCoHeader" className="full-width flex-row-start viewCoHeader">
              <ViewCoHeader />
            </div>
          </thead>
          <tbody className="flex-center" id="viewcoContent" style={{ width: "100%" }}>
            <div id="viewcoContent" style={{ width: "80%" }}>
              {isSelectionMode
                ? postContent.fields.map((item, idx) => {
                    return (
                      <tr
                        key={item.docFieldId}
                        className="d-flex formFields"
                        style={{ alignItems: "flex-start", pageBreakInside: "avoid" }}
                      >
                        <div
                          id={"fieldNameMargin"}
                          style={{
                            minWidth: "max-content",
                            color: "#888",
                          }}
                        >
                          {item.fieldName + " :"}
                        </div>
                        <TextareaAutosize
                          id={item.content ? "textAreaMargin" : "empty"}
                          className="inputStyle p-0 px-1 mx-1"
                          value={item.content}
                          onChange={(e) => handleEdit(idx, e.currentTarget.value)}
                        />
                      </tr>
                    );
                  })
                : allFields.map((item, idx) => {
                    const sameContent = !!templateContentArray[idx]
                      ? templateContentArray[idx].content === item.content
                      : true;
                    return (
                      <tr
                        key={item.docFieldId}
                        className="my-1 d-flex formFields"
                        style={{ alignItems: "flex-start", pageBreakInside: "avoid" }}
                      >
                        <div
                          style={{
                            minWidth: "max-content",
                            color: "#888",
                            paddingTop: "2px",
                          }}
                        >
                          {item.fieldName + " :"}
                        </div>
                        <TextareaAutosize
                          id={item.content ? "textAreaMargin" : "empty"}
                          className="inputStyle p-0 px-1 mx-1"
                          value={item.content}
                          onChange={(e) => handleEdit(idx, e.currentTarget.value)}
                        />
                        <Button
                          id="amendButton"
                          className="amendButton"
                          color="primary"
                          disabled={sameContent}
                          onClick={() => handleUpdateContent(idx, item.contentId, item.content)}
                        >
                          更改
                        </Button>
                      </tr>
                    );
                  })}
              <div style={{ height: "40px" }} />
              {isSignatureNeeded && <SignatureSection />}
            </div>
          </tbody>
          <tfoot>
            <div style={{ height: "100px" }}></div>
            <div id="viewCoFooter" className="full-width flex-row-start viewCoHeader">
              <ViewCoFooter />
            </div>
          </tfoot>
        </table>
      </div>
      {isSelectionMode && saveDocumentModalOpen && (
        <Modal size="md" isOpen={saveDocumentModalOpen}>
          <Container size="lg" className="p-4 d-flex flex-column justify-content-around" style={{ minHeight: "200px" }}>
            <Row>
              <Col className="flex-center">
                <h5>儲存文件</h5>
              </Col>
            </Row>
            <Row>
              <Col md={1} style={{ width: "max-content" }}>
                文件名稱
              </Col>
              <Col>
                <input
                  className="inputStyle"
                  value={postContent.docName}
                  onChange={(e) => {
                    setPostContent({ ...postContent, docName: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row className="flex-center mt-3">
              <Col md={6} className="flex-center">
                <Button style={{ width: "100%" }} onClick={() => setSaveDocumentModalOpen(false)}>
                  取消
                </Button>
              </Col>
              <Col md={6} className="flex-center">
                <Button color="primary" style={{ width: "100%" }} onClick={postDocumentToOrder}>
                  儲存
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>
      )}
    </Container>
  );
}
