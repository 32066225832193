import { AddIcon2, SearchIcon } from "components/IconsOnly";
import { push } from "connected-react-router";
import { A_ADD_CLIENT, A_ADD_COMPANY, A_ADD_ORDER, A_ADD_WORKER, A_ASSIGN_WORKER } from "models/authorities";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { IRootState } from "store";

interface ActionRowProps {
  lastPage: number;
  addItem: string | null;
  currentPage: number;
  setCurrentPageFunction: (args0: number) => void;
  isSelectionMode?: boolean;
  isNew?: boolean;
  modalIsOpen?: boolean;
  setModalIsOpen?: (args0: boolean) => void;
  searchText?: string;
  setSearchText?: (args0: string) => void;
  handleSearch?: () => void;
  placeholderText?: string;
  searchByStaff?: boolean;
  setSearchByStaff?: (args0: boolean) => void;
}
export const ActionRow = (props: ActionRowProps) => {
  const {
    lastPage,
    addItem,
    currentPage,
    setCurrentPageFunction,
    isSelectionMode = false,
    isNew = true,
    modalIsOpen,
    setModalIsOpen,
    searchText,
    setSearchText,
    handleSearch,
    placeholderText,
    searchByStaff = false,
    setSearchByStaff,
  } = props;
  const scrollRef = useRef<HTMLDivElement>(null);
  const [openSelectPage, setOpenSelectPage] = useState(false);
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const dispatch = useDispatch();

  const handleSelectPage = (pageNumber: number) => {
    dispatch(setCurrentPageFunction(pageNumber));
    setOpenSelectPage(false);
  };

  const handleAddNewWork = () => {
    if (!isNew && addItem === "工程單員工") {
      setModalIsOpen!(!modalIsOpen);
    }
    switch (addItem) {
      case "員工":
        dispatch(push("/admin/worker/new-worker"));
        break;
      case "客戶":
        dispatch(push(`/admin/client/new-client`));
        break;
      case "工作單":
        dispatch(push("/service/new-work"));
        break;
      case "保養單":
        dispatch(push("/maintenance/new-work"));
        break;
      case "文件類型":
        dispatch(push("/admin/document/new-document"));
        break;
      case "公司資料":
        dispatch(push("/admin/company/new-company"));
        break;
      default:
        break;
    }
  };

  const defineAddNewAuthorities = () => {
    switch (addItem) {
      case "員工":
        return A_ADD_WORKER.includes(userRole);
      case "客戶":
        return A_ADD_CLIENT.includes(userRole);
      case "工作單":
        return A_ADD_ORDER.includes(userRole);
      case "保養單":
        return A_ADD_ORDER.includes(userRole);
      case "文件類型":
        return false;
      case "公司資料":
        return A_ADD_COMPANY.includes(userRole);
      case "工程單員工":
        return A_ASSIGN_WORKER.includes(userRole);
      default:
        break;
    }
  };

  useEffect(() => {
    if (scrollRef) {
      scrollRef.current?.scrollTo(32 * (currentPage - 1), 0);
    }
  }, [currentPage]);

  const shoudAppearButton = ["/all-orders", "/service", "/maintenance", "/order-history"];

  return (
    <>
      <div className="p-2 mb-2 flex-row-start actionRow flex1 full-width" style={{ maxHeight: "100px" }}>
        <div className="flex-row-start flex1" style={{ position: "relative" }}>
          {lastPage > 1 && (
            <div className="flex-column-start" style={{ alignItems: "flex-start", maxWidth: "28vw" }}>
              {lastPage > 1 && (
                <section className="flex-row-around full-width">
                  <div
                    className="py-1 px-3 pointer"
                    style={{ boxShadow: "0 2px 4px #0004", borderRadius: "3px" }}
                    onClick={() => handleSelectPage(1)}
                  >
                    第一頁
                  </div>
                  <div
                    className="py-1 px-3 pointer"
                    style={{ borderRadius: "3px", boxShadow: "0 2px 4px #0004" }}
                    onClick={() => handleSelectPage(lastPage)}
                  >
                    最後一頁
                  </div>
                </section>
              )}
              <section
                className="flex-row-start mt-1 "
                style={{
                  width: "100%",
                  borderRadius: "4px",
                  border: "solid 1px #AAA0",
                }}
              >
                <div className="mx-2 flex-center" style={{ minWidth: "64px", whiteSpace: "nowrap" }}>
                  頁數
                </div>
                <div
                  ref={scrollRef}
                  className="p-2 flex-row-start"
                  style={{
                    scrollBehavior: "smooth",
                    overflowX: "auto",
                  }}
                >
                  {Array(lastPage)
                    .fill(0)
                    .map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          className="mx-1 flex-center pointer"
                          style={{
                            background: currentPage === idx + 1 ? "var(--navBackgroundColor)" : "#FFF",
                            color: currentPage === idx + 1 ? "var(--secondaryTextColor)" : "var(--primaryTextColor)",
                            minWidth: currentPage === idx + 1 ? "48px" : "24px",
                            height: "24px",
                            borderRadius: "3px",
                            transition: "all 0.3s",
                          }}
                          onClick={() => handleSelectPage(idx + 1)}
                        >
                          {idx + 1}
                        </div>
                      );
                    })}
                </div>
              </section>
            </div>
          )}
        </div>
        <div className="flex-center flex1">
          {shoudAppearButton.includes(window.location.pathname) && (
            <Button
              style={{ whiteSpace: "nowrap" }}
              className="pointer primaryColor mx-3"
              onClick={() => {
                if (setSearchByStaff && setSearchText) {
                  setSearchByStaff(!searchByStaff);
                  setSearchText("");
                }
              }}
            >
              {searchByStaff ? "以員工搜尋" : "預設搜尋"}
            </Button>
          )}
          {!searchByStaff ? (
            <input
              className="searchInput"
              placeholder={placeholderText ?? "Search..."}
              value={searchText}
              onKeyDown={(e) => {
                if (e.key === "Enter" && handleSearch) handleSearch();
              }}
              onChange={(e) => {
                if (setSearchText) setSearchText(e.target.value);
              }}
            />
          ) : (
            <input
              className="searchInput"
              placeholder={"員工名稱 ／ 員工電話"}
              value={searchText}
              onKeyDown={(e) => {
                if (e.key === "Enter" && handleSearch) handleSearch();
              }}
              onChange={(e) => {
                if (setSearchText) setSearchText(e.target.value);
              }}
            />
          )}
          <div className="pointer" style={{ marginLeft: "16px" }} onClick={handleSearch}>
            <SearchIcon />
          </div>
        </div>
        {defineAddNewAuthorities() && addItem && addItem !== "文件類型" && !isSelectionMode ? (
          <div className="flex-row-end pointer flex1" onClick={handleAddNewWork}>
            <AddIcon2 />
            <div style={{ padding: "8px" }}>{isNew ? `新增${addItem}` : `加入${addItem}`}</div>
          </div>
        ) : (
          <div className="flex1" />
        )}
      </div>
      {openSelectPage && <div className="clickElsewhere" onClick={() => setOpenSelectPage(false)} />}
    </>
  );
};
