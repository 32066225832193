import Layout from "pages/Layout";
import AddNewDocument from "./AddNewDocument";

export default function AddNewDocumentPage() {
  return (
    <Layout>
      <AddNewDocument header="建立文件類型" />
    </Layout>
  );
}
