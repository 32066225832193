import axios from "axios";
import "css/Form.css";
import { DISTRICTS, DistrictType } from "models/districtModels";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setClientChangesMade } from "redux/Client/action";
import { setMessageModal } from "redux/Loading/action";
import DistrictModal from "./parts/DistrictModal";
import FormHeader from "./parts/FormHeader";

interface IClientInfo {
  name: string;
  email: string;
  tel: string;
  contactPerson: string;
  district: DistrictType;
  address: string;
  clientType: string;
}

export default function NewClient() {
  const [districtSelectionOpen, setDistrictSelectionOpen] = useState(false);
  const [clientInfo, setClientInfo] = useState<IClientInfo>({
    name: "",
    email: "",
    contactPerson: "",
    tel: "",
    district: "CENTRAL_AND_WESTERN",
    address: "",
    clientType: "",
  });

  const handleExit = () => {
    history.goBack();
    setClientInfo({
      name: "",
      email: "",
      contactPerson: "",
      tel: "",
      district: "CENTRAL_AND_WESTERN",
      address: "",
      clientType: "",
    });
  };

  const redMark = (identifier: boolean) => {
    return {
      color: "red",
      width: !identifier ? "16px" : "0px",
      transition: "all 0.3s",
      opacity: !identifier ? 1 : 0,
    };
  };
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(`/clients`, {
        newClient: clientInfo,
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      dispatch(setClientChangesMade(true));
      handleExit();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  const handleSelectDistrict = (item: DistrictType) => {
    setClientInfo({ ...clientInfo, district: item });
    setDistrictSelectionOpen(false);
  };

  return (
    <Container className="p-3">
      <FormHeader offset={false} header={"新增客戶"} />
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2} className="d-flex">
            <div style={redMark(!!clientInfo.name)}>{"*"}</div>
            <div> 客戶名稱:</div>
          </Col>
          <Col>
            <input
              type="text"
              name="name"
              value={clientInfo.name}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, name: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2} className="d-flex">
            <div> 聯絡人: </div>
          </Col>
          <Col>
            <input
              type="text"
              name="contactPerson"
              value={clientInfo.contactPerson}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, contactPerson: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2} className="d-flex">
            <div> 聯絡電話: </div>
          </Col>
          <Col>
            <input
              type="text"
              name="tel"
              value={clientInfo.tel}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, tel: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 地區:</div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="pointer">{DISTRICTS[clientInfo.district] ?? "選擇地區"}</div>
            <div className="chooseButton" onClick={() => setDistrictSelectionOpen(true)}>
              選擇
            </div>
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2} className="d-flex">
            <div> 地址: </div>
          </Col>
          <Col>
            <input
              type="text"
              name="address"
              value={clientInfo.address}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, address: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2} className="d-flex">
            <div> 電郵地址: </div>
          </Col>
          <Col>
            <input
              type="email"
              name="email"
              value={clientInfo.email}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, email: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2} className="d-flex">
            <div> Customer Type: </div>
          </Col>
          <Col>
            <input
              type="text"
              name="clientType"
              value={clientInfo.clientType}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, clientType: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="d-flex flex-center">
          <Button style={{ width: "50%", margin: "8px" }} type="submit" color="primary" disabled={!clientInfo.name}>
            {"新增"}
          </Button>
        </Row>
      </form>

      <DistrictModal
        isOpen={districtSelectionOpen}
        handleSelectDistrict={handleSelectDistrict}
        handleClose={() => setDistrictSelectionOpen(false)}
      />
    </Container>
  );
}
