import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { AnyAction, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk, { ThunkDispatch as OldThunkDispatch } from 'redux-thunk';
import companyReducer from 'redux/Companies/reducer';
import { CompanyState } from 'redux/Companies/state';
import newDocumentReducer from 'redux/ServiceDocument/reducer';
import { NewDocumentState } from 'redux/ServiceDocument/state';
import templateReducer from 'redux/Template/reducer';
import { TemplateState } from 'redux/Template/state';
import viewWorkerModalReducer from 'redux/ViewWorkerModal/reducer';
import { ViewWorkerModalState } from 'redux/ViewWorkerModal/state';
import authReducer from './redux/Auth/reducer';
import { IAuthState } from './redux/Auth/state';
import clientReducer from './redux/Client/reducer';
import { ClientState } from './redux/Client/state';
import documentReducer from './redux/Document/reducer';
import { DocumentState } from './redux/Document/state';
import loadingReducer from './redux/Loading/reducer';
import { LoadingState } from './redux/Loading/state';
import orderByIdReducer from './redux/Order/reducer';
import { OrderByIdState } from './redux/Order/state';
import infoBarReducer from './redux/Routing/reducer';
import { InfoBarState } from './redux/Routing/state';
import workerReducer from './redux/Worker/reducer';
import { WorkerState } from './redux/Worker/state';

export const history = createBrowserHistory();
export type ThunkDispatch = OldThunkDispatch<IRootState, null, AnyAction>;

export interface IRootState {
  router: RouterState;
  auth: IAuthState;
  infoBar: InfoBarState;
  orderById: OrderByIdState;
  client: ClientState;
  worker: WorkerState;
  loading: LoadingState;
  documents: DocumentState;
  templates: TemplateState;
  newDocument: NewDocumentState;
  company: CompanyState;
  viewWorkerModal: ViewWorkerModalState;
}

const rootReducer = combineReducers({
  auth: authReducer,
  router: connectRouter(history),
  infoBar: infoBarReducer,
  orderById: orderByIdReducer,
  client: clientReducer,
  worker: workerReducer,
  loading: loadingReducer,
  documents: documentReducer,
  templates: templateReducer,
  newDocument: newDocumentReducer,
  company: companyReducer,
  viewWorkerModal: viewWorkerModalReducer,
});

// for REDUX middleware creation
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

declare global {
  /* tslint:disable:interface-name */
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk), applyMiddleware(routerMiddleware(history)))
);
