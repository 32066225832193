export const pageStyle = /*css*/ `
    @media page{
      #contentContainer{
        margin-right: 24px;
        margin-bottom: 24px;
        margin-left: 24px;
      }
    }
    @media print{
      #amendButton {
        display: none;
      }
      #eachPage {            
        width: 210mm;
        height: 289mm;
        max-width: 210mm;
        max-height: 289mm;
        margin-bottom: 0;
        padding: 0;
        margin: 0;
      }
      #viewcoTable {
        height: 297mm;
        width: 210mm;
      }
      #viewCoHeader {
        display: flex;
        width: 100%;
      }
      #viewCoFooter {
        display: flex;
        width: 100%;
        position: fixed;
        bottom: 0;
      }
      #empty { 
        align-self: flex-end;
        margin-top: 0;
      }
      #textAreaMargin {
        margin-top: 0;
      }
      #fieldNameMargin {
        margin-top: 3px;
      }
    }
  `;
