import { ClientActions } from './action';
import { ClientState } from './state';

export const initialState: ClientState = {
  pagination: {
    currentPage: 1,
    from: 1,
    lastPage: 1,
    perPage: 10,
    to: 1,
    total: 1,
  },
  changesMade: false,
  clientHistory: [],
  clientModalIsOpen: false,
  clientData: {
    area: 'HONG_KONG',
    address: '',
    contactPerson: '',
    district: 'CENTRAL_AND_WESTERN',
    email: '',
    id: 0,
    name: '',
    tel: '',
    clientType: '',
    updatedAt: '',
  },
};

export default function clientReducer(state: ClientState = initialState, action: ClientActions) {
  switch (action.type) {
    case '@@Pagination/setClientData':
      return {
        ...state,
        clientData: action.data,
      };
    case '@@Pagination/setClientModalOpen':
      return {
        ...state,
        clientModalIsOpen: action.isOpen,
      };
    case '@@Pagination/setClientHistory':
      return {
        ...state,
        clientHistory: action.data,
      };
    //pagination
    case '@@Pagination/setClientPagination':
      return {
        ...state,
        pagination: action.data,
      };
    case '@@Pagination/setClientCurrentPage':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.currentPage,
        },
      };
    case '@@Pagination/resetClientPagination':
      return {
        ...state,
        pagination: { ...initialState.pagination },
      };
    case '@@Pagination/setClientChangesMade':
      return {
        ...state,
        changesMade: action.changesMade,
      };
    //end of pagination

    default:
      return state;
  }
}
