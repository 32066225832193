import OrderHistory from "components/OrderHistory";
import Layout from "pages/Layout";

export default function OrderHistoryPage() {
  return (
    <Layout>
      <OrderHistory header="工作單記錄" type={null} />
    </Layout>
  );
}
