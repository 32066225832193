import ClientInformation from "components/ClientInformation";
import Layout from "pages/Layout";

export default function MaintenanceClientInformationPage() {
  return (
    <Layout>
      <ClientInformation header="客戶資料" />
    </Layout>
  );
}
