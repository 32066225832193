import axios from "axios";
import { CloseIcon, DeleteIcon, OpenListIcon } from "components/IconsOnly";
import { ActionRow } from "components/parts/ActionRow";
import DeleteModal from "components/parts/DeleteModal";
import FormHeader from "components/parts/FormHeader";
import { formatDateAndTime } from "helpers/date";
import { IDocumentTemplate } from "models/responseModels";
import { DIVISIONS } from "models/variables";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { resetDocumentData, setDocumentCurrentPage, setDocumentData } from "redux/Document/action";
import { DocumentData } from "redux/Document/state";
import {
  setNewDocumentModalOpen,
  setSelectedDocument,
  setSelectedDocumentModalOpen,
} from "redux/ServiceDocument/action";
import { IRootState } from "store";
import SortableTableHeaders from "./parts/SortableTableHeaders";

export interface IDocumentList {
  header: string;
  isSelectionMode?: boolean;
  searchText?: string;
  setSearchText?: (args0: string) => void;
  handleSearch?: () => void;
  sortByDirection?: { sortType: string; isDesc: boolean };
  setSortByDirection?: React.Dispatch<
    React.SetStateAction<{
      sortType: string;
      isDesc: boolean;
    }>
  >;
}
interface IDocumentTemplateList {
  docTypeId: number;
  templateId: number;
  templateName: string;
}

export default function DocumentList(props: IDocumentList) {
  const {
    header,
    isSelectionMode = false,
    searchText,
    setSearchText,
    handleSearch,
    sortByDirection,
    setSortByDirection,
  } = props;
  const [modal, setModal] = useState(false);

  const [expandList, setExpandList] = useState(-1);
  const [templateDetails, setTemplateDetails] = useState<IDocumentTemplateList[]>([]);
  const [deleteTarget, setDeleteTarget] = useState<{ name: string; id: number }>({ name: "", id: -1 });

  const sortableHeaders = isSelectionMode
    ? [
        { headerText: "文件類型編號", headerKey: "formNo" },
        { headerText: "文件類型名稱", headerKey: "name" },
        { headerText: "註解", headerKey: "description" },
        { headerText: "部門", headerKey: "division" },
        { headerText: "更新日期", headerKey: "updatedAt" },
      ]
    : [
        { headerText: "動作", headerKey: "" },
        { headerText: "文件類型編號", headerKey: "formNo" },
        { headerText: "文件類型名稱", headerKey: "name" },
        { headerText: "註解", headerKey: "description" },
        { headerText: "部門", headerKey: "division" },
        { headerText: "更新日期", headerKey: "updatedAt" },
      ];

  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const data = useSelector((state: IRootState) => state.documents.documentDataArray);
  const pagination = useSelector((state: IRootState) => state.documents.pagination);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSelect = (item: DocumentData) => {
    dispatch(setDocumentData(item));
    if (!isSelectionMode) {
      history.push(`/admin/document/${item.id}`);
    } else {
      dispatch(setSelectedDocumentModalOpen(true, "docType"));
    }
  };

  const handleSelectTemplate = (item: any) => {
    dispatch(setSelectedDocument(item));
    dispatch(setSelectedDocumentModalOpen(true, "template"));
  };

  useEffect(() => {
    dispatch(resetDocumentData());
    setExpandList(-1);
  }, [dispatch]);

  const fetchThisDocumentTemplates = async (docTypeId: number, idx: number) => {
    if (expandList !== idx) {
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/doc`);
        url.searchParams.set("docTypeId", docTypeId.toString());
        url.searchParams.set("template", "true");
        const res = await axios.get(url.toString());
        const result = res.data;
        setTemplateDetails(
          result.data.map((i: IDocumentTemplate) => {
            return { docTypeId: i.docTypeId, templateId: i.id, templateName: i.name };
          })
        );
        setExpandList(idx);
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    } else if (expandList === idx) {
      setExpandList(-1);
    }
  };
  const handleControlSort = (sortType: string) => {
    setExpandList(-1);
    if (sortByDirection && setSortByDirection) {
      if (sortByDirection.sortType === sortType) {
        setSortByDirection({ sortType, isDesc: !sortByDirection.isDesc });
      } else {
        setSortByDirection({ sortType, isDesc: true });
      }
    }
  };

  const placeholderText = "文件編號 / 文件類型名稱 / 註解";

  return (
    <div className="p-3 relative" style={{ overflowX: "hidden" }}>
      {!isSelectionMode ? (
        <FormHeader offset={false} header={header} />
      ) : (
        <Row className="m-3">
          <div
            className="d-flex justify-content-end pointer"
            style={{ position: "absolute", right: "48px" }}
            onClick={() => dispatch(setNewDocumentModalOpen(false))}
          >
            <CloseIcon />
          </div>
        </Row>
      )}
      <ActionRow
        lastPage={pagination.lastPage}
        addItem="文件類型"
        currentPage={pagination.currentPage}
        setCurrentPageFunction={setDocumentCurrentPage}
        isSelectionMode={isSelectionMode}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
        placeholderText={placeholderText}
      />
      {!data.length ? (
        <div className="my-2 flex-center full-width disableText">沒有文件</div>
      ) : (
        <div className="flex-center full-width" style={{ overflowX: "auto" }}>
          <div className={isSelectionMode ? "p-4 viewCoTable" : "p-2 viewCoTable"}>
            <SortableTableHeaders
              headers={sortableHeaders}
              sortControl={handleControlSort}
              currentSorting={sortByDirection}
            />
            <div className="tableBody relative">
              {isLoading ? (
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              ) : (
                data.map((item, idx) => {
                  return (
                    <div key={item.id}>
                      <div className="flex-center tableRow">
                        {isSelectionMode && (
                          <div
                            style={{ position: "absolute", left: "-16px" }}
                            onClick={() => fetchThisDocumentTemplates(item.id, idx)}
                          >
                            <OpenListIcon isInverted={expandList === idx} />
                          </div>
                        )}
                        {!isSelectionMode && (
                          <div className="flex-row-around tdActionContainer">
                            <div
                              className="flex-center tdIconContainer full-height"
                              onClick={() => {
                                setModal(!modal);
                                setDeleteTarget({ name: item.name, id: item.id });
                              }}
                            >
                              <DeleteIcon />
                            </div>
                          </div>
                        )}
                        <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                          {item.formNo || "-"}
                        </div>
                        <div className="tdMainItem" onClick={() => handleSelect(item)}>
                          {item.name || "-"}
                        </div>
                        <div className="tdLongItem" onClick={() => handleSelect(item)}>
                          {item.description || "-"}
                        </div>
                        <div className="flex-center tdDistrict" onClick={() => handleSelect(item)}>
                          {(item.division && DIVISIONS[item.division]) || "-"}
                        </div>
                        <div className="flex-center tdDateItem" onClick={() => handleSelect(item)}>
                          {formatDateAndTime(item.updatedAt) || "-"}
                        </div>
                      </div>
                      {isSelectionMode &&
                        expandList === idx &&
                        (templateDetails.length > 0 ? (
                          templateDetails.map((item) => {
                            return (
                              <div
                                key={item.templateId}
                                className="flex-center templateTableRow"
                                onClick={() => handleSelectTemplate(item)}
                              >
                                <div className="flex-center templateTdItem">{item.templateName}</div>
                              </div>
                            );
                          })
                        ) : (
                          <div className={`flex-center emptyContentTemplateTableRow`}>
                            <div className="flex-center templateTdItem">{"沒有範本"}</div>
                          </div>
                        ))}
                    </div>
                  );
                })
              )}
              <DeleteModal isOpen={modal} deleteTarget={deleteTarget} setModal={setModal} addItemString={"文件類型"} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
