import axios from "axios";
import { CloseIcon, PasswordIcon } from "components/IconsOnly";
import { formatDate } from "helpers/date";
import { highLevel } from "models/authorities";
import { DIVISIONS } from "models/variables";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Modal, Row, Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setMessageModal } from "redux/Loading/action";
import { setViewWorkerModalOpen } from "redux/ViewWorkerModal/action";
import { IRootState } from "store";
import TableHeaders from "./TableHeaders";

export default function ViewWorkerModal() {
  const [areYouSureModal, setAreYouSureModal] = useState({ isOpen: false, message: "" });
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const viewWorkerModal = useSelector((state: IRootState) => state.viewWorkerModal);
  const workerHistory = viewWorkerModal.history;
  const isOpen = viewWorkerModal.isOpen;
  const data = viewWorkerModal.data;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setViewWorkerModalOpen(false));
  };

  const handleResetUserPassword = async () => {
    try {
      await axios.put(`/account/reset-password`, {
        userId: data.id,
      });
      dispatch(setMessageModal({ isOpen: true, content: "帳戶密碼已重設為電話號碼" }));
      handleExit();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };
  const handleExit = () => {
    setAreYouSureModal({ isOpen: false, message: "" });
  };

  const tableHeaders = ["工作單編號", "客戶名稱", "部門", "服務質量", "技術", "溝通", "安全", "開始日期", "結束日期"];

  return (
    <Modal size="xl" isOpen={isOpen} style={{ minWidth: "800px" }}>
      <div className="p-3 relative" style={{ overflowX: "hidden" }}>
        <div className="absolute pointer" style={{ top: "1em", right: "1em" }} onClick={handleClose}>
          <CloseIcon />
        </div>
        <div className="p-2 full-width flex-center">
          <h4 style={{ textAlign: "center" }}>員工資料</h4>
        </div>
        <div className="flex-center full-width relative">
          <div className="full-width">
            <SectionRow keyText={"員工編號"} valueText={data.staffNumber} />
            <SectionRow keyText={"中文姓名"} valueText={data.chiName} />
            <SectionRow keyText={"英文姓名"} valueText={data.engName} />
            <SectionRow keyText={"所屬公司"} valueText={data.company} />
            <SectionRow keyText={"電話號碼"} valueText={data.mobile} />
            <SectionRow keyText={"工作單數目"} valueText={workerHistory.length.toString()} />
          </div>
          {highLevel.includes(userRole) && (
            <div
              className="flex-column-start p-2 absolute pointer"
              style={{ right: "2%" }}
              onClick={() =>
                setAreYouSureModal({
                  isOpen: true,
                  message: `確認重設 ${data.staffNumber ?? data.chiName}	的帳戶密碼？`,
                })
              }
            >
              <div className="flex-center printIconContainer">
                <PasswordIcon />
              </div>
              <div className="my-2">{"重設帳戶密碼"}</div>
            </div>
          )}
        </div>
        <div className="p-2 full-width flex-column-center">
          <h4 style={{ textAlign: "center" }}>工作歷史</h4>
          <div style={{ textAlign: "center" }}>{"( 評分以5分為滿分 )"}</div>
        </div>
        {!workerHistory.length ? (
          <div className="my-2 flex-center full-width disableText">沒有工作記錄</div>
        ) : (
          <div className="flex-center full-width" style={{ overflowX: "auto" }}>
            <div className="p-2 viewCoTable">
              {isLoading ? (
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              ) : (
                <>
                  <TableHeaders headers={tableHeaders} />
                  <div className="tableBody relative">
                    {workerHistory.map((item, idx) => {
                      return (
                        <div key={idx} className={`flex-center tableRow cursor-default pointer-events-none`}>
                          <div className="flex-center tdItem">{item.orderNumber}</div>
                          <div className="flex-center tdItem">{item.clientName}</div>
                          <div className="flex-center tdDistrict">{DIVISIONS[item.division]}</div>
                          <div className="flex-center tdItem">{item.serviceQuality || " - "}</div>
                          <div className="flex-center tdItem">{item.technical || " - "}</div>
                          <div className="flex-center tdItem">{item.communication || " - "}</div>
                          <div className="flex-center tdItem">{item.safety || " - "}</div>
                          <div className="flex-center tdItem">
                            {item.startDate ? formatDate(item.startDate) : " - "}
                          </div>
                          <div className="flex-center tdItem">
                            {item.completeDate ? formatDate(item.completeDate) : " - "}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal centered isOpen={areYouSureModal.isOpen}>
        <Container className="p-3 flex-column-center">
          <Row className="my-2 mb-3 flex-center" style={{ fontSize: "20px" }}>
            {areYouSureModal.message}
          </Row>
          <Row className="my-2 d-flex full-width">
            <Col className="w-40">
              <Button className="full-width" onClick={() => setAreYouSureModal({ isOpen: false, message: "" })}>
                取消
              </Button>
            </Col>
            <Col className="w-40">
              <Button color="primary" className="full-width" onClick={handleResetUserPassword}>
                確認重設
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal>
    </Modal>
  );
}

interface OrderRowProps {
  keyText: string;
  valueText: string;
}

export function SectionRow(props: OrderRowProps) {
  const { keyText, valueText } = props;

  return (
    <Row className="my-2 flex-center" style={{ minHeight: "24px", alignItems: "flex-start" }}>
      <Col
        md={2}
        className="flex-center"
        style={{
          overflowWrap: "anywhere",
          alignItems: "flex-start",
          justifyContent: "flex-end",
          textAlign: "right",
        }}
      >
        <div>{keyText + ": "}</div>
      </Col>
      <Col
        md={9}
        className="flex-row-start"
        style={{
          alignItems: "flex-start",
          overflowWrap: "anywhere",
          textAlign: "left",
        }}
      >
        <div>{valueText}</div>
      </Col>
    </Row>
  );
}
