export const setIsLoading = (isLoading: boolean) => {
  return {
    type: '@@Loading/setIsLoading' as const,
    isLoading,
  };
};
export const setMaxRow = (row: number) => {
  if (row < 1) {
    row = 1;
  }
  return {
    type: '@@Loading/setMaxRow' as const,
    row,
  };
};
export const setUserPreferredRow = (row: number | null) => {
  return {
    type: '@@Loading/setUserPreferredRow' as const,
    row,
  };
};
export const setMessageModal = (data: { isOpen: boolean; content?: string }) => {
  return {
    type: '@@Loading/setMessageModal' as const,
    data,
  };
};

type ActionCreators = typeof setIsLoading | typeof setMaxRow | typeof setUserPreferredRow | typeof setMessageModal;

export type LoadingActions = ReturnType<ActionCreators>;
