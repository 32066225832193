import { IPagination } from '../../models/responseModels';

export const setWorkerPagination = (data: IPagination) => {
  return {
    type: '@@Pagination/setWorkerPagination' as const,
    data,
  };
};

export const setWorkerCurrentPage = (currentPage: number) => {
  return {
    type: '@@Pagination/setWorkerCurrentPage' as const,
    currentPage,
  };
};
export const resetWorkerPagination = () => {
  return {
    type: '@@Pagination/resetWorkerPagination' as const,
  };
};
export const setWorkerChangesMade = (changesMade: boolean) => {
  return {
    type: '@@Pagination/setWorkerChangesMade' as const,
    changesMade,
  };
};

type ActionCreators =
  | typeof setWorkerPagination
  | typeof setWorkerCurrentPage
  | typeof resetWorkerPagination
  | typeof setWorkerChangesMade;

export type WorkerActions = ReturnType<ActionCreators>;
