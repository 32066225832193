import NewCompany from "components/NewCompany";
import Layout from "pages/Layout";

export default function NewCompanyPage() {
  return (
    <Layout>
      <NewCompany />
    </Layout>
  );
}
