import { InfoBarTypes, InnerType } from './state';

export const setInfoBarType = (types: { infoBarType?: InfoBarTypes; innerType?: InnerType }) => {
  return {
    type: '@@infoBar/SetInfoBarType' as const,
    types,
  };
};
export const setInfoBarActive = (index: number) => {
  return {
    type: '@@infoBar/SetInfoBarActive' as const,
    index,
  };
};

type ActionCreators = typeof setInfoBarType | typeof setInfoBarActive;

export type InfoBarTypeActions = ReturnType<ActionCreators>;
