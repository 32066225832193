import Layout from "pages/Layout";
import React from "react";
import EditDocumentById from "./EditDocumentById";

export default function EditDocumentPage() {
  return (
    <Layout>
      <EditDocumentById header="修改文件類型" />
    </Layout>
  );
}
