import React from "react";

function SignatureSection() {
  return (
    <div className="A4PaperFooter flex-row-between my-4">
      <div style={{ width: "42%", maxHeight: "140px" }}>
        <div style={{ height: "80px" }}>Customer signature:</div>
        <div style={{ borderBottom: "solid 1px #aaa" }} />
      </div>
      <div style={{ width: "42%", maxHeight: "140px" }}>
        <div style={{ height: "80px" }}>ViewCo representative signature:</div>
        <div style={{ borderBottom: "solid 1px #aaa" }} />
      </div>
    </div>
  );
}

export default SignatureSection;
