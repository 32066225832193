import axios from "axios";
import { formatDateAndTime } from "helpers/date";
import { A_ADD_CLIENT } from "models/authorities";
import { FetchOrderHistoryByClientId, IClients, IPagination } from "models/responseModels";
import { AREAS } from "models/variables";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import {
  resetClientPagination,
  setClientChangesMade,
  setClientCurrentPage,
  setClientData,
  setClientHistory,
  setClientModalOpen,
} from "redux/Client/action";
import { setIsLoading } from "redux/Loading/action";
import { IRootState } from "store";
import { DeleteIcon, EditIcon } from "./IconsOnly";
import { ActionRow } from "./parts/ActionRow";
import DeleteModal from "./parts/DeleteModal";
import FormHeader from "./parts/FormHeader";
import SortableTableHeaders from "./parts/SortableTableHeaders";
import ViewClientModal from "./parts/ViewClientModal";
import { CSVLink } from "react-csv";

interface IClientInformation {
  header: string;
}

export default function ClientInformation({ header }: IClientInformation) {
  // const csvRef = useRef<any>(null);
  const [exportData, setExportData] = useState<Array<IClients>>([]);
  const [sortByDirection, setSortByDirection] = useState<{ sortType: string; isDesc: boolean }>({
    sortType: "updatedAt",
    isDesc: true,
  });
  const [modal, setModal] = useState(false);
  const [editTarget, setEditTarget] = useState<{ name: string; id: number }>({ name: "", id: -1 });
  const [allClients, setAllClients] = useState<Array<IClients>>([]);
  const [lastPage, setLastPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const history = useHistory();
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const sortableHeaders = A_ADD_CLIENT.includes(userRole)
    ? [
        { headerText: "動作", headerKey: "" },
        { headerText: "客戶名稱", headerKey: "name" },
        { headerText: "區域", headerKey: "area" },
        { headerText: "聯絡人", headerKey: "contactPerson" },
        { headerText: "聯絡電話", headerKey: "tel" },
        { headerText: "地址", headerKey: "address" },
        { headerText: "更新日期", headerKey: "updatedAt" },
      ]
    : [
        { headerText: "客戶名稱", headerKey: "name" },
        { headerText: "區域", headerKey: "area" },
        { headerText: "聯絡人", headerKey: "contactPerson" },
        { headerText: "聯絡電話", headerKey: "tel" },
        { headerText: "地址", headerKey: "address" },
        { headerText: "更新日期", headerKey: "updatedAt" },
      ];

  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);

  const dispatch = useDispatch();

  const pagination = useSelector((state: IRootState) => state.client.pagination);
  const changesMade = useSelector((state: IRootState) => state.client.changesMade);
  const global = useSelector((state: IRootState) => state.loading);
  const maxRow = global.maxRow;
  const userPreferredRow = global.userPreferredRow;

  const fetchAllClients = async (pageNumber: number) => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/clients`);
      if (userPreferredRow) {
        url.searchParams.set("rows", userPreferredRow.toString());
      } else {
        url.searchParams.set("rows", maxRow.toString());
      }
      url.searchParams.set("page", pageNumber.toString());
      if (!!searchText) url.searchParams.set("search", searchText);
      url.searchParams.set("order", `${sortByDirection.sortType}`);
      url.searchParams.set("direction", `${sortByDirection.isDesc ? "desc" : "asc"}`);
      const res = await axios.get<{ data: any; pagination: IPagination }>(url.toString());
      const result = res.data;
      setAllClients(result.data);
      setLastPage(result.pagination.lastPage);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
  const readyForExport = async () => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/clients`);
      url.searchParams.set("rows", "99999");
      const res = await axios.get(url.toString());
      const result = res.data;
      setExportData(result.data);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    readyForExport();
    if (changesMade) {
      fetchAllClients(1);
      dispatch(setClientCurrentPage(1));
      dispatch(setClientChangesMade(false));
    } else {
      fetchAllClients(pagination.currentPage);
    }

    return () => {
      dispatch(resetClientPagination());
    };
  }, []);

  useEffect(() => {
    if (modal) return;
    fetchAllClients(pagination.currentPage);
  }, [dispatch, pagination.currentPage, modal, maxRow, userPreferredRow]);

  const handleSelect = async (item: IClients) => {
    dispatch(setClientData(item));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders`);
      url.searchParams.set("clientId", item.id.toString());
      const res = await axios.get<{ data: FetchOrderHistoryByClientId[] }>(url.toString());
      const result = res.data;
      dispatch(setClientHistory(result.data));
      dispatch(setClientModalOpen(true));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const handleSearch = () => {
    dispatch(resetClientPagination());
    fetchAllClients(1);
  };

  const handleControlSort = (sortType: string) => {
    if (sortByDirection.sortType === sortType) {
      setSortByDirection({ sortType, isDesc: !sortByDirection.isDesc });
    } else {
      setSortByDirection({ sortType, isDesc: true });
    }
    fetchAllClients(1);
  };

  return (
    <div className="p-3 relative" style={{ overflowX: "hidden" }}>
      <FormHeader offset={false} header={header} />
      {/* <div
        className="absolute pointer px-3 py-2"
        style={{ top: 12, right: 32, borderRadius: "4px", boxShadow: "0 2px 4px #0004" }}
        onClick={() => csvRef.current.link.click()}
      >
        下載客戶名單
      </div> */}
      {/* <CSVLink className="d-none" filename={new Date().toLocaleString() + ".csv"} data={exportData} ref={csvRef} /> */}
      <ActionRow
        lastPage={lastPage}
        currentPage={pagination.currentPage}
        addItem={"客戶"}
        setCurrentPageFunction={setClientCurrentPage}
        handleSearch={handleSearch}
        searchText={searchText}
        setSearchText={setSearchText}
        placeholderText={"客戶名稱 / 電郵 / 聯絡人 / 電話 / 地址"}
      />
      {!allClients.length ? (
        <div className="my-2 flex-center full-width disableText">尚未加入客戶</div>
      ) : (
        <div className="flex-center full-width">
          <div className="p-2 viewCoTable">
            <SortableTableHeaders
              headers={sortableHeaders}
              sortControl={handleControlSort}
              currentSorting={sortByDirection}
            />
            <div className="tableBody relative">
              {isLoading ? (
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              ) : (
                allClients.map((item, idx) => {
                  return (
                    <div key={idx} className={`flex-center tableRow`}>
                      {A_ADD_CLIENT.includes(userRole) && (
                        <div className="flex-row-around tdActionContainer">
                          <div
                            className="flex-center tdIconContainer full-height"
                            onClick={() => {
                              history.push(`/admin/client/${item.id}`);
                            }}
                          >
                            <EditIcon />
                          </div>
                          <div
                            className="flex-center tdIconContainer full-height"
                            onClick={() => {
                              setModal(!modal);
                              setEditTarget({ name: item.name, id: item.id });
                            }}
                          >
                            <DeleteIcon />
                          </div>
                        </div>
                      )}
                      <div className="tdClientNameItem p-2" onClick={() => handleSelect(item)}>
                        {item.name || "-"}
                      </div>
                      <div className="flex-center tdDistrict" onClick={() => handleSelect(item)}>
                        {AREAS[item.area] || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.contactPerson || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.tel || "-"}
                      </div>
                      <div className="tdLongItem" onClick={() => handleSelect(item)}>
                        {item.address || "-"}
                      </div>
                      <div className="flex-center tdDateItem" onClick={() => handleSelect(item)}>
                        {formatDateAndTime(item.updatedAt)}
                      </div>
                    </div>
                  );
                })
              )}
              {A_ADD_CLIENT.includes(userRole) && (
                <DeleteModal isOpen={modal} deleteTarget={editTarget} setModal={setModal} addItemString={"客戶"} />
              )}
            </div>
          </div>
        </div>
      )}
      <ViewClientModal />
    </div>
  );
}
