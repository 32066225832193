import NewWorker from "components/NewWorker";
import Layout from "./Layout";

export default function NewWorkerPage() {
  return (
    <Layout>
      <NewWorker />
    </Layout>
  );
}
