import axios from "axios";
import DocumentList from "components/DocumentList";
import Layout from "pages/Layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAxiosError } from "redux/Auth/action";
import {
  resetDocumentPagination,
  setAllDocumentData,
  setChangesMade,
  setDocumentCurrentPage,
  setDocumentPagination,
} from "redux/Document/action";
import { IRootState } from "store";

export default function DocumentsPage() {
  const [searchText, setSearchText] = useState("");
  const [sortByDirection, setSortByDirection] = useState<{ sortType: string; isDesc: boolean }>({
    sortType: "updatedAt",
    isDesc: true,
  });
  const changesMade = useSelector((state: IRootState) => state.documents.changesMade);
  const dispatch = useDispatch();
  const pagination = useSelector((state: IRootState) => state.documents.pagination);
  const global = useSelector((state: IRootState) => state.loading);
  const maxRow = global.maxRow;
  const userPreferredRow = global.userPreferredRow;

  const fetchAllDocuments = async (pageNumber: number) => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/doc-types`);
      if (userPreferredRow) {
        url.searchParams.set("rows", userPreferredRow.toString());
      } else {
        url.searchParams.set("rows", maxRow.toString());
      }
      url.searchParams.set("page", pageNumber.toString());
      if (!!searchText) url.searchParams.set("search", searchText);
      url.searchParams.set("order", `${sortByDirection.sortType}`);
      url.searchParams.set("direction", `${sortByDirection.isDesc ? "desc" : "asc"}`);
      const res = await axios.get(url.toString());
      const result = res.data;
      dispatch(setAllDocumentData(result.data));
      dispatch(setDocumentPagination({ ...pagination, lastPage: result.pagination.lastPage }));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  useEffect(() => {
    if (changesMade) {
      fetchAllDocuments(1);
      dispatch(setDocumentCurrentPage(1));
      dispatch(setChangesMade(false));
    } else {
      fetchAllDocuments(pagination.currentPage);
    }
    return () => {
      dispatch(resetDocumentPagination());
    };
  }, [dispatch, changesMade]);

  useEffect(() => {
    fetchAllDocuments(pagination.currentPage);
  }, [dispatch, pagination.currentPage]);

  const handleSearch = () => {
    dispatch(resetDocumentPagination());
    fetchAllDocuments(1);
  };

  useEffect(() => {
    fetchAllDocuments(1);
  }, [sortByDirection.sortType, sortByDirection.isDesc, maxRow, userPreferredRow]);

  return (
    <Layout>
      <DocumentList
        header="文件類型列表"
        handleSearch={handleSearch}
        searchText={searchText}
        setSearchText={setSearchText}
        sortByDirection={sortByDirection}
        setSortByDirection={setSortByDirection}
      />
    </Layout>
  );
}
