import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Container, Row, Col, Button } from "reactstrap";
import { setMessageModal } from "redux/Loading/action";
import { IRootState } from "store";

function SaveSuccessModal() {
  const messageModal = useSelector((state: IRootState) => state.loading.messageModal);
  const dispatch = useDispatch();
  return (
    <Modal centered isOpen={messageModal.isOpen}>
      <Container className="p-3">
        <Row className="d-flex p-1 align-items-center">
          <Col md={8} style={{ textAlign: "center" }}>
            {messageModal.content}
          </Col>
          <Col md={4}>
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                dispatch(setMessageModal({ isOpen: false }));
              }}
            >
              OK
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

export default SaveSuccessModal;
