import Layout from "pages/Layout";
import React from "react";
import TemplateById from "./TemplateById";

export default function EditDocumentPage() {
  return (
    <Layout>
      <TemplateById />
    </Layout>
  );
}
