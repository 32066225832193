import React from "react";
import { Col, Container, Modal, Row } from "reactstrap";

function NeedLargerDeviceModal() {
  return (
    <Modal size="lg" centered isOpen={true}>
      <Container style={{ background: "#222" }}>
        <Row className="p-4 flex-column-center">
          <Col className="flex-center">
            <h5 style={{ color: "#EEE" }}>請使用更大的裝置</h5>
          </Col>
          <Col className="flex-center">
            <h6 style={{ color: "#EEE" }}>{"(最低 1024 x 768)"}</h6>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

export default NeedLargerDeviceModal;
