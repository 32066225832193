import axios from "axios";
import { MoveDownIcon, MoveUpIcon } from "components/IconsOnly";
import FormHeader from "components/parts/FormHeader";
import TableHeaders from "components/parts/TableHeaders";
import { push } from "connected-react-router";
import globalStyle from "css/global.module.scss";
import { DivisionType } from "models/variables";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ImBin } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setChangesMade } from "redux/Document/action";
import { IRootState } from "store";

interface IBasicData {
  header: string;
}

interface NewDoc {
  name: string;
  formNo: string;
  description: string;
  division: DivisionType | null;
}
interface NewFields {
  fieldName: string;
  fieldOrder: number;
}

const TABLE_WIDTH = "70%";

export default function AddNewDocument({ header }: IBasicData) {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<NewFields>();
  const [details, setDetails] = useState<NewFields>({ fieldName: "", fieldOrder: -1 });
  const [content, setContent] = useState<NewFields[]>([]);
  const autofocusRef = useRef<HTMLInputElement>(null);
  const [modal, setModal] = useState(false);
  const [newDocType, setNewDocType] = useState<NewDoc>({
    name: "",
    formNo: "",
    description: "",
    division: null,
  });

  const dispatch = useDispatch();
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);

  function confirmRow() {
    setContent([...content, details]);
    setDetails({ fieldName: "", fieldOrder: -1 });
    autofocusRef.current?.focus();
  }
  const handleDeleteRow = (idx: number) => {
    const newArr = content.slice();
    newArr.splice(idx, 1);
    setContent(newArr);
  };

  const setFieldOrders = () => {
    const newArr = content.slice();
    for (let i = 0; i < newArr.length; i++) {
      content[i].fieldOrder = i + 1;
    }
    setContent(newArr);
  };

  const handleSaveForm = async () => {
    if (content.length === 0) {
      return;
    }
    setFieldOrders();
    try {
      const res = await axios.post(`/doc-types`, { newDocType: newDocType });
      const documentId = res.data.id;
      await axios.post(`/doc-type-fields/${documentId}`, {
        newFields: content,
      });
      dispatch(setChangesMade(true));
      setModal(false);
      dispatch(push("/admin/document"));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };
  const handleMoveUp = (idx: number) => {
    if (idx === 0) return;
    const newArr = content.slice();
    newArr.splice(idx - 1, 0, ...newArr.splice(idx, 1));
    setContent(newArr);
  };
  const handleMoveDown = (idx: number) => {
    if (idx === content.length - 1) return;
    const newArr = content.slice();
    newArr.splice(idx + 1, 0, ...newArr.splice(idx, 1));
    setContent(newArr);
  };

  const headers = ["排序", "資料欄顯示", "動作"];
  const hasContent = content.length > 0;
  const isDirty = !details.fieldName.length;

  return (
    <div className="p-3" style={{ overflowY: "scroll", maxHeight: "90vh" }}>
      <FormHeader offset={false} header={header} />

      {!content.length ? (
        <div className="my-2 flex-center full-width disableText">尚未加入資料欄</div>
      ) : (
        <div className="viewCoTable" style={{ width: "100%" }}>
          <TableHeaders headers={headers} tableWidth={TABLE_WIDTH} />
          <div className="tableBody relative" style={{ width: TABLE_WIDTH }}>
            {isLoading ? (
              <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
            ) : (
              content &&
              content.map((element, idx) => {
                return (
                  <div key={idx} className="flex-center documentTableRow" style={{ position: "relative" }}>
                    <div className="flex-row-around tdActionContainer">{idx + 1 || "-"}</div>
                    <div className="flex-center tdItem">{element.fieldName || "-"}</div>
                    {/* <div className="flex-center tdItem">{element.defaultValue || "-"}</div> */}
                    <div className="flex-row-around tdActionContainer">
                      <div className={globalStyle["moveButton"]} onClick={() => handleMoveUp(idx)}>
                        <MoveUpIcon color={idx === 0 ? "#AAA" : "#444"} />
                      </div>
                      <div className={globalStyle["moveButton"]} onClick={() => handleMoveDown(idx)}>
                        <MoveDownIcon color={idx === content.length - 1 ? "#AAA" : "#444"} />
                      </div>
                      <div className={globalStyle["moveButton"]} onClick={() => handleDeleteRow(idx)}>
                        <ImBin size={24} />
                      </div>
                    </div>
                    <div className="d-flex" style={{ position: "absolute", right: 0 }}></div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
      <Row>
        <Card className="p-3">
          <Form onSubmit={handleSubmit(confirmRow)}>
            <FormGroup row className="mb-2">
              <Label sm={2}>{"資料欄顯示"}</Label>
              <Col sm={10}>
                <input
                  style={{ width: "100%" }}
                  type="text"
                  placeholder="e.g. 姓名, 員工編號"
                  ref={autofocusRef}
                  autoFocus={true}
                  value={details.fieldName}
                  onChange={(e) => setDetails({ ...details, fieldName: e.target.value })}
                />
                {errors.fieldName && <span className={globalStyle["alert"]}>請填寫此欄</span>}
              </Col>
            </FormGroup>
            {/* <FormGroup row className="mb-2">
              <Label sm={2}>預設值</Label>
              <Col sm={10}>
                <input
                  style={{ width: "100%" }}
                  type="text"
                  placeholder="可選擇填寫"
                  value={details.defaultValue}
                  onChange={(e) => setDetails({ ...details, defaultValue: e.target.value })}
                />
              </Col>
            </FormGroup> */}
            <Row className="d-flex justify-content-center">
              <Button style={{ width: "70%" }} type="submit" disabled={isDirty}>
                新增
              </Button>
            </Row>
          </Form>
        </Card>
      </Row>

      <Row className="my-4 pb-5 justify-content-center">
        <Button style={{ width: "70%" }} color={"primary"} disabled={!hasContent} onClick={() => setModal(!modal)}>
          儲存文件
        </Button>
      </Row>

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader>文件類型名稱</ModalHeader>
        <ModalBody>
          <Row className="align-items-center">
            <Col md={3} className="my-2 d-flex align-items-center">
              <Label>文件編號</Label>
            </Col>
            <Col>
              <input
                style={{ width: "100%" }}
                value={newDocType.formNo}
                onChange={(e) => setNewDocType({ ...newDocType, formNo: e.target.value })}
              />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={3} className="my-2 d-flex align-items-center">
              <Label>文件類型名稱</Label>
            </Col>
            <Col>
              <input
                style={{ width: "100%" }}
                value={newDocType.name}
                onChange={(e) => setNewDocType({ ...newDocType, name: e.target.value })}
              />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={3} className="my-2 d-flex align-items-center">
              <Label>描述</Label>
            </Col>
            <Col>
              <input
                style={{ width: "100%" }}
                value={newDocType.description}
                onChange={(e) => setNewDocType({ ...newDocType, description: e.target.value })}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveForm}>
            儲存
          </Button>
          <Button color="secondary" onClick={() => setModal(!modal)}>
            取消
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
