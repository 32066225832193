import WorkerInformation from "components/WorkerInformation";
import Layout from "pages/Layout";

export default function ServiceWorkerInformationPage() {
  return (
    <Layout>
      <WorkerInformation header="工程單員工資料" />
    </Layout>
  );
}
