import NewCompany from "components/NewCompany";
import Layout from "./Layout";

export default function NewWorkerPage() {
  return (
    <Layout>
      <NewCompany isNewCompany={false} />
    </Layout>
  );
}
