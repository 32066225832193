import { DownCaret } from "components/IconsOnly";
import { FormFields } from "components/NewWork";
import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { setNewOrderData } from "redux/Order/action";
import { IRootState } from "store";
import "css/Calendar.css";
import { setDateToStart } from "helpers/date";

function QuotationDetails() {
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const data = useSelector((state: IRootState) => state.orderById.newOrderData);
  const dispatch = useDispatch();
  const [openQuotationDetails, setOpenQuotationDetails] = useState(true);
  return (
    <>
      <div className={`my-3 flex-row-start`}>
        <div
          className="pointer"
          onClick={() => setOpenQuotationDetails(!openQuotationDetails)}
          style={{ transform: openQuotationDetails ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.4s" }}
        >
          <DownCaret />
        </div>
        <h5 className="m-0 mx-2">報價資料</h5>
      </div>
      <div
        style={{
          height: openQuotationDetails ? "240px" : 0,
          opacity: openQuotationDetails ? 1 : 0,
          transition: "all 0.4s",
        }}
      >
        {openQuotationDetails && (
          <>
            <Row>
              <FormFields keyText={"Bill to client:"} valueText={data.billToClient ?? ""} targetKey={"billToClient"} />
              <FormFields
                keyText={"Person in charge:"}
                valueText={data.personInCharge ?? ""}
                targetKey={"personInCharge"}
              />
              <FormFields keyText={"Customer Code:"} valueText={data.customerCode ?? ""} targetKey={"customerCode"} />
            </Row>
            <Row>
              <FormFields keyText={"Quotation No.:"} valueText={data.salesQuoteNo ?? ""} targetKey={"salesQuoteNo"} />
              <FormFields
                keyText={"Customer PO No.:"}
                valueText={data.externalDocumentNo ?? ""}
                targetKey={"externalDocumentNo"}
              />
              <FormFields
                keyText={"Contract Amount:"}
                valueText={data.contractAmount?.toString() ?? ""}
                targetKey={"contractAmount"}
              />
            </Row>
            <Row className="flex-row-start">
              <Col md={4} className={`p-2 full-width flex-row-column-start flex-column flex1`}>
                <div className="flex-row-center" style={{ width: "max-content" }}>
                  <h6 style={{ whiteSpace: "nowrap" }}>{"Expected start date:"}</h6>
                </div>
                <DatePicker
                  calendarType={"US"}
                  format={"y-MM-dd"}
                  minDate={userRole === "SUPER_ADMIN" ? undefined : setDateToStart(new Date(Date.now()).toISOString())}
                  onChange={(e: Date) => {
                    if (!e) {
                      dispatch(setNewOrderData({ ...data, expectedStartDate: null }));
                    } else {
                      if (
                        !!data.expectedCompleteDate &&
                        setDateToStart(data.expectedCompleteDate).valueOf() < setDateToStart(e.toISOString()).valueOf()
                      ) {
                        dispatch(
                          setNewOrderData({
                            ...data,
                            expectedStartDate: e.toISOString(),
                            expectedCompleteDate: null,
                          })
                        );
                      } else {
                        dispatch(setNewOrderData({ ...data, expectedStartDate: e.toISOString() }));
                      }
                    }
                  }}
                  value={!data.expectedStartDate ? null : new Date(data.expectedStartDate)}
                  className="calendarPicker"
                  calendarClassName="calendar"
                />
              </Col>
              <Col md={4} className={`p-2 full-width flex-row-column-start flex-column flex1`}>
                <div className="flex-row-center" style={{ width: "max-content" }}>
                  <h6 style={{ whiteSpace: "nowrap" }}>{"Expected complete date:"}</h6>
                </div>
                <DatePicker
                  calendarType={"US"}
                  minDate={
                    !data.expectedStartDate
                      ? userRole === "SUPER_ADMIN"
                        ? undefined
                        : setDateToStart(new Date(Date.now()).toISOString())
                      : new Date(data.expectedStartDate)
                  }
                  format={"y-MM-dd"}
                  onChange={(e: Date) => {
                    if (!e) {
                      dispatch(setNewOrderData({ ...data, expectedCompleteDate: null }));
                    } else {
                      dispatch(setNewOrderData({ ...data, expectedCompleteDate: e.toISOString() }));
                    }
                  }}
                  value={!data.expectedCompleteDate ? null : new Date(data.expectedCompleteDate)}
                  className="calendarPicker"
                  calendarClassName="calendar"
                />
              </Col>
              <Col md={4} />
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default QuotationDetails;
