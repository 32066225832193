import { AllWorkersType } from "components/AssignWorker";
import { FormFields } from "components/NewWork";
import TableHeaders from "components/parts/TableHeaders";
import { setDateToStart, timeFormatter } from "helpers/date";
import { FetchAssignedWorkers } from "models/responseModels";
import { getServiceTypeText } from "models/serviceTypeText";
import {
  AREAS,
  DIVISIONS,
  DIVISIONS_MAPPING,
  DivisionType,
  ServiceTypes,
  SERVICE_TYPES,
  SERVICE_TYPES_MAPPING,
} from "models/variables";
import { useState } from "react";
import DatePicker from "react-date-picker";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner } from "reactstrap";
import { assignWorkerToList, removeWorkerFromList, setNewOrderData, setOrderByIdData } from "redux/Order/action";
import { IRootState } from "store";

type DropdownType = "" | "SERVICE_TYPE" | "DIVISION";

export default function NewAdminDetails() {
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const [dropdownType, setDropdownType] = useState<DropdownType>("");
  const [showServiceTypeText, setShowServiceTypeText] = useState(false);
  const data = useSelector((state: IRootState) => state.orderById.newOrderData);
  const dispatch = useDispatch();
  const isServiceTypeOpen = dropdownType === "SERVICE_TYPE";
  const isDivisionTypeOpen = dropdownType === "DIVISION";

  const handleSelectServiceType = (item: ServiceTypes) => {
    dispatch(setNewOrderData({ ...data, serviceType: item }));
    setDropdownType("");
    setShowServiceTypeText(false);
  };
  const handleSelectDivisionType = (item: DivisionType) => {
    dispatch(setNewOrderData({ ...data, division: item }));
    setDropdownType("");
  };
  const redMark = (identifier: boolean) => {
    return {
      color: "red",
      width: !identifier ? "16px" : "0px",
      transition: "all 0.3s",
      opacity: !identifier ? 1 : 0,
    };
  };

  return (
    <>
      <Row className={`my-2`}>
        <Col className={`p-2`}>
          <h5>行政資料</h5>
        </Col>
      </Row>
      <Row className={`my-1 full-width`}>
        <FormFields keyText={"ACJ 號碼 / P.O. 號碼:"} valueText={data.acjNo ?? ""} targetKey={"acjNo"} />
        <Col className={`p-2 flex-row-start flex1`}>
          <div style={redMark(!!data.division)}>{"*"}</div>
          <h6 className="m-0"> 部門: </h6>
          <div className="mx-3 d-flex align-items-center">
            <Dropdown
              isOpen={isDivisionTypeOpen}
              toggle={() => {
                if (isDivisionTypeOpen) {
                  setDropdownType("");
                } else {
                  setDropdownType("DIVISION");
                }
              }}
            >
              <DropdownToggle>
                {data.division ? DIVISIONS[data.division] : "-"}
                <MdKeyboardArrowDown
                  fill={"#EEE"}
                  size={24}
                  style={{
                    marginLeft: "16px",
                    transform: isDivisionTypeOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "all 0.4s cubic-bezier(0.9, 1, 0.3, 1.2)",
                  }}
                />
              </DropdownToggle>
              <DropdownMenu>
                {Object.values(DIVISIONS).map((item) => {
                  return (
                    <DropdownItem
                      key={item}
                      md={2}
                      className="d-flex flex-center districtSelectionItem"
                      onClick={() => {
                        handleSelectDivisionType(DIVISIONS_MAPPING.get(item));
                      }}
                    >
                      {item}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
        {/* <FormFields keyText={"消息來源:"} valueText={data.informedBy ?? ""} targetKey={"informedBy"} /> */}
      </Row>
      <Row className={`my-1 mb-2 full-width`}>
        <FormFields keyText={"消息來源:"} valueText={data.informedBy ?? ""} targetKey={"informedBy"} />
        <Col className={`p-2 flex-row-start flex1`}>
          <div style={redMark(!!data.serviceType)}>{"*"}</div>
          <h6 className="m-0"> 服務性質: </h6>
          <div className="mx-3 d-flex align-items-center relative">
            {data.serviceType && !!getServiceTypeText(data.serviceType) && showServiceTypeText && (
              <div className="absolute p-2 px-3 serviceTypeText">
                {data.serviceType && getServiceTypeText(data.serviceType)}
              </div>
            )}
            <Dropdown
              isOpen={isServiceTypeOpen}
              toggle={() => {
                if (isServiceTypeOpen) {
                  setDropdownType("");
                } else {
                  setDropdownType("SERVICE_TYPE");
                }
              }}
            >
              <DropdownToggle
                onMouseEnter={() => setShowServiceTypeText(true)}
                onMouseLeave={() => setShowServiceTypeText(false)}
              >
                {data.serviceType ? SERVICE_TYPES[data.serviceType] : "-"}
                <MdKeyboardArrowDown
                  fill={"#EEE"}
                  size={24}
                  style={{
                    marginLeft: "16px",
                    transform: isServiceTypeOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "all 0.4s cubic-bezier(0.9, 1, 0.3, 1.2)",
                  }}
                />
              </DropdownToggle>
              <DropdownMenu>
                {Object.values(SERVICE_TYPES).map((item) => {
                  return (
                    <DropdownItem
                      key={item}
                      md={2}
                      className="d-flex flex-center districtSelectionItem"
                      onClick={() => {
                        handleSelectServiceType(SERVICE_TYPES_MAPPING.get(item));
                      }}
                    >
                      {item}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row className={`my-1 full-width`}>
        <Col className={`p-2`}>
          <h6>施工日期:</h6>
          <DatePicker
            calendarType={"US"}
            minDate={userRole === "SUPER_ADMIN" ? undefined : setDateToStart(new Date(Date.now()).toISOString())}
            format={"y-MM-dd"}
            onChange={(e: Date) => {
              if (!e) {
                dispatch(setNewOrderData({ ...data, startDate: null }));
              } else {
                if (
                  !!data.completeDate &&
                  setDateToStart(data.completeDate).valueOf() < setDateToStart(e.toISOString()).valueOf()
                ) {
                  dispatch(setNewOrderData({ ...data, startDate: e.toISOString(), completeDate: null }));
                } else {
                  dispatch(setNewOrderData({ ...data, startDate: e.toISOString() }));
                }
              }
            }}
            value={!data.startDate ? null : new Date(data.startDate)}
            className="calendarPicker"
            calendarClassName="calendar"
          />
          <input
            className="timePicker mx-1"
            disabled={!data.startDate}
            type="time"
            value={!!data.startDate ? timeFormatter.format(new Date(data.startDate)) : undefined}
            onChange={(e) => {
              if (!data.startDate) {
                return;
              } else {
                const target = e.target.value.split(":"); // turn the user input into numbers
                const joinTime = new Date(data.startDate).setHours(parseInt(target[0]), parseInt(target[1]));
                dispatch(setNewOrderData({ ...data, startDate: new Date(joinTime).toISOString() }));
              }
            }}
          />
        </Col>
        <Col className={`p-2`}>
          <h6> 完成日期: </h6>
          <DatePicker
            calendarType={"US"}
            minDate={
              !data.startDate
                ? userRole === "SUPER_ADMIN"
                  ? undefined
                  : setDateToStart(new Date(Date.now()).toISOString())
                : new Date(data.startDate)
            }
            format={"y-MM-dd"}
            onChange={(e: Date) => {
              if (!e) {
                dispatch(setNewOrderData({ ...data, completeDate: null }));
              } else {
                dispatch(setNewOrderData({ ...data, completeDate: e.toISOString() }));
              }
            }}
            value={!data.completeDate ? null : new Date(data.completeDate)}
            className="calendarPicker"
            calendarClassName="calendar"
          />
          <input
            className="timePicker mx-1"
            disabled={!data.completeDate}
            type="time"
            value={!!data.completeDate ? timeFormatter.format(new Date(data.completeDate)) : undefined}
            onChange={(e) => {
              if (!data.completeDate) {
                return;
              } else {
                const target = e.target.value.split(":"); // turn the user input into numbers
                const joinTime = new Date(data.completeDate).setHours(parseInt(target[0]), parseInt(target[1]));
                dispatch(setNewOrderData({ ...data, completeDate: new Date(joinTime).toISOString() }));
              }
            }}
          />
        </Col>
      </Row>
      <Row className={`my-1 full-width`}>
        <FormFields
          keyText={"服務事項:"}
          useTextArea
          valueText={data.serviceSubject ?? ""}
          targetKey={"serviceSubject"}
        />
        <FormFields
          keyText={"服務報告:"}
          useTextArea
          valueText={data.serviceReport ?? ""}
          targetKey={"serviceReport"}
        />
      </Row>
    </>
  );
}

interface AssignListProps {
  assignList: FetchAssignedWorkers[] | AllWorkersType[];
  closeAction: (args0: boolean) => void;
  isEditMode?: boolean;
  isAssignMode?: boolean;
  setChanged?: (args0: boolean) => void;
  isNewOrderMode?: boolean;
  assignWorkerToNewOrder?: (args0: FetchAssignedWorkers) => void;
}
export const AssignList = (props: AssignListProps) => {
  const {
    setChanged,
    assignList,
    closeAction,
    isEditMode = false,
    isAssignMode = false,
    isNewOrderMode = false,
    assignWorkerToNewOrder,
  } = props;
  const orderData = useSelector((state: IRootState) => state.orderById.orderById);
  const dispatch = useDispatch();
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const assignWorkers = useSelector((state: IRootState) => state.orderById.assignWorkers);
  const newOrderAssignWorkers = useSelector((state: IRootState) => state.orderById.newOrderData.staffIds);
  const headers = ["員工編號", "中文姓名", "手機號碼", "所屬部門"];
  const assignHeaders = ["選擇", "員工編號", "中文姓名", "手機號碼", "區域", "所屬部門"];

  const handleSelectItem = (item: FetchAssignedWorkers | AllWorkersType) => {
    if (isNewOrderMode && assignWorkerToNewOrder) {
      assignWorkerToNewOrder(item as FetchAssignedWorkers);
      return;
    }
    if (isAssignMode) {
      if (assignWorkers.includes(item.id)) {
        dispatch(removeWorkerFromList(item.id));
      } else {
        dispatch(assignWorkerToList(item.id));
      }
      return;
    }
    if (isEditMode) {
      if (setChanged) {
        setChanged(true);
      }
      dispatch(
        setOrderByIdData({
          ...orderData,
          // assignedToUserId: item.id, assignedToUserName: item.chiName
        })
      );
    }
    closeAction(false);
  };

  return (
    <>
      {!assignList.length ? (
        <div className="my-2 flex-center py-2">沒有資料</div>
      ) : (
        <div className="flex-center full-width" style={{ overflowX: "auto" }}>
          <div className="p-2 viewCoTable">
            <TableHeaders headers={isAssignMode || isNewOrderMode ? assignHeaders : headers} />
            <div className="tableBody relative">
              {isLoading ? (
                <Row className="my-3 d-flex justify-content-center">
                  <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
                </Row>
              ) : (
                assignList.map((item, idx) => {
                  return (
                    <div
                      key={item + idx.toString()}
                      className={`flex-center tableRow`}
                      onClick={() => {
                        handleSelectItem(item);
                      }}
                    >
                      {(isNewOrderMode || isAssignMode) && (
                        <div className="flex-row-around tdActionContainer">
                          <input
                            type="checkbox"
                            readOnly
                            name={item.id.toString()}
                            checked={
                              isNewOrderMode
                                ? newOrderAssignWorkers?.includes(item.id)
                                : assignWorkers.includes(item.id)
                            }
                          />
                        </div>
                      )}
                      <div className="flex-center tdItem">{item.staffNumber || " - "}</div>
                      <div className="flex-center tdItem">{item.chiName || " - "}</div>
                      <div className="flex-center tdItem">{item.mobile || " - "}</div>
                      <div className="flex-center tdItem">{AREAS[item.area] || " - "}</div>
                      <div className="flex-center tdDistrict">{!!item.division ? DIVISIONS[item.division] : " - "}</div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
