import axios from "axios";
import { midLevel } from "models/authorities";
import { useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, Col, Container, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setMessageModal } from "redux/Loading/action";
import { clearNewOrderData, setNewOrderData } from "redux/Order/action";
import { IRootState } from "store";
import { CloseIcon, InformationIcon } from "./IconsOnly";
import FormHeader from "./parts/FormHeader";
import InformationModal from "./parts/InformationModal";
import NewAdminDetails from "./parts/NewOrder/NewAdminDetails";
import NewClientDetails from "./parts/NewOrder/NewClientDetails";
import NewOrderAssignWorker from "./parts/NewOrder/NewOrderAssignWorker";
import QuotationDetails from "./parts/NewOrder/QuotationDetails";

export default function NewWork() {
  const [showInformation, setShowInformation] = useState(false);
  const auth = useSelector((state: IRootState) => state.auth);
  const data = useSelector((state: IRootState) => state.orderById.newOrderData);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      if (!data.clientId) {
        dispatch(setMessageModal({ isOpen: true, content: "請選擇客戶" }));
        return;
      }
      if (!data.division) {
        dispatch(setMessageModal({ isOpen: true, content: "請選擇部門" }));
        return;
      }
      const res = await axios.post(`/orders`, {
        newOrder: data,
      });
      const newOrderId = await res.data.id;
      let assignedStaff: number[] = [];

      if (midLevel.includes(auth.role) && !data.staffIds?.includes(Number(auth.userId))) {
        assignedStaff = [Number(auth.userId)];
      }
      if (data.staffIds && data.staffIds.length) {
        assignedStaff = [...assignedStaff, ...data.staffIds];
      }
      if (!!assignedStaff.length) {
        await axios.post(`/order-staffs/${newOrderId}`, {
          staffIds: assignedStaff,
        });
      }
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      dispatch(clearNewOrderData());
      history.goBack();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  return (
    <Container className="p-3" style={{ overflowY: "auto", maxHeight: "92vh" }}>
      <FormHeader offset={false} header={"新增工作單"} />
      <div
        className="absolute pointer informationIconContainer"
        style={{ right: 40 }}
        onClick={() => setShowInformation(true)}
      >
        <InformationIcon />
      </div>
      <form className="px-4">
        <NewClientDetails />
        <NewAdminDetails />
        <NewOrderAssignWorker />
        <QuotationDetails />
        {/* <NewProgressCheck /> */}
        <Row className="mt-4 full-width">
          <Col className="d-flex align-items-center" md={6}>
            <h5 style={{ marginBottom: 0, minWidth: "max-content" }}>總收費:</h5>
            <div style={{ marginLeft: "8px" }}>$</div>
            <input
              className="inputStyle"
              style={{ width: "70%" }}
              onFocus={() => {
                if (!!data.totalPrice && data.totalPrice > 0) {
                  return;
                } else {
                  dispatch(setNewOrderData({ ...data, totalPrice: 0 }));
                }
              }}
              type="text"
              value={data.totalPrice === 0 ? 0 : data.totalPrice === null ? "-" : data.totalPrice}
              onChange={(e) => {
                if (parseInt(e.target.value) < 0) return;
                if (isNaN(parseInt(e.target.value))) {
                  dispatch(setNewOrderData({ ...data, totalPrice: null }));
                } else {
                  dispatch(setNewOrderData({ ...data, totalPrice: parseInt(e.target.value) }));
                }
              }}
            />
            <div
              onClick={() => dispatch(setNewOrderData({ ...data, totalPrice: null }))}
              style={{ boxSizing: "border-box", transform: "scale(0.7)", cursor: "pointer" }}
            >
              <CloseIcon />
            </div>
          </Col>
          <Col md={6} className="flex-row-start">
            <Button
              color="primary"
              disabled={!data.clientId || !data.division || !data.staffIds?.length || !data.serviceType}
              className="m-1"
              style={{ width: "40%" }}
              onClick={handleSubmit}
            >
              儲存
            </Button>
          </Col>
        </Row>
      </form>
      <InformationModal isOpen={showInformation} closeAction={() => setShowInformation(false)} />
    </Container>
  );
}

interface FormFieldsProps {
  keyText: string;
  valueText: string;
  targetKey: string;
  placeholderText?: string;
  isNumber?: boolean;
  useTextArea?: boolean;
}

export const FormFields = (props: FormFieldsProps) => {
  const { useTextArea = false } = props;
  const dispatch = useDispatch();
  const data = useSelector((state: IRootState) => state.orderById.newOrderData);
  return (
    <Col className={`p-2 full-width flex-row-column-start flex-column flex1`}>
      <div className="flex-row-center" style={{ width: "max-content" }}>
        <h6 style={{ whiteSpace: "nowrap" }}>{props.keyText}</h6>
      </div>
      {useTextArea ? (
        <TextareaAutosize
          rows={4}
          className="full-width p-0 px-1 mx-1"
          style={{ borderColor: "#AAA", borderRadius: "4px" }}
          value={props.valueText ?? ""}
          onChange={(e) => dispatch(setNewOrderData({ ...data, [props.targetKey]: e.currentTarget.value }))}
        />
      ) : (
        <input
          type={props.isNumber ? "number" : "text"}
          className="inputStyle"
          placeholder={props.placeholderText ?? ""}
          style={{ width: "100%" }}
          value={props.valueText ?? ""}
          onChange={(e) => {
            if (props.isNumber) {
              dispatch(setNewOrderData({ ...data, [props.targetKey]: parseInt(e.target.value) }));
            } else {
              dispatch(setNewOrderData({ ...data, [props.targetKey]: e.target.value }));
            }
          }}
        />
      )}
    </Col>
  );
};
