import { CloseIcon } from "components/IconsOnly";
import { getStatusDescription } from "models/progressStatusText";
import { getServiceTypeText } from "models/serviceTypeText";
import { ProgressStatusType, PROGRESS_STATUS, ServiceTypes, SERVICE_TYPES } from "models/variables";
import { Modal, Container, Row, Col } from "reactstrap";

interface InformationModalProps {
  isOpen: boolean;
  closeAction: () => void;
}
export default function InformationModal(props: InformationModalProps) {
  const LEFT_COLUMN_WIDTH = "88px";
  const SERVICE_TYPE_LEFT_COLUMN_WIDTH = "104px";
  return (
    <Modal isOpen={props.isOpen} style={{ minWidth: "fit-content" }}>
      <Container className="p-3">
        <Row>
          <Col className="flex-row-end pointer" onClick={props.closeAction}>
            <CloseIcon />
          </Col>
        </Row>
        <Row className="my-4 flex-center fit-content">
          <Col>
            <h5>進度</h5>
            <section className="mx-2">
              {Object.keys(PROGRESS_STATUS).map((item) => {
                if (!getStatusDescription(item as ProgressStatusType)) return <></>;
                return (
                  <div key={item} className="flex-row-start" style={{ whiteSpace: "nowrap" }}>
                    <div style={{ width: LEFT_COLUMN_WIDTH }}>{PROGRESS_STATUS[item as ProgressStatusType]} </div>
                    <div>{`：${getStatusDescription(item as ProgressStatusType)?.replaceAll("*", "")}`}</div>
                  </div>
                );
              })}
            </section>
          </Col>
        </Row>
        <Row className="mb-4 flex-center fit-content">
          <Col>
            <h5>服務性質</h5>
            <section className="mx-2">
              {Object.keys(SERVICE_TYPES).map((item) => {
                if (!getServiceTypeText(item as ServiceTypes)) return <></>;
                return (
                  <div key={item} className="flex-row-start" style={{ whiteSpace: "nowrap" }}>
                    <div style={{ width: SERVICE_TYPE_LEFT_COLUMN_WIDTH }}>{SERVICE_TYPES[item as ServiceTypes]}</div>
                    <div>{`：${getServiceTypeText(item as ServiceTypes)?.replaceAll("*", "")}`}</div>
                  </div>
                );
              })}
            </section>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
