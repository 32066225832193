import axios from "axios";
import { formatDateAndTime } from "helpers/date";
import { A_ADD_WORKER } from "models/authorities";
import { FetchStaffHistory, IWorkers } from "models/responseModels";
import { AREAS, DIVISIONS, ROLES } from "models/variables";
import { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading } from "redux/Loading/action";
import { setViewWorkerHistory, setViewWorkerModalData, setViewWorkerModalOpen } from "redux/ViewWorkerModal/action";
import { resetWorkerPagination, setWorkerChangesMade, setWorkerCurrentPage } from "redux/Worker/action";
import { IRootState } from "store";
import { DeleteIcon, EditIcon } from "./IconsOnly";
import { ActionRow } from "./parts/ActionRow";
import DeleteModal from "./parts/DeleteModal";
import FormHeader from "./parts/FormHeader";
import SortableTableHeaders from "./parts/SortableTableHeaders";
import ViewWorkerModal from "./parts/ViewWorkerModal";

interface IWorkerInformation {
  header: string;
}

const Roles = ["SUPER_ADMIN", "ADMIN", "SUPERVISOR", "FOREMEN", "WORKER", "所有"];

export default function WorkerInformation({ header }: IWorkerInformation) {
  const [sortByDirection, setSortByDirection] = useState<{ sortType: string; isDesc: boolean }>({
    sortType: "updatedAt",
    isDesc: true,
  });
  const [modal, setModal] = useState(false);
  const [allWorkers, setAllWorkers] = useState<Array<IWorkers>>([]);
  const [editTarget, setEditTarget] = useState<{ name: string; id: number }>({ name: "", id: -1 });
  const [lastPage, setLastPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [roleTypeIsOpen, setRoleTypeIsOpen] = useState(false);
  const [fetchByRole, setFetchByRole] = useState("所有");

  const sortableHeaders = [
    { headerText: "動作", headerKey: "" },
    { headerText: "員工編號", headerKey: "staffNumber" },
    { headerText: "中文姓名", headerKey: "chiName" },
    // { headerText: "英文姓名", headerKey: "engName" },
    { headerText: "聯絡電話", headerKey: "mobile" },
    { headerText: "區域", headerKey: "area" },
    { headerText: "所屬公司", headerKey: "company" },
    { headerText: "所屬部門", headerKey: "division" },
    { headerText: "員工職位", headerKey: "role" },
    { headerText: "更新日期", headerKey: "updatedAt" },
  ];

  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);

  const pagination = useSelector((state: IRootState) => state.worker.pagination);
  const changesMade = useSelector((state: IRootState) => state.worker.changesMade);
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const global = useSelector((state: IRootState) => state.loading);
  const maxRow = global.maxRow;
  const userPreferredRow = global.userPreferredRow;

  const fetchAllWorkers = async (pageNumber: number) => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/users`);
      if (userPreferredRow) {
        url.searchParams.set("rows", userPreferredRow.toString());
      } else {
        url.searchParams.set("rows", maxRow.toString());
      }
      url.searchParams.set("page", pageNumber.toString());
      if (fetchByRole !== "所有") {
        url.searchParams.set("role", fetchByRole.toString());
      }
      if (!!searchText) url.searchParams.set("search", searchText);
      url.searchParams.set("order", `${sortByDirection.sortType}`);
      url.searchParams.set("direction", `${sortByDirection.isDesc ? "desc" : "asc"}`);
      const res = await axios.get(url.toString());
      const result = res.data;
      setAllWorkers(result.data);
      setLastPage(result.pagination.lastPage);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleSelect = async (item: IWorkers) => {
    dispatch(setIsLoading(true));
    dispatch(
      setViewWorkerModalData({
        chiName: item.chiName,
        company: item.company,
        companyId: item.companyId ?? 0,
        createdAt: "",
        division: item.division,
        email: item.email,
        engName: item.engName,
        greencardRefNo: item.greencardRefNo,
        id: item.id,
        mobile: item.mobile,
        residenceDistrict: item.residenceDistrict,
        role: item.role,
        staffNumber: item.staffNumber,
        updatedAt: item.updatedAt,
        userIsActive: true,
        area: item.area,
      })
    );
    try {
      const res = await axios.get<{ data: FetchStaffHistory[] }>(`/users/${item.id}`);
      const result = await res.data;
      dispatch(setViewWorkerHistory(result.data));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
    dispatch(setViewWorkerModalOpen(true));
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (changesMade) {
      fetchAllWorkers(1);
      dispatch(setWorkerCurrentPage(1));
      dispatch(setWorkerChangesMade(false));
    } else {
      fetchAllWorkers(pagination.currentPage);
    }
    return () => {
      dispatch(resetWorkerPagination());
    };
  }, [dispatch]);

  useEffect(() => {
    if (modal) return;
    fetchAllWorkers(pagination.currentPage);
  }, [dispatch, pagination.currentPage, modal, maxRow, userPreferredRow]);

  const handleSearch = () => {
    dispatch(resetWorkerPagination());
    fetchAllWorkers(1);
  };

  const handleControlSort = (sortType: string) => {
    if (sortByDirection.sortType === sortType) {
      setSortByDirection({ sortType, isDesc: !sortByDirection.isDesc });
    } else {
      setSortByDirection({ sortType, isDesc: true });
    }
  };

  useEffect(() => {
    fetchAllWorkers(1);
  }, [sortByDirection.sortType, sortByDirection.isDesc, fetchByRole]);

  return (
    <div className="p-3 relative" style={{ overflowX: "hidden" }}>
      <FormHeader
        offset={false}
        header={header}
        includeExtraButton
        children={
          <div className="flex-center">
            <div>顯示員工：</div>
            <Dropdown
              isOpen={roleTypeIsOpen}
              toggle={() => {
                setRoleTypeIsOpen(!roleTypeIsOpen);
              }}
            >
              <DropdownToggle
                style={{
                  width: "max-content",

                  color: "#EEE",
                }}
              >
                {fetchByRole}
                <MdKeyboardArrowDown
                  fill={"#EEE"}
                  size={24}
                  style={{
                    marginLeft: "16px",
                    transform: roleTypeIsOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "all 0.4s cubic-bezier(0.9, 1, 0.3, 1.2)",
                  }}
                />
              </DropdownToggle>
              <DropdownMenu>
                {Roles.map((item) => {
                  return (
                    <DropdownItem
                      key={item}
                      md={2}
                      className="d-flex flex-center districtSelectionItem"
                      onClick={() => {
                        setFetchByRole(item);
                        setRoleTypeIsOpen(false);
                      }}
                    >
                      {item}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        }
      />
      <ActionRow
        lastPage={lastPage}
        currentPage={pagination.currentPage}
        addItem={"員工"}
        setCurrentPageFunction={setWorkerCurrentPage}
        handleSearch={handleSearch}
        searchText={searchText}
        setSearchText={setSearchText}
        placeholderText={"編號 / 姓名 / 手機號碼 / 所屬公司"}
      />
      {!allWorkers.length ? (
        <div className="my-2 flex-center full-width disableText"> 沒有員工</div>
      ) : (
        <div className="flex-center full-width" style={{ overflowX: "auto" }}>
          <div className="p-2 viewCoTable">
            <SortableTableHeaders
              headers={sortableHeaders}
              sortControl={handleControlSort}
              currentSorting={sortByDirection}
            />
            <div className="tableBody relative">
              {isLoading ? (
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              ) : (
                allWorkers.map((item, idx) => {
                  return (
                    <div key={idx} className="flex-center tableRow">
                      <div className="flex-row-around tdActionContainer">
                        {A_ADD_WORKER.includes(userRole) && (
                          <div
                            className="flex-center tdIconContainer full-height"
                            onClick={() => {
                              history.push(`/admin/worker/${item.id}`);
                            }}
                          >
                            <EditIcon />
                          </div>
                        )}
                        {A_ADD_WORKER.includes(userRole) && (
                          <div
                            className="flex-center tdIconContainer full-height"
                            onClick={() => {
                              setModal(!modal);
                              setEditTarget({ name: item.chiName, id: item.id });
                            }}
                          >
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.staffNumber || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.chiName || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.mobile || "-"}
                      </div>
                      <div className="flex-center tdDistrict" onClick={() => handleSelect(item)}>
                        {AREAS[item.area] ?? "-"}
                      </div>
                      <div className="tdMainItem" onClick={() => handleSelect(item)}>
                        {item.company}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {DIVISIONS[item.division] ?? "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {ROLES[item.role]}
                      </div>
                      <div className="flex-center tdDateItem" onClick={() => handleSelect(item)}>
                        {formatDateAndTime(item.updatedAt)}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            {A_ADD_WORKER.includes(userRole) && (
              <DeleteModal isOpen={modal} deleteTarget={editTarget} setModal={setModal} addItemString={"員工"} />
            )}
            <ViewWorkerModal />
          </div>
        </div>
      )}
    </div>
  );
}
