import NewWork from "components/NewWork";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearNewOrderData } from "redux/Order/action";
import Layout from "./Layout";

export default function NewWorkPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearNewOrderData());
    };
  }, [dispatch]);
  return (
    <Layout>
      <NewWork />
    </Layout>
  );
}
