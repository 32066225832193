import axios from "axios";
import { CloseIcon, DownCaret, SearchIcon } from "components/IconsOnly";
import { EditOrderProps } from "components/WorkInformation";
import { formatDateAndTime } from "helpers/date";
import { FetchAssignedWorkers } from "models/responseModels";
import { getServiceTypeText } from "models/serviceTypeText";
import {
  DIVISIONS,
  DIVISIONS_MAPPING,
  DivisionType,
  ServiceTypes,
  SERVICE_TYPES,
  SERVICE_TYPES_MAPPING,
} from "models/variables";
import { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Nav,
  Row,
  TabContent,
} from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setOrderByIdData } from "redux/Order/action";
import { IRootState } from "store";
import { AssignList } from "./NewOrder/NewAdminDetails";
import ReactstrapTabNavLink from "./ReactstrapTabNavLink";

type DropdownType = "" | "SERVICE_TYPE" | "DIVISION_TYPE";

interface AdminDetailsProps {
  showDetails: boolean;
  setShowDetails: (args0: boolean) => void;
  ROW_HEIGHT: string;
}

export default function AdminDetails(props: EditOrderProps & AdminDetailsProps) {
  const { setChanged, showDetails, setShowDetails, ROW_HEIGHT } = props;
  const [dropdownType, setDropdownType] = useState<DropdownType>("");
  const [modalSearchText, setModalSearchText] = useState("");
  const [assignUserOpen, setAssignUserOpen] = useState(false);
  const [showServiceTypeText, setShowServiceTypeText] = useState(false);
  const [allUsers, setAllUsers] = useState<FetchAssignedWorkers[]>([]);
  const [activeTab, setActiveTab] = useState("WORKER");
  const pathname = window.location.pathname.split("/");
  const orderId = pathname[pathname.length - 1];

  const data = useSelector((state: IRootState) => state.orderById.orderById);
  const dispatch = useDispatch();

  const isServiceTypeOpen = dropdownType === "SERVICE_TYPE";
  const isDivisionTypeOpen = dropdownType === "DIVISION_TYPE";
  const tabs = ["WORKER", "FOREMEN", "SUPERVISOR"];

  const handleSelectServiceType = (item: ServiceTypes) => {
    dispatch(setOrderByIdData({ ...data, serviceType: item }));
    if (setChanged) {
      setChanged(true);
    }
    setDropdownType("");
    setShowServiceTypeText(false);
  };

  const handleSelectDivisions = (item: DivisionType) => {
    dispatch(setOrderByIdData({ ...data, division: item }));
    if (setChanged) {
      setChanged(true);
    }
    setDropdownType("");
  };

  function toggle(tab: string) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const fetchAllUsers = async () => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/users`);
      url.searchParams.set("type", "form");
      url.searchParams.set("orderId", `${orderId}`);
      if (!!modalSearchText) url.searchParams.set("search", modalSearchText);
      const res = await axios.get(url.toString());
      const result = res.data.data;
      setAllUsers(result);
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };
  useEffect(() => {
    fetchAllUsers();
  }, []);

  const supervisorList = allUsers.filter((i) => i.role === "SUPERVISOR");
  const foremenList = allUsers.filter((i) => i.role === "FOREMEN");
  const workerList = allUsers.filter((i) => i.role === "WORKER");

  return (
    <>
      <Row className={`my-4 flex-row-start`}>
        <Col className="flex-row-start" style={{ maxWidth: "40px" }}>
          <div
            className="flex-center pointer"
            style={{ transform: showDetails ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.3s" }}
            onClick={() => setShowDetails(!showDetails)}
          >
            <DownCaret />
          </div>
        </Col>
        <Col md={1} style={{ width: "fit-content" }}>
          <h5 className="m-0">行政資料</h5>
        </Col>
      </Row>
      <Row
        className={`my-1`}
        style={{
          opacity: showDetails ? 1 : 0,
          height: showDetails ? ROW_HEIGHT : "0px",
          transition: "all 0.3s",
        }}
      >
        <Col className="flex-row-start">
          <h6 className="m-0">發單日期:</h6>
          <div className="mx-2">{formatDateAndTime(data.createdAt)}</div>
        </Col>
        <Col className={`p-2 flex-row-start`}>
          <h6 className="m-0"> 部門: </h6>
          <div className="mx-3 d-flex align-items-center">
            <Dropdown
              isOpen={isDivisionTypeOpen}
              toggle={() => {
                if (isDivisionTypeOpen) {
                  setDropdownType("");
                } else {
                  setDropdownType("DIVISION_TYPE");
                }
              }}
            >
              <DropdownToggle>
                {data.division ? DIVISIONS[data.division] : "-"}
                <MdKeyboardArrowDown
                  fill={"#EEE"}
                  size={24}
                  style={{
                    marginLeft: "16px",
                    transform: isDivisionTypeOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "all 0.4s cubic-bezier(0.9, 1, 0.3, 1.2)",
                  }}
                />
              </DropdownToggle>
              <DropdownMenu>
                {Object.values(DIVISIONS).map((item) => {
                  return (
                    <DropdownItem
                      key={item}
                      md={2}
                      className="d-flex flex-center districtSelectionItem"
                      onClick={() => {
                        handleSelectDivisions(DIVISIONS_MAPPING.get(item));
                      }}
                    >
                      {item}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
        <Col className={`p-2 flex-row-start`}>
          <h6 className="m-0"> 服務性質: </h6>
          <div
            className="mx-3 flex-row-start relative"
            onMouseEnter={() => setShowServiceTypeText(true)}
            onMouseLeave={() => setShowServiceTypeText(false)}
          >
            {data.serviceType && !!getServiceTypeText(data.serviceType) && showServiceTypeText && (
              <div className="absolute p-2 px-3 serviceTypeText">
                {data.serviceType && getServiceTypeText(data.serviceType)}
              </div>
            )}
            <Dropdown
              isOpen={isServiceTypeOpen}
              toggle={() => {
                if (isServiceTypeOpen) {
                  setDropdownType("");
                } else {
                  setDropdownType("SERVICE_TYPE");
                }
              }}
            >
              <DropdownToggle
                onMouseEnter={() => setShowServiceTypeText(true)}
                onMouseLeave={() => setShowServiceTypeText(false)}
              >
                {data.serviceType ? SERVICE_TYPES[data.serviceType] : "-"}
                <MdKeyboardArrowDown
                  fill={"#EEE"}
                  size={24}
                  style={{
                    marginLeft: "16px",
                    transform: isServiceTypeOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "all 0.4s cubic-bezier(0.9, 1, 0.3, 1.2)",
                  }}
                />
              </DropdownToggle>
              <DropdownMenu>
                {Object.values(SERVICE_TYPES).map((item) => {
                  return (
                    <DropdownItem
                      key={item}
                      md={2}
                      className="d-flex flex-center districtSelectionItem"
                      onClick={() => {
                        handleSelectServiceType(SERVICE_TYPES_MAPPING.get(item));
                      }}
                    >
                      {item}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row
        className={`my-1`}
        style={{
          opacity: showDetails ? 1 : 0,
          height: showDetails ? ROW_HEIGHT : "0px",
          transition: "all 0.3s",
        }}
      >
        <Col className={`p-2`}>
          <h6> ACJ 號碼 / P.O. 號碼: </h6>
          <input
            readOnly={data.progressStatus === "COMPLETED"}
            placeholder="可選填"
            className="inputStyle"
            value={data.acjNo ?? ""}
            onChange={(e) => {
              if (setChanged) {
                setChanged(true);
              }
              dispatch(setOrderByIdData({ ...data, acjNo: e.target.value }));
            }}
          />
        </Col>
        <Col className={`p-2`}>
          <h6> 消息來源: </h6>
          <input
            className="inputStyle"
            value={data.informedBy}
            onChange={(e) => {
              if (setChanged) {
                setChanged(true);
              }
              dispatch(setOrderByIdData({ ...data, informedBy: e.target.value }));
            }}
          />
        </Col>
      </Row>

      <Modal
        isOpen={assignUserOpen}
        className="d-flex justify-content-center"
        style={{ minWidth: "70vw", maxHeight: "90vh" }}
      >
        <Container className="p-3" style={{ overflowY: "scroll" }}>
          <div
            className="closeButtonContainer"
            onClick={() => {
              setAssignUserOpen(false);
            }}
          >
            <CloseIcon />
          </div>
          <h2 style={{ textAlign: "center" }}>{`${data.orderNumber} - 委派員工`}</h2>
          <Row className="px-2 flex-row-between m-4">
            <Col md={6} className="d-flex">
              <input
                className="searchInput"
                style={{ width: "100%" }}
                value={modalSearchText}
                placeholder={"員工編號 / 中文姓名 / 手機號碼"}
                onChange={(e) => setModalSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") fetchAllUsers();
                }}
              ></input>
              <div className="mx-3" onClick={fetchAllUsers}>
                <SearchIcon />
              </div>
            </Col>
            <Col className="flex-row-end"></Col>
          </Row>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Nav tabs style={{ height: "40px" }}>
              {tabs.map((element) => {
                const props = { tabContent: element, activeTab, toggle };
                return <ReactstrapTabNavLink key={element} {...props} />;
              })}
            </Nav>

            <TabContent activeTab={activeTab} className="flex-row-start" style={{ width: "100%" }}>
              {
                {
                  SUPERVISOR: (
                    <AssignList
                      assignList={supervisorList}
                      closeAction={setAssignUserOpen}
                      isEditMode={true}
                      setChanged={setChanged}
                    />
                  ),
                  FOREMEN: (
                    <AssignList
                      assignList={foremenList}
                      closeAction={setAssignUserOpen}
                      isEditMode={true}
                      setChanged={setChanged}
                    />
                  ),
                  WORKER: (
                    <AssignList
                      assignList={workerList}
                      closeAction={setAssignUserOpen}
                      isEditMode={true}
                      setChanged={setChanged}
                    />
                  ),
                }[activeTab]
              }
            </TabContent>
          </div>
        </Container>
      </Modal>
    </>
  );
}
