import axios from "axios";
import { DeleteIcon, EditIcon } from "components/IconsOnly";
import { ActionRow } from "components/parts/ActionRow";
import DeleteModal from "components/parts/DeleteModal";
import FormHeader from "components/parts/FormHeader";
import { formatDateAndTime } from "helpers/date";
import { A_ADD_COMPANY } from "models/authorities";
import { ICompanies } from "models/responseModels";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { resetCompanyPagination, setCompanyChangesMade, setCompanyCurrentPage } from "redux/Companies/action";
import { setIsLoading } from "redux/Loading/action";
import { IRootState } from "store";
import SortableTableHeaders from "./SortableTableHeaders";

export default function CompanyList() {
  const [sortByDirection, setSortByDirection] = useState<{ sortType: string; isDesc: boolean }>({
    sortType: "updatedAt",
    isDesc: true,
  });
  const [allCompanies, setAllCompanies] = useState<ICompanies[]>([]);
  const [modal, setModal] = useState(false);
  const [editTarget, setEditTarget] = useState<{ name: string; id: number }>({ name: "", id: -1 });
  const [lastPage, setLastPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const sortableHeaders = [
    { headerText: "動作", headerKey: "" },
    { headerText: "公司名稱", headerKey: "name" },
    { headerText: "聯絡人", headerKey: "contactPerson" },
    { headerText: "電話號碼", headerKey: "tel" },
    { headerText: "電郵地址", headerKey: "email" },
    { headerText: "合作員工", headerKey: "usersCount" },
    { headerText: "更新日期", headerKey: "updatedAt" },
  ];

  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const pagination = useSelector((state: IRootState) => state.company.pagination);
  const changesMade = useSelector((state: IRootState) => state.company.changesMade);
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const global = useSelector((state: IRootState) => state.loading);
  const maxRow = global.maxRow;
  const userPreferredRow = global.userPreferredRow;

  const fetchAllCompanies = async (pageNumber: number) => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/companies`);
      if (userPreferredRow) {
        url.searchParams.set("rows", userPreferredRow.toString());
      } else {
        url.searchParams.set("rows", maxRow.toString());
      }
      url.searchParams.set("page", pageNumber.toString());
      if (!!searchText) url.searchParams.set("search", searchText);
      url.searchParams.set("order", `${sortByDirection.sortType}`);
      url.searchParams.set("direction", `${sortByDirection.isDesc ? "desc" : "asc"}`);
      const res = await axios.get(url.toString());
      const result = res.data;
      setAllCompanies(result.data);
      setLastPage(result.pagination.lastPage);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    if (changesMade) {
      fetchAllCompanies(1);
      dispatch(setCompanyCurrentPage(1));
      dispatch(setCompanyChangesMade(false));
    } else {
      fetchAllCompanies(pagination.currentPage);
    }
    return () => {
      dispatch(resetCompanyPagination());
    };
  }, []);

  useEffect(() => {
    if (modal) return;
    fetchAllCompanies(pagination.currentPage);
  }, [dispatch, pagination.currentPage, modal]);

  const handleSelect = (id: number) => {
    history.push(`/admin/company/${id}`);
  };

  const handleSearch = () => {
    fetchAllCompanies(1);
  };

  const handleControlSort = (sortType: string) => {
    if (sortByDirection.sortType === sortType) {
      setSortByDirection({ sortType, isDesc: !sortByDirection.isDesc });
    } else {
      setSortByDirection({ sortType, isDesc: true });
    }
  };

  useEffect(() => {
    fetchAllCompanies(1);
  }, [sortByDirection.sortType, sortByDirection.isDesc, maxRow, userPreferredRow]);

  return (
    <div className="p-3 relative">
      <FormHeader offset={false} header={"合作公司資料"} />
      <ActionRow
        lastPage={lastPage}
        currentPage={pagination.currentPage}
        addItem={"公司資料"}
        setCurrentPageFunction={setCompanyCurrentPage}
        setSearchText={setSearchText}
        searchText={searchText}
        handleSearch={handleSearch}
        placeholderText={"公司名稱 / 聯絡人 / 電話 / 電郵"}
      />
      {/* <RowsIndicator /> */}
      {!allCompanies.length ? (
        <div className="my-2 flex-center full-width disableText">未加入合作公司</div>
      ) : (
        <div className="flex-center full-width" style={{ overflowX: "auto" }}>
          <div className="p-2 viewCoTable">
            <SortableTableHeaders
              headers={sortableHeaders}
              sortControl={handleControlSort}
              currentSorting={sortByDirection}
            />
            <div className="tableBody relative">
              {isLoading ? (
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              ) : (
                allCompanies.map((item) => {
                  return (
                    <div key={item.id} className={`flex-center tableRow`}>
                      <div className="flex-row-around tdActionContainer">
                        {A_ADD_COMPANY.includes(userRole) && (
                          <div
                            className="flex-center tdIconContainer full-height"
                            onClick={() => {
                              history.push(`/admin/company/${item.id}`);
                            }}
                          >
                            <EditIcon />
                          </div>
                        )}
                        {A_ADD_COMPANY.includes(userRole) && (
                          <div
                            className="flex-center tdIconContainer full-height"
                            onClick={() => {
                              setModal(!modal);
                              setEditTarget({ name: item.name, id: item.id });
                            }}
                          >
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                      <div className="tdMainItem" onClick={() => handleSelect(item.id)}>
                        {item.name || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item.id)}>
                        {item.contactPerson || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item.id)}>
                        {item.tel || "-"}
                      </div>
                      <div className="tdLongItem" onClick={() => handleSelect(item.id)}>
                        {item.email || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item.id)}>
                        {item.usersCount}
                      </div>
                      <div className="flex-center tdDateItem" onClick={() => handleSelect(item.id)}>
                        {formatDateAndTime(item.updatedAt)}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            {A_ADD_COMPANY.includes(userRole) && (
              <DeleteModal isOpen={modal} deleteTarget={editTarget} setModal={setModal} addItemString={"公司"} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
