import axios from "axios";
import "css/Remarks.css";
import { setDateToStart, timeFormatter } from "helpers/date";
import { A_CHG_TO_COMPLETED } from "models/authorities";
import { getStatusDescription } from "models/progressStatusText";
import { progressColor, ProgressStatusType, PROGRESS_STATUS, PROGRESS_STATUS_MAPPING } from "models/variables";
import { useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import DatePicker from "react-date-picker";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setMessageModal } from "redux/Loading/action";
import { setOrderByIdData, setWorkChangesMade } from "redux/Order/action";
import { IRootState } from "store";
import { CloseIcon } from "./IconsOnly";
import AdminDetails from "./parts/AdminDetails";
import ClientDetails from "./parts/ClientDetails";
import FormHeader from "./parts/FormHeader";

interface IWorkInformation {
  header: string;
}

export interface EditOrderProps {
  changed?: boolean;
  setChanged?: (args0: boolean) => void;
}

const ROW_HEIGHT = "80px";

type FloatContainerType = "CANCEL" | "REMARKS" | "";

export default function WorkInformation(props: IWorkInformation & EditOrderProps) {
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const { header, changed = false, setChanged } = props;
  const data = useSelector((state: IRootState) => state.orderById.orderById);
  const [progressDropDown, setProgressDropDown] = useState(false);
  const [floatContainerType, setFloatContainerType] = useState<FloatContainerType>("");
  const [showDetails, setShowDetails] = useState(true);
  const isFloatCancel = floatContainerType === "CANCEL";
  const isFloatRemarks = floatContainerType === "REMARKS";
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      const { totalPrice, paymentMade } = data;
      if (!!totalPrice && !!paymentMade && totalPrice < paymentMade) {
        dispatch(setMessageModal({ isOpen: true, content: "金額不正確" }));
        return;
      }
      await axios.put(`/orders/${data.id}`, {
        order: {
          orderNumber: data.orderNumber,
          clientId: data.clientId,
          // serviceTypeOthers: data.serviceTypeOthers,
          serviceSubject: data.serviceSubject,
          serviceReport: data.serviceReport,
          startDate: data.startDate,
          completeDate: data.completeDate,
          informedBy: data.informedBy,
          totalPrice: data.totalPrice,
          priority: data.priority,
          serviceType: data.serviceType,
          division: data.division,
          paymentMade: data.paymentMade,
          invoiceIssued: data.invoiceIssued,
          progressStatus: data.progressStatus,
          acjNo: data.acjNo,
          remarks: data.remarks,
          cancelReason: data.cancelReason,
        },
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      dispatch(setWorkChangesMade(true));
      if (setChanged) {
        setChanged(false);
      }
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  const handleSelectProgress = (item: ProgressStatusType) => {
    if (item === "COMPLETED" && !data.acjNo) {
      dispatch(setMessageModal({ isOpen: true, content: "尚未輸入 ACJ 號碼 / P.O. 號碼" }));
      return;
    } else if (item === "COMPLETED" && !data.surveyCompleted) {
      dispatch(setMessageModal({ isOpen: true, content: "尚未有評分或客戶簽署" }));
    } else if (item === "COMPLETED" && !data.serviceReport) {
      dispatch(setMessageModal({ isOpen: true, content: "尚未輸入服務報告" }));
    } else if (item !== data.progressStatus) {
      dispatch(setOrderByIdData({ ...data, progressStatus: item }));
      if (setChanged) {
        setChanged(true);
      }
      setProgressDropDown(false);
    }
  };

  const handleOpenFloat = (type: FloatContainerType) => {
    if (floatContainerType === type) {
      setFloatContainerType("");
    } else {
      setFloatContainerType(type);
    }
  };

  // const isOver8days = !!data.startDate && new Date(data.startDate) < new Date(Date.now() - 691200000); //8days
  const isOver8days = !!data.startDate && new Date(data.startDate) < new Date(Date.now() - 31556926000);

  return (
    <div className="p-3 relative">
      <FormHeader
        offset={false}
        header={`${header} - ${data.orderNumber}`}
        includeExtraButton={true}
        children={
          <div className="flex-column-center absolute" style={{ right: 0, top: "8px", zIndex: 1 }}>
            <div className="mb-2 flex-center absolute" style={{ height: "48px", top: 0, right: "16px" }}>
              <h6 className="m-2" style={{ minWidth: "40px" }}>
                進度
              </h6>
              <Dropdown
                isOpen={progressDropDown}
                toggle={() => {
                  setProgressDropDown(!progressDropDown);
                }}
              >
                <DropdownToggle
                  style={{
                    width: "max-content",
                    backgroundColor: progressColor(data.progressStatus),
                    color: "#444",
                  }}
                >
                  {PROGRESS_STATUS[data.progressStatus]}
                  <MdKeyboardArrowDown
                    fill={"#444"}
                    size={24}
                    style={{
                      marginLeft: "16px",
                      transform: progressDropDown ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "all 0.4s cubic-bezier(0.9, 1, 0.3, 1.2)",
                    }}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {Object.values(PROGRESS_STATUS).map((item) => {
                    return (
                      <DropdownItem
                        key={item}
                        disabled={item === PROGRESS_STATUS["COMPLETED"] && !A_CHG_TO_COMPLETED.includes(userRole)}
                        md={2}
                        className="d-flex flex-center districtSelectionItem"
                        onClick={() => {
                          handleSelectProgress(PROGRESS_STATUS_MAPPING.get(item));
                        }}
                      >
                        {item}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>

            <h6 className="progressDescription disableText">{getStatusDescription(data.progressStatus)}</h6>

            <div className={isFloatRemarks ? "remarkContainer openContainer" : "remarkContainer"} style={{ zIndex: 1 }}>
              <div
                className={isFloatRemarks ? "py-3 px-4  remarkButton openButton" : "py-2 px-4  remarkButton"}
                onClick={() => handleOpenFloat("REMARKS")}
              >
                {isFloatRemarks ? "CLOSE" : "REMARKS"}
              </div>
              {isFloatRemarks && (
                <div
                  className="flex-center full-width relative"
                  style={{ background: "white", width: "90%", height: "80%" }}
                >
                  <TextareaAutosize
                    value={data.remarks}
                    className="remarkTextarea"
                    onChange={(e) => {
                      if (setChanged) {
                        setChanged(true);
                      }
                      dispatch(setOrderByIdData({ ...data, remarks: e.currentTarget.value }));
                    }}
                  />
                </div>
              )}
            </div>

            {data.progressStatus === "CANCELLED" && (
              <div
                className={isFloatCancel ? "cancelContainer openContainer" : "cancelContainer"}
                style={{ zIndex: 0 }}
              >
                <div
                  className={isFloatCancel ? "py-3 px-4  remarkButton openButton" : "py-2 px-4  remarkButton"}
                  onClick={() => handleOpenFloat("CANCEL")}
                >
                  {isFloatCancel ? "CLOSE" : "取消原因"}
                </div>
                {isFloatCancel && (
                  <div
                    className="flex-center full-width relative"
                    style={{ background: "white", width: "90%", height: "80%" }}
                  >
                    <TextareaAutosize
                      value={data.cancelReason}
                      className="remarkTextarea"
                      onChange={(e) => {
                        if (setChanged) {
                          setChanged(true);
                        }
                        dispatch(setOrderByIdData({ ...data, cancelReason: e.currentTarget.value }));
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        }
      />
      <ClientDetails setChanged={setChanged} />
      <AdminDetails
        setChanged={setChanged}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        ROW_HEIGHT={ROW_HEIGHT}
      />
      <Row
        className={`my-1  mb-4`}
        style={{
          opacity: showDetails ? 1 : 0,
          height: showDetails ? ROW_HEIGHT : "0px",
          transition: "all 0.3s",
        }}
      >
        <Col className={`p-2`}>
          <h6>施工日期:</h6>
          <DatePicker
            disabled={userRole === "SUPER_ADMIN" ? false : isOver8days}
            calendarType={"US"}
            minDate={
              userRole === "SUPER_ADMIN"
                ? undefined
                : data.startDate
                ? setDateToStart(data.startDate)
                : setDateToStart(new Date(Date.now() - 604800000).toISOString()) // date back 7 days as RQ from Soman 2022 Jan 21
            }
            format={"y-MM-dd"}
            onChange={(e: Date) => {
              if (setChanged) {
                setChanged(true);
              }
              if (!e) {
                dispatch(setOrderByIdData({ ...data, startDate: null }));
              } else {
                if (
                  !!data.completeDate &&
                  setDateToStart(data.completeDate).valueOf() < setDateToStart(e.toISOString()).valueOf()
                ) {
                  dispatch(setOrderByIdData({ ...data, startDate: e.toISOString(), completeDate: null }));
                } else {
                  dispatch(setOrderByIdData({ ...data, startDate: e.toISOString() }));
                }
              }
            }}
            value={!data.startDate ? null : new Date(data.startDate)}
            className="calendarPicker"
            calendarClassName="calendar"
          />
          <input
            className="timePicker mx-1"
            disabled={!data.startDate}
            type="time"
            value={!!data.startDate ? timeFormatter.format(new Date(data.startDate)) : undefined}
            onChange={(e) => {
              if (!data.startDate) {
                return;
              } else {
                const target = e.target.value.split(":"); // turn the user input into numbers
                const joinTime = new Date(data.startDate).setHours(parseInt(target[0]), parseInt(target[1]));
                dispatch(setOrderByIdData({ ...data, startDate: new Date(joinTime).toISOString() }));
              }
            }}
          />
        </Col>
        <Col className={`p-2`}>
          <h6> 完成日期: </h6>
          <DatePicker
            disabled={userRole === "SUPER_ADMIN" ? false : isOver8days}
            calendarType={"US"}
            minDate={
              !data.startDate
                ? userRole === "SUPER_ADMIN"
                  ? undefined
                  : data.completeDate
                  ? setDateToStart(data.completeDate)
                  : setDateToStart(new Date(Date.now() - 604800000).toISOString())
                : new Date(data.startDate)
            }
            format={"y-MM-dd"}
            onChange={(e: Date) => {
              if (setChanged) {
                setChanged(true);
              }
              if (!e) {
                dispatch(setOrderByIdData({ ...data, completeDate: null }));
              } else {
                dispatch(setOrderByIdData({ ...data, completeDate: e.toISOString() }));
              }
            }}
            value={!data.completeDate ? null : new Date(data.completeDate)}
            className="calendarPicker"
            calendarClassName="calendar"
          />
          <input
            className="timePicker mx-1"
            disabled={!data.completeDate}
            type="time"
            value={!!data.completeDate ? timeFormatter.format(new Date(data.completeDate)) : undefined}
            onChange={(e) => {
              if (!data.completeDate) {
                return;
              } else {
                const target = e.target.value.split(":"); // turn the user input into numbers
                const joinTime = new Date(data.completeDate).setHours(parseInt(target[0]), parseInt(target[1]));
                dispatch(setOrderByIdData({ ...data, completeDate: new Date(joinTime).toISOString() }));
              }
            }}
          />
        </Col>
      </Row>
      <Row
        className={`my-1 mb-4`}
        style={{
          opacity: showDetails ? 1 : 0,
          height: showDetails ? ROW_HEIGHT : "0px",
          transition: "all 0.3s",
        }}
      >
        <Col md={6} className={`p-2`}>
          <h6>總收費:</h6>
          <div className="flex-row-start">
            <div>$</div>
            <input
              className="inputStyle"
              style={{ width: "70%" }}
              onFocus={() => {
                if (!!data.totalPrice && data.totalPrice > 0) {
                  return;
                } else {
                  dispatch(setOrderByIdData({ ...data, totalPrice: 0 }));
                }
              }}
              type="text"
              value={data.totalPrice === 0 ? 0 : data.totalPrice === null ? "-" : data.totalPrice}
              onChange={(e) => {
                if (setChanged) {
                  setChanged(true);
                }
                if (parseInt(e.target.value) < 0) return;
                if (isNaN(parseInt(e.target.value))) {
                  dispatch(setOrderByIdData({ ...data, totalPrice: null }));
                } else {
                  dispatch(setOrderByIdData({ ...data, totalPrice: parseInt(e.target.value) }));
                }
              }}
            />
            <div
              onClick={() => {
                if (setChanged) {
                  setChanged(true);
                }
                dispatch(setOrderByIdData({ ...data, totalPrice: null }));
              }}
              style={{ boxSizing: "border-box", transform: "scale(0.7)", cursor: "pointer" }}
            >
              <CloseIcon />
            </div>
          </div>
        </Col>
        <Col md={6} className={`p-2`}>
          <h6>已收取費用: </h6>
          <div className="flex-row-start">
            <div>$</div>
            <input
              className="inputStyle"
              style={{ width: "70%" }}
              onFocus={() => {
                if (!!data.paymentMade && data.paymentMade > 0) {
                  return;
                } else {
                  dispatch(setOrderByIdData({ ...data, paymentMade: 0 }));
                }
              }}
              type="text"
              value={data.paymentMade === 0 ? 0 : data.paymentMade === null ? "-" : data.paymentMade}
              onChange={(e) => {
                if (setChanged) {
                  setChanged(true);
                }
                if (parseInt(e.target.value) < 0) return;
                if (isNaN(parseInt(e.target.value))) {
                  dispatch(setOrderByIdData({ ...data, paymentMade: null }));
                } else {
                  dispatch(setOrderByIdData({ ...data, paymentMade: parseInt(e.target.value) }));
                }
              }}
            />
            <div
              onClick={() => {
                if (setChanged) {
                  setChanged(true);
                }
                dispatch(setOrderByIdData({ ...data, paymentMade: null }));
              }}
              style={{ boxSizing: "border-box", transform: "scale(0.7)", cursor: "pointer" }}
            >
              <CloseIcon />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="flex-center">
          <Button disabled={!changed} color="primary" className="m-1 py-2 w-50" onClick={handleSubmit}>
            儲存
          </Button>
        </Col>
      </Row>
      <Row style={{ height: "200px" }}></Row>
    </div>
  );
}
