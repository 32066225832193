import SaveSuccessModal from "components/parts/Document/SaveSuccessModal";
import NeedLargerDeviceModal from "components/parts/NeedLargerDeviceModal";
import PrivateRoute from "helpers/AdminPrivateRoute";
import {
  A_ADD_CLIENT,
  A_ADD_COMPANY,
  A_ADD_ORDER,
  A_ADD_WORKER,
  A_CREATE_NEW_TEMPLATE,
  A_DASHBOARD,
  A_MANAGE_DISTRICT,
  A_VIEW_CLIENT,
  A_VIEW_COMPANY,
  A_VIEW_DOC_TYPE,
  A_VIEW_WORKER,
  highLevel,
} from "models/authorities";
import AddNewDocumentPage from "pages/Admin/AddNewDocumentPage";
import AllOrdersLobbyPage from "pages/Admin/AllOrdersLobbyPage";
import AreaPage from "pages/Admin/AreaPage";
import CompanyPage from "pages/Admin/CompanyPage";
import DashboardPage from "pages/Admin/DashboardPage";
import DocumentByIdPage from "pages/Admin/DocumentByIdPage";
import EditDocumentByIdPage from "pages/Admin/EditDocumentByIdPage";
import NewTemplatePage from "pages/Admin/NewTemplatePage";
import TemplateByIdPage from "pages/Admin/TemplateByIdPage";
import TemplatesPage from "pages/Admin/TemplatePage";
import ViewOrderHistoryByIdPage from "pages/Admin/ViewOrderHistoryByIdPage";
import ViewOrderPage from "pages/Admin/ViewOrderPage";
import EditCompanyPage from "pages/EditCompanyPage";
import ErrorPage from "pages/ErrorPage";
import WorkerInformationPage from "pages/Maintenance/MaintenanceWorkerInformationPage";
import NewCompanyPage from "pages/NewCompanyPage";
import OrderHistoryPage from "pages/OrderHistoryPage";
import React, { useEffect } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import DocumentsPage from "./pages/Admin/DocumentsPage";
import EditClientPage from "./pages/EditClientPage";
import EditWorkerPage from "./pages/EditWorkerPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import LoginPage from "./pages/LoginPage";
import MaintenanceClientInformationPage from "./pages/Maintenance/MaintenanceClientInformation";
import MaintenanceLobbyPage from "./pages/Maintenance/MaintenanceLobbyPage";
import NewClientPage from "./pages/NewClientPage";
import NewWorkerPage from "./pages/NewWorkerPage";
import NewWorkPage from "./pages/NewWorkPage";
import PageFramework from "./pages/PageFramework";
import ServiceLobbyPage from "./pages/Service/ServiceLobbyPage";
import ServiceWorkerInformationPage from "./pages/Service/ServiceWorkerInformation";
import { checkLogin } from "./redux/Auth/action";
import { IRootState } from "./store";

function Main() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: IRootState) => state.auth.isAuthenticated);
  const userRole = useSelector((state: IRootState) => state.auth.role);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(checkLogin());
    }
  }, [dispatch, isLoggedIn]);

  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Redirect to="/service" />
        </Route>
        <Route path="/login" exact component={LoginPage} />
        <Route exact path="/forget-password">
          <ForgetPasswordPage />
        </Route>
        {isLoggedIn && (
          <>
            {highLevel.includes(userRole) && (
              <>
                <PrivateRoute exact path="/admin/document/new-document" component={AddNewDocumentPage} />
                <PrivateRoute exact path="/admin/document/edit/:documentId" component={EditDocumentByIdPage} />
              </>
            )}
            {A_ADD_ORDER.includes(userRole) && (
              <>
                <PrivateRoute exact path="/service/new-work" component={NewWorkPage} />
                <PrivateRoute exact path="/maintenance/new-work" component={NewWorkPage} />
              </>
            )}
            {A_CREATE_NEW_TEMPLATE.includes(userRole) && (
              <PrivateRoute exact path="/admin/document/:documentId/new-template" component={NewTemplatePage} />
            )}
            {A_ADD_COMPANY.includes(userRole) && (
              <>
                <PrivateRoute exact path="/admin/company/new-company" component={NewCompanyPage} />
                <PrivateRoute exact path="/admin/company/:companyId" component={EditCompanyPage} />
              </>
            )}
            {A_ADD_WORKER.includes(userRole) && (
              <>
                <PrivateRoute exact path="/admin/worker/new-worker" component={NewWorkerPage} />
                <PrivateRoute exact path="/admin/worker/:workerId" component={EditWorkerPage} />
              </>
            )}
            {A_ADD_CLIENT.includes(userRole) && (
              <>
                <PrivateRoute exact path="/admin/client/new-client" component={NewClientPage} />
                <PrivateRoute exact path="/admin/client/:clientId" component={EditClientPage} />
              </>
            )}
            {A_VIEW_DOC_TYPE.includes(userRole) && (
              <>
                <PrivateRoute exact path="/admin/document" component={DocumentsPage} />
                <PrivateRoute exact path="/admin/document/:documentId" component={DocumentByIdPage} />
              </>
            )}
            {A_VIEW_COMPANY.includes(userRole) && (
              <>
                <PrivateRoute exact path="/admin/company" component={CompanyPage} />
              </>
            )}
            {A_VIEW_WORKER.includes(userRole) && (
              <>
                <PrivateRoute exact path="/admin/worker" component={WorkerInformationPage} />
              </>
            )}
            {A_VIEW_CLIENT.includes(userRole) && (
              <>
                <PrivateRoute exact path="/admin/client" component={MaintenanceClientInformationPage} />
              </>
            )}
            {A_DASHBOARD.includes(userRole) && (
              <>
                <PrivateRoute exact path="/admin/dashboard" component={DashboardPage} />
              </>
            )}
            {A_MANAGE_DISTRICT.includes(userRole) && <PrivateRoute exact path="/admin/area" component={AreaPage} />}
            {userRole !== "WORKER" && (
              <>
                <PrivateRoute exact path="/admin/template" component={TemplatesPage} />
                <PrivateRoute exact path="/admin/template/:templateId" component={TemplateByIdPage} />
                <PrivateRoute exact path="/service/work/:jobNo" component={PageFramework} />
                <PrivateRoute exact path="/service/information/worker" component={ServiceWorkerInformationPage} />
                <PrivateRoute exact path="/maintenance/work/:jobNo" component={PageFramework} />
                <PrivateRoute exact path="/stats" component={PageFramework} />
                <PrivateRoute exact path="/maintenance/view/:jobNo" component={ViewOrderPage} />
                <PrivateRoute exact path="/service/view/:jobNo" component={ViewOrderPage} />
                <PrivateRoute exact path="/service" component={ServiceLobbyPage} />
                <PrivateRoute exact path="/maintenance" component={MaintenanceLobbyPage} />
                <PrivateRoute exact path="/all-orders" component={AllOrdersLobbyPage} />
              </>
            )}
            <PrivateRoute exact path="/order-history" component={OrderHistoryPage} />
            <PrivateRoute exact path="/order-history/view/:jobNo" component={ViewOrderHistoryByIdPage} />
          </>
        )}
        <Route path="*" component={ErrorPage} />
      </Switch>
      <SaveSuccessModal />
      {isMobile && !isTablet && <NeedLargerDeviceModal />}
    </div>
  );
}

export default Main;
