import { FetchOrderHistoryByClientId, IClients, IPagination } from '../../models/responseModels';

export const setClientData = (data: IClients) => {
  return {
    type: '@@Pagination/setClientData' as const,
    data,
  };
};
export const setClientModalOpen = (isOpen: boolean) => {
  return {
    type: '@@Pagination/setClientModalOpen' as const,
    isOpen,
  };
};
export const setClientHistory = (data: FetchOrderHistoryByClientId[]) => {
  return {
    type: '@@Pagination/setClientHistory' as const,
    data,
  };
};
//pagination
export const setClientPagination = (data: IPagination) => {
  return {
    type: '@@Pagination/setClientPagination' as const,
    data,
  };
};

export const setClientCurrentPage = (currentPage: number) => {
  return {
    type: '@@Pagination/setClientCurrentPage' as const,
    currentPage,
  };
};
export const resetClientPagination = () => {
  return {
    type: '@@Pagination/resetClientPagination' as const,
  };
};

export const setClientChangesMade = (changesMade: boolean) => {
  return {
    type: '@@Pagination/setClientChangesMade' as const,
    changesMade,
  };
};
//end of pagination

type ActionCreators =
  | typeof setClientPagination
  | typeof setClientCurrentPage
  | typeof resetClientPagination
  | typeof setClientChangesMade
  | typeof setClientHistory
  | typeof setClientModalOpen
  | typeof setClientData;

export type ClientActions = ReturnType<ActionCreators>;
