import axios from "axios";
import { CloseIcon } from "components/IconsOnly";
import "css/Dynamics.css";
import { formatDate, formatDateAndTime, setDateToStart } from "helpers/date";
import { highLevel } from "models/authorities";
import React, { useRef, useState } from "react";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setMessageModal } from "redux/Loading/action";
import { setOrderByIdData, setWorkChangesMade } from "redux/Order/action";
import { IRootState } from "store";

interface DynamicsProps {
  isOpen: boolean;
  closeModal: () => void;
}

export default function DynamicsDetails(props: DynamicsProps) {
  const [showCopied, setShowCopied] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const copyRef = useRef<HTMLTableRowElement>(null);
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const orderById = useSelector((state: IRootState) => state.orderById);
  const order = orderById.orderById;
  const tableHeads = [
    "Job No.",
    "Job Creation Date",
    "Dept.",
    "Person In Charge",
    "Customer Code",
    "Customer Name",
    "Job Location",
    "Job Description",
    "Contract Amount (HKs)",
    "Quotation No.",
    "Customer PO No.",
    "Estimate Job Starting Date",
    "Estimate Job Completion Date",
    "Actual Job Starting Date",
    "Actual Job Completion Date",
  ];
  const dispatch = useDispatch();

  const copyRow = () => {
    let allString = "";
    const td = copyRef.current?.getElementsByTagName("td");
    if (td) {
      for (let i = 0; i < td?.length; i++) {
        allString = allString + td[i].innerText + "\t";
      }
    }
    navigator.clipboard.writeText(allString);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 2000);
  };

  const handleSubmit = async () => {
    try {
      await axios.put(`/orders/${order.id}`, {
        order: {
          billToClient: order.billToClient,
          personInCharge: order.personInCharge,
          customerCode: order.customerCode,
          salesQuoteNo: order.salesQuoteNo,
          externalDocumentNo: order.externalDocumentNo,
          contractAmount: order.contractAmount,
          expectedStartDate: order.expectedStartDate,
          expectedCompleteDate: order.expectedCompleteDate,
        },
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      setIsEditMode(false);
      dispatch(setWorkChangesMade(true));
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  return (
    <Modal size="xl" isOpen={props.isOpen} style={{ width: "75%" }} contentClassName="p-4">
      <div className="p-2 relative" style={{ overflow: "hidden" }}>
        <div className="full-width flex-row-between absolute" style={{ left: 0 }}>
          {highLevel.includes(userRole) && (
            <div className="w-50">
              <Button
                className="w-50 pointer"
                color={isEditMode ? "dark" : "primary"}
                onClick={() => setIsEditMode(!isEditMode)}
              >
                {isEditMode ? "VIEW" : "EDIT"}
              </Button>
            </div>
          )}
          <div className="full-width flex-row-end">
            <div className="pointer" onClick={props.closeModal}>
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="my-4" style={{ paddingTop: "32px", overflowX: "auto" }}>
          {isEditMode ? (
            <>
              <Row>
                <DynamicFormFields
                  keyText={"Bill to client:"}
                  valueText={order.billToClient ?? ""}
                  targetKey={"billToClient"}
                />
                <DynamicFormFields
                  keyText={"Person in charge:"}
                  valueText={order.personInCharge ?? ""}
                  targetKey={"personInCharge"}
                />
                <DynamicFormFields
                  keyText={"Customer Code:"}
                  valueText={order.customerCode ?? ""}
                  targetKey={"customerCode"}
                />
              </Row>
              <Row>
                <DynamicFormFields
                  keyText={"Quotation No.:"}
                  valueText={order.salesQuoteNo ?? ""}
                  targetKey={"salesQuoteNo"}
                />
                <DynamicFormFields
                  keyText={"Customer PO No.:"}
                  valueText={order.externalDocumentNo ?? ""}
                  targetKey={"externalDocumentNo"}
                />
                <DynamicFormFields
                  keyText={"Contract Amount:"}
                  valueText={order.contractAmount?.toString() ?? ""}
                  targetKey={"contractAmount"}
                />
              </Row>
              <Row className="flex-row-start">
                <Col md={4} className={`p-2 full-width flex-row-column-start flex-column flex1`}>
                  <div className="flex-row-center" style={{ width: "max-content" }}>
                    <h6 style={{ whiteSpace: "nowrap" }}>{"Expected start date:"}</h6>
                  </div>
                  <DatePicker
                    calendarType={"US"}
                    minDate={
                      userRole === "SUPER_ADMIN" ? undefined : setDateToStart(new Date(Date.now()).toISOString())
                    }
                    format={"y-MM-dd"}
                    onChange={(e: Date) => {
                      if (!e) {
                        dispatch(setOrderByIdData({ ...order, expectedStartDate: null }));
                      } else {
                        if (
                          !!order.expectedCompleteDate &&
                          setDateToStart(order.expectedCompleteDate).valueOf() <
                            setDateToStart(e.toISOString()).valueOf()
                        ) {
                          dispatch(
                            setOrderByIdData({
                              ...order,
                              expectedStartDate: e.toISOString(),
                              expectedCompleteDate: null,
                            })
                          );
                        } else {
                          dispatch(setOrderByIdData({ ...order, expectedStartDate: e.toISOString() }));
                        }
                      }
                    }}
                    value={!order.expectedStartDate ? null : new Date(order.expectedStartDate)}
                    className="calendarPicker"
                    calendarClassName="calendar"
                  />
                </Col>
                <Col md={4} className={`p-2 full-width flex-row-column-start flex-column flex1`}>
                  <div className="flex-row-center" style={{ width: "max-content" }}>
                    <h6 style={{ whiteSpace: "nowrap" }}>{"Expected complete date:"}</h6>
                  </div>
                  <DatePicker
                    calendarType={"US"}
                    minDate={
                      !order.expectedStartDate
                        ? userRole === "SUPER_ADMIN"
                          ? undefined
                          : setDateToStart(new Date(Date.now()).toISOString())
                        : new Date(order.expectedStartDate)
                    }
                    format={"y-MM-dd"}
                    onChange={(e: Date) => {
                      if (!e) {
                        dispatch(setOrderByIdData({ ...order, expectedCompleteDate: null }));
                      } else {
                        dispatch(setOrderByIdData({ ...order, expectedCompleteDate: e.toISOString() }));
                      }
                    }}
                    value={!order.expectedCompleteDate ? null : new Date(order.expectedCompleteDate)}
                    className="calendarPicker"
                    calendarClassName="calendar"
                  />
                </Col>
                <Col md={4} />
              </Row>
            </>
          ) : (
            <table className="my-4">
              <thead>
                <tr>
                  {tableHeads.map((item) => (
                    <th className="px-3 thBackground">{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr ref={copyRef} id="copyRef">
                  <td className="tbodyStyle">{order.acjNo ?? "-"}</td>
                  <td className="tbodyStyle">{formatDateAndTime(order.createdAt) ?? "-"}</td>
                  <td className="tbodyStyle">{order.serviceType ?? "-"}</td>
                  <td className="tbodyStyle">{order.personInCharge ?? "-"}</td>
                  <td className="tbodyStyle">{order.customerCode ?? "-"}</td>
                  <td className="tbodyStyle">{order.billToClient ?? "-"}</td>
                  <td className="tbodyStyle">{order.clientAddress ?? "-"}</td>
                  <td className="tbodyStyle">{order.serviceSubject ?? "-"}</td>
                  <td className="tbodyStyle">{order.contractAmount ?? "-"}</td>
                  <td className="tbodyStyle">{order.salesQuoteNo ?? "-"}</td>
                  <td className="tbodyStyle">{order.externalDocumentNo ?? "-"}</td>
                  <td className="tbodyStyle">{order.expectedStartDate ? formatDate(order.expectedStartDate) : "-"}</td>
                  <td className="tbodyStyle">
                    {order.expectedCompleteDate ? formatDate(order.expectedCompleteDate) : "-"}
                  </td>
                  <td className="tbodyStyle">{order.startDate ? formatDate(order.startDate) : "-"}</td>
                  <td className="tbodyStyle">{order.completeDate ? formatDate(order.completeDate) : "-"}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        {isEditMode ? (
          <div className="flex-center">
            <Button color="primary" className="w-30" onClick={handleSubmit}>
              SAVE
            </Button>
          </div>
        ) : (
          <div
            className="pointer px-3 py-1 relative"
            onClick={copyRow}
            style={{
              zIndex: 1,
              background: "#F9F9F9",
              width: "fit-content",
              borderRadius: "4px",
              boxShadow: "0 2px 2px #0003",
            }}
          >
            Copy to clipboard
            {showCopied && (
              <div
                className={`absolute px-3 py-1 ${showCopied && "copied"}`}
                style={{ top: "0%", borderRadius: "4px", color: "#888" }}
              >
                Copied!
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

interface FormFieldsProps {
  keyText: string;
  valueText: string;
  targetKey: string;
  placeholderText?: string;
}

export const DynamicFormFields = (props: FormFieldsProps) => {
  const dispatch = useDispatch();
  const data = useSelector((state: IRootState) => state.orderById.orderById);
  return (
    <Col md={6} className={`p-2 full-width flex-row-column-start flex-column flex1`}>
      <div className="flex-row-center" style={{ width: "max-content" }}>
        <h6 style={{ whiteSpace: "nowrap" }}>{props.keyText}</h6>
      </div>
      <input
        className="inputStyle"
        placeholder={props.placeholderText ?? ""}
        style={{ width: "80%" }}
        value={props.valueText ?? ""}
        onChange={(e) => {
          dispatch(setOrderByIdData({ ...data, [props.targetKey]: e.target.value }));
        }}
      ></input>
    </Col>
  );
};
