import { InfoBarTypeActions } from './action';
import { InfoBarState } from './state';

export const initialState: InfoBarState = {
  infoBar: {
    infoBarType: '',
    innerType: '',
    isActive: 0,
  },
};

export default function infoBarReducer(state: InfoBarState = initialState, action: InfoBarTypeActions) {
  switch (action.type) {
    case '@@infoBar/SetInfoBarType':
      return {
        ...state,
        infoBar: {
          ...state.infoBar,
          infoBarType: action.types.infoBarType ?? state.infoBar.infoBarType,
          innerType: action.types.innerType ?? '',
        },
      };
    case '@@infoBar/SetInfoBarActive':
      return {
        ...state,
        infoBar: {
          ...state.infoBar,
          isActive: action.index,
        },
      };
    default:
      return state;
  }
}
