import React from "react";

interface TableHeadersProps {
  headers: string[];
  tableWidth?: string;
}

const longItem = ["地址", "電郵地址", "文件類型名稱", "註解", "範本名稱", "文件名稱"];
const mainItem = ["所屬公司", "公司名稱"];
const districtItem = ["地區", "居住區域", "部門", "所屬部門"];
const actionItem = ["選擇", "動作", "排序"];

function TableHeaders(props: TableHeadersProps) {
  const { headers, tableWidth = "unset" } = props;
  return (
    <div className="flex-center tableHeader full-height cursor-default" style={{ width: tableWidth }}>
      {headers.map((item, idx) => {
        if (longItem.includes(item)) {
          return (
            <div key={item + idx} className="flex-center thLongItem full-height cursor-default">
              {item}
            </div>
          );
        } else if (mainItem.includes(item)) {
          return (
            <div key={item + idx} className="flex-center thMainItem full-height cursor-default">
              {item}
            </div>
          );
        } else if (districtItem.includes(item)) {
          return (
            <div key={item + idx} className="flex-center thDistrict full-height cursor-default">
              {item}
            </div>
          );
        } else if (actionItem.includes(item)) {
          return (
            <div key={item + idx} className="flex-center thAction full-height cursor-default">
              {item}
            </div>
          );
        }
        return (
          <div key={item + idx} className="flex-center thItem full-height cursor-default">
            {item}
          </div>
        );
      })}
    </div>
  );
}

export default TableHeaders;
