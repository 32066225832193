import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Modal, Row } from "reactstrap";
import { IRootState } from "store";

function WorkerRedirectModal() {
  const isOpen = useSelector((state: IRootState) => state.auth.workerRedirectModalIsOpen);
  return (
    <Modal centered isOpen={isOpen}>
      <Container className="p-4">
        <Row>
          <Col className="flex-column-center">
            <h4 className="my-2">請使用專用平台</h4>
            <a className="mt-3 mb-2 flex-center full-width" href="https://mobile.viewco.muselabs.app">
              https://mobile.viewco.muselabs.app
            </a>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

export default WorkerRedirectModal;
