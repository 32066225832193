import { NavLink, NavItem } from "reactstrap";
import classnames from "classnames";
import globalStyle from "css/global.module.scss";

interface IReactstrapTabNavLink {
  activeTab: string;
  tabContent: string;
  toggle(tab: string): void;
  changed?: boolean;
}

export default function ReactstrapTabNavLink({
  activeTab,
  tabContent,
  toggle,
  changed = false,
}: IReactstrapTabNavLink) {
  return (
    <NavItem className={globalStyle["navItem"]}>
      <NavLink
        className={classnames({ active: activeTab === tabContent })}
        onClick={() => {
          if (!changed) {
            toggle(tabContent);
          } else {
            alert("請先儲存更改");
          }
        }}
        style={{ height: "40px", minWidth: "140px", textAlign: "center" }}
      >
        {tabContent}
      </NavLink>
    </NavItem>
  );
}
