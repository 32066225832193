import axios from "axios";
import { CloseIcon } from "components/IconsOnly";
import { formatDateAndTime } from "helpers/date";
import { DISTRICTS } from "models/districtModels";
import { IClients, IPagination } from "models/responseModels";
import { AREAS } from "models/variables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Modal, Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setClientChangesMade, setClientCurrentPage } from "redux/Client/action";
import { setIsLoading } from "redux/Loading/action";
import { IRootState } from "store";
import { ActionRow } from "./ActionRow";
import FormHeader from "./FormHeader";
import SortableTableHeaders from "./SortableTableHeaders";
import ViewClientModal from "./ViewClientModal";

interface SelectClientModalProps {
  isOpen: boolean;
  closeModalAction: (args0: boolean) => void;
  handleSelect: (args0: number) => void;
}

function SelectClientModal(props: SelectClientModalProps) {
  const { handleSelect } = props;
  const [sortByDirection, setSortByDirection] = useState<{ sortType: string; isDesc: boolean }>({
    sortType: "updatedAt",
    isDesc: true,
  });
  // const [modal, setModal] = useState(false);
  const [allClients, setAllClients] = useState<Array<IClients>>([]);
  const [lastPage, setLastPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  // const history = useHistory();
  const sortableHeaders = [
    { headerText: "名稱", headerKey: "name" },
    { headerText: "區域", headerKey: "area" },
    { headerText: "地區", headerKey: "district" },
    { headerText: "聯絡人", headerKey: "contactPerson" },
    { headerText: "聯絡電話", headerKey: "tel" },
    { headerText: "地址", headerKey: "address" },
    { headerText: "更新日期", headerKey: "updatedAt" },
  ];

  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);

  const dispatch = useDispatch();

  const pagination = useSelector((state: IRootState) => state.client.pagination);
  const changesMade = useSelector((state: IRootState) => state.client.changesMade);
  const global = useSelector((state: IRootState) => state.loading);
  const maxRow = global.maxRow;
  const userPreferredRow = global.userPreferredRow;

  const fetchAllClients = async (pageNumber: number) => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/clients`);
      if (userPreferredRow) {
        url.searchParams.set("rows", userPreferredRow.toString());
      } else {
        url.searchParams.set("rows", maxRow.toString());
      }
      url.searchParams.set("page", pageNumber.toString());
      if (!!searchText) url.searchParams.set("search", searchText);
      url.searchParams.set("order", `${sortByDirection.sortType}`);
      url.searchParams.set("direction", `${sortByDirection.isDesc ? "desc" : "asc"}`);
      const res = await axios.get<{ data: any; pagination: IPagination }>(url.toString());
      const result = res.data;
      setAllClients(result.data);
      setLastPage(result.pagination.lastPage);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    if (changesMade) {
      fetchAllClients(1);
      dispatch(setClientCurrentPage(1));
      dispatch(setClientChangesMade(false));
    } else {
      fetchAllClients(pagination.currentPage);
    }
  }, []);

  useEffect(() => {
    // if (modal) return;
    fetchAllClients(pagination.currentPage);
  }, [dispatch, pagination.currentPage, maxRow, userPreferredRow]);

  const handleSearch = () => {
    fetchAllClients(1);
  };

  const handleControlSort = (sortType: string) => {
    if (sortByDirection.sortType === sortType) {
      setSortByDirection({ sortType, isDesc: !sortByDirection.isDesc });
    } else {
      setSortByDirection({ sortType, isDesc: true });
    }
    fetchAllClients(1);
  };

  return (
    <Modal size="xl" isOpen={props.isOpen}>
      <Container className="p-3">
        <div className="p-3 relative" style={{ overflowX: "hidden" }}>
          <FormHeader
            offset={false}
            header={"選擇客戶"}
            includeExtraButton={true}
            children={
              <div onClick={() => props.closeModalAction(false)}>
                <CloseIcon />
              </div>
            }
          />
          <ActionRow
            isSelectionMode={true}
            lastPage={lastPage}
            currentPage={pagination.currentPage}
            addItem={"客戶"}
            setCurrentPageFunction={setClientCurrentPage}
            handleSearch={handleSearch}
            searchText={searchText}
            setSearchText={setSearchText}
            placeholderText={"名稱 / 電郵 / 聯絡人 / 電話 / 地址"}
          />
          {!allClients.length ? (
            <div className="my-2 flex-center full-width disableText">尚未加入客戶</div>
          ) : (
            <div className="flex-center full-width">
              <div className="p-2 viewCoTable">
                <SortableTableHeaders
                  headers={sortableHeaders}
                  sortControl={handleControlSort}
                  currentSorting={sortByDirection}
                />
                <div className="tableBody relative">
                  {isLoading ? (
                    <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
                  ) : (
                    allClients.map((item, idx) => {
                      return (
                        <div key={idx} className={`flex-center tableRow`} onClick={() => handleSelect(item.id)}>
                          <div className="flex-center tdItem">{item.name || "-"}</div>
                          <div className="flex-center tdDistrict">{AREAS[item.area] || "-"}</div>
                          <div className="flex-center tdDistrict">{DISTRICTS[item.district] || "-"}</div>
                          <div className="flex-center tdItem">{item.contactPerson || "-"}</div>
                          <div className="flex-center tdItem">{item.tel || "-"}</div>
                          <div className="tdLongItem">{item.address || "-"}</div>
                          <div className="flex-center tdDateItem">{formatDateAndTime(item.updatedAt)}</div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          )}
          <ViewClientModal />
        </div>
      </Container>
    </Modal>
  );
}

export default SelectClientModal;
