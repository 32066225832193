import axios from "axios";
import "css/Form.css";
import { DISTRICTS, DistrictType } from "models/districtModels";
import { IClients } from "models/responseModels";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setClientChangesMade } from "redux/Client/action";
import { setIsLoading, setMessageModal } from "redux/Loading/action";
import DistrictModal from "./parts/DistrictModal";
import FormHeader from "./parts/FormHeader";

interface INewClient {
  header: string;
}

interface IClientInfo {
  name: string;
  email: string;
  tel: string;
  contactPerson: string;
  district: DistrictType;
  address: string;
  clientType: string;
}

export default function EditClient({ header }: INewClient) {
  const [districtSelectionOpen, setDistrictSelectionOpen] = useState(false);
  const [clientInfo, setClientInfo] = useState<IClientInfo>({
    address: "",
    contactPerson: "",
    district: "CENTRAL_AND_WESTERN",
    email: "",
    name: "",
    tel: "",
    clientType: "",
  });

  const handleExit = () => {
    history.goBack();
    setClientInfo({
      address: "",
      contactPerson: "",
      district: "CENTRAL_AND_WESTERN",
      email: "",
      name: "",
      tel: "",
      clientType: "",
    });
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = window.location.pathname.split("/");
  const clientId = pathname[pathname.length - 1];

  useEffect(() => {
    const fetchClientById = async () => {
      dispatch(setIsLoading(true));
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/clients`);
        url.searchParams.set("id", clientId);
        const res = await axios.get<IClients>(url.toString());
        const result = res.data;
        setClientInfo({
          address: result.address,
          contactPerson: result.contactPerson,
          district: result.district,
          email: result.email,
          name: result.name,
          tel: result.tel,
          clientType: result.clientType,
        });
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    fetchClientById();
    return () => {
      setClientInfo({
        address: "",
        contactPerson: "",
        district: "CENTRAL_AND_WESTERN",
        email: "",
        name: "",
        tel: "",
        clientType: "",
      });
    };
  }, [dispatch, clientId]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.put(`/clients/${clientId}`, {
        client: clientInfo,
      });
      dispatch(setClientChangesMade(true));
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      handleExit();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  const handleSelectDistrict = (item: DistrictType) => {
    setClientInfo({ ...clientInfo, district: item });
    setDistrictSelectionOpen(false);
  };

  return (
    <Container className="p-3">
      <FormHeader offset={false} header={header} />
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 客戶名稱:</div>
          </Col>
          <Col>
            <input
              type="text"
              name="name"
              value={clientInfo.name}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, name: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 聯絡人: </div>
          </Col>
          <Col>
            <input
              type="text"
              name="contactPerson"
              value={clientInfo.contactPerson}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, contactPerson: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 聯絡電話: </div>
          </Col>
          <Col>
            <input
              type="text"
              name="tel"
              value={clientInfo.tel}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, tel: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 地區:</div>
          </Col>
          <Col className="d-flex align-items-center">
            <div>{DISTRICTS[clientInfo.district] ?? "選擇地區"}</div>
            <div className="chooseButton" onClick={() => setDistrictSelectionOpen(true)}>
              選擇
            </div>
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 地址: </div>
          </Col>
          <Col>
            <input
              type="text"
              name="address"
              value={clientInfo.address}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, address: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 電郵地址: </div>
          </Col>
          <Col>
            <input
              type="email"
              name="email"
              value={clientInfo.email}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, email: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> Customer Type: </div>
          </Col>
          <Col>
            <input
              type="text"
              name="clientType"
              value={clientInfo.clientType}
              className="inputStyle"
              onChange={(e) => setClientInfo({ ...clientInfo, clientType: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="d-flex flex-center">
          <Button style={{ width: "50%", margin: "8px" }} type="submit">
            {"修改"}
          </Button>
        </Row>
      </form>

      <DistrictModal
        isOpen={districtSelectionOpen}
        handleSelectDistrict={handleSelectDistrict}
        handleClose={() => setDistrictSelectionOpen(false)}
      />
    </Container>
  );
}
