import { IAuthActions } from './action';
import { IAuthState } from './state';

export const initialState: IAuthState = {
  isAuthenticated: false,
  role: '',
  userId: '',
  msg: '',
  workerRedirectModalIsOpen: false,
};

export default function authReducer(state: IAuthState = initialState, action: IAuthActions) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        role: action.role,
        userId: action.userId,
        msg: '',
      };
    case 'LOGIN_FAILED':
      return {
        ...state,
        isAuthenticated: false,
        msg: action.error,
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        isAuthenticated: false,
        msg: '',
      };
    case 'Auth/openWorkerRedirect':
      return {
        ...state,
        workerRedirectModalIsOpen: true,
      };
    default:
      return state;
  }
}
