import { useForm } from "react-hook-form";
import { FaPhoneAlt, FaUser } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { Button, Form, Input } from "reactstrap";
import style from "../css/LoginPage.module.scss";

interface IData {
  email: string;
  contactNumber: string;
}

export default function ForgetPasswordPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  function onSubmit(data: IData) {
    // console.log(data)
  }

  return (
    <div className={style["background"]}>
      <div id={style["loginSquare"]}>
        <div id={style["heading"]}>
          <h1>Forget Password</h1>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} id={style["form"]}>
          <div className={style["row"]}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "yellow" }}>
              <FaUser />
            </div>
            <Input
              className={style["input"]}
              type="email"
              {...register("email", { required: true })}
              placeholder="email"
            />
          </div>
          {errors.email && <div className={style["error"]}>請填寫此項資料</div>}

          <div className={style["row"]}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "yellow" }}>
              <FaPhoneAlt />
            </div>
            <Input
              className={style["input"]}
              type="password"
              {...register("password", { required: true })}
              placeholder="contact number"
            />
          </div>
          {errors.password && <div className={style["error"]}>請填寫此項資料</div>}

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexFlow: "row nowrap",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className={style["back"]}
              onClick={() => {
                history.push("/");
              }}
            >
              <IoIosArrowBack size="24px" />
              Back
            </div>
            <Button type="submit" color="primary">
              Apply
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
