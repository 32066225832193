// M1  例行保養  = 維修部現有保養合約客戶，定期例行保養工作
//  M1  免費e-call = 維修部現有保養合約客戶，按合約內容已包括e-call服務不另收費
//  M2  收費e-call = 維修部現有保養合約客戶，非保養時間/非合約內容項目，需另外收費)
//  M2  維修工程  = 維修部之維修 / 更換 / 更改工程 (不包括在保養合約內的工作)
// M3  維修工程  = 維修部Bulk Rate工程 (跟合約內Unit Rate工作)
// P1   工程保養  = 工程部保養合約客戶，定期例行保養工作
// P2   大型工程  = 工程部之工程項目

import { ServiceTypes } from './variables';

export const getServiceTypeText = (serviceType: ServiceTypes) => {
  switch (serviceType) {
    case 'M1':
      return '維修部現有保養合約客戶，定期例行保養工作';
    case 'M1(E-CALL)':
      return '維修部現有保養合約客戶，按合約內容已包括e-call服務不另收費';
    case 'M2':
      return '維修部之維修 / 更換 / 更改工程 (不包括在保養合約內的工作)';
    case 'M2(E-CALL)':
      return '維修部現有保養合約客戶，非保養時間/非合約內容項目，需另外收費';
    case 'M3':
      return '維修部Bulk Rate工程 (跟合約內Unit Rate工作)';
    case 'P1':
      return '工程部保養合約客戶，定期例行保養工作';
    case 'P2':
      return '工程部之工程項目';
  }
};
