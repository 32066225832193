import { IPagination } from '../../models/responseModels';

export const setCompanyPagination = (data: IPagination) => {
  return {
    type: '@@Pagination/setCompanyPagination' as const,
    data,
  };
};

export const setCompanyCurrentPage = (currentPage: number) => {
  return {
    type: '@@Pagination/setCompanyCurrentPage' as const,
    currentPage,
  };
};
export const resetCompanyPagination = () => {
  return {
    type: '@@Pagination/resetCompanyPagination' as const,
  };
};
export const setCompanyChangesMade = (changesMade: boolean) => {
  return {
    type: '@@Pagination/setCompanyChangesMade' as const,
    changesMade,
  };
};

type ActionCreators =
  | typeof setCompanyPagination
  | typeof setCompanyCurrentPage
  | typeof resetCompanyPagination
  | typeof setCompanyChangesMade;

export type CompanyActions = ReturnType<ActionCreators>;
