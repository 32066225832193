import axios from "axios";
import modal from "bootstrap/js/dist/modal";
import {
  A_ADD_CLIENT,
  A_ADD_COMPANY,
  A_ADD_WORKER,
  A_ASSIGN_WORKER,
  A_DELETE_MEDIA,
  A_DELETE_ORDER,
} from "models/authorities";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setCompanyChangesMade } from "redux/Companies/action";
import { setChangesMade } from "redux/Document/action";
import { setWorkChangesMade } from "redux/Order/action";
import { setTemplateChangesMade } from "redux/Template/action";
import { IRootState } from "store";

interface DeleteModalProps {
  isOpen: boolean;
  deleteTarget: { name: string; id: number };
  setModal: (args0: boolean) => void;
  addItemString: string;
}

function DeleteModal(props: DeleteModalProps) {
  const { isOpen, deleteTarget, setModal, addItemString } = props;
  const dispatch = useDispatch();
  const userRole = useSelector((state: IRootState) => state.auth.role);

  const handleDelete = async () => {
    if (addItemString === "員工" && A_ADD_WORKER.includes(userRole)) {
      try {
        await axios.delete(`/users`, {
          data: {
            userIds: [deleteTarget.id],
          },
        });
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        setModal(!modal);
      }
    }
    if (addItemString === "客戶" && A_ADD_CLIENT.includes(userRole)) {
      try {
        await axios.delete(`/clients`, {
          data: {
            clientIds: [deleteTarget.id],
          },
        });
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        setModal(!modal);
      }
    }
    if ((addItemString === "工作單" || addItemString === "保養單") && A_DELETE_ORDER.includes(userRole)) {
      try {
        await axios.delete(`/orders`, {
          data: {
            orderIds: [deleteTarget.id],
          },
        });
        dispatch(setWorkChangesMade(true));
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        setModal(!modal);
      }
    }
    if (addItemString === "文件類型") {
      try {
        await axios.delete(`/doc-types`, {
          data: {
            docTypeIds: [deleteTarget.id],
          },
        });
        dispatch(setChangesMade(true));
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        setModal(!modal);
      }
    }
    if (addItemString === "文件") {
      try {
        await axios.delete(`/doc`, {
          data: {
            docIds: [deleteTarget.id],
          },
        });
        dispatch(setTemplateChangesMade(true));
        dispatch(setChangesMade(true));
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        setModal(!modal);
      }
    }
    if (addItemString === "工程單員工" && A_ASSIGN_WORKER.includes(userRole)) {
      const pathname = window.location.pathname.split("/");
      const orderId = pathname[pathname.length - 1];
      try {
        await axios.delete(`/order-staffs/${orderId}`, {
          data: {
            staffIds: [deleteTarget.id],
          },
        });
        dispatch(setChangesMade(true));
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        setModal(!modal);
      }
    }
    if (addItemString === "圖片" && A_DELETE_MEDIA.includes(userRole)) {
      try {
        await axios.delete(`/order-media`, {
          data: {
            mediaIds: [deleteTarget.id],
          },
        });
        dispatch(setChangesMade(true));
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        setModal(!modal);
      }
    }
    if (addItemString === "公司" && A_ADD_COMPANY.includes(userRole)) {
      try {
        await axios.delete(`/companies`, {
          data: {
            companyIds: [deleteTarget.id],
          },
        });
        dispatch(setCompanyChangesMade(true));
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        setModal(!modal);
      }
    }
  };

  return (
    <Modal isOpen={isOpen}>
      {addItemString === "圖片" ? (
        <>
          <ModalHeader style={{ border: "none" }}>移除{addItemString}</ModalHeader>
          <ModalBody>確定要移除{addItemString}嗎?</ModalBody>
        </>
      ) : (
        <>
          <ModalHeader style={{ border: "none" }}>
            移除{addItemString} - {deleteTarget.name}
          </ModalHeader>
          <ModalBody>
            確定要移除{addItemString} - {deleteTarget.name} 嗎?
          </ModalBody>
        </>
      )}

      <ModalFooter style={{ border: "none" }}>
        <Button color="danger" onClick={handleDelete}>
          確定
        </Button>
        <Button color="secondary" onClick={() => setModal(!modal)}>
          取消
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteModal;
