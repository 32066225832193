import { TemplateActions } from './action';
import { TemplateState } from './state';

export const initialState: TemplateState = {
  templateData: {
    id: -1,
    division: null,
    docTypeDescription: '',
    docTypeId: -1,
    docTypeIsActive: false,
    docTypeName: '',
    formNo: '',
    isTemplate: false,
    name: '',
    updatedAt: '',
    createdAt: '',
  },
  templateDataArray: [],
  templateContentArray: [],
  changesMade: false,
  pagination: {
    currentPage: 1,
    from: 1,
    lastPage: 1,
    perPage: 10,
    to: 1,
    total: 1,
  },
};

export default function templateReducer(state: TemplateState = initialState, action: TemplateActions) {
  switch (action.type) {
    case '@@Templates/setAllTemplatesArray':
      return {
        ...state,
        templateDataArray: action.data,
      };
    case '@@Templates/setTemplateData':
      return {
        ...state,
        templateData: {
          id: action.data.id ?? state.templateData.id,
          division: action.data.division ?? state.templateData.division,
          docTypeDescription: action.data.docTypeDescription ?? state.templateData.docTypeDescription,
          docTypeId: action.data.docTypeId ?? state.templateData.docTypeId,
          docTypeIsActive: action.data.docTypeIsActive ?? state.templateData.docTypeIsActive,
          docTypeName: action.data.docTypeName ?? state.templateData.docTypeName,
          formNo: action.data.formNo ?? state.templateData.formNo,
          isTemplate: action.data.isTemplate ?? state.templateData.isTemplate,
          name: action.data.name ?? state.templateData.name,
          updatedAt: action.data.updatedAt ?? state.templateData.updatedAt,
          createdAt: action.data.createdAt ?? state.templateData.createdAt,
        },
      };
    case '@@Templates/resetTemplateData':
      return {
        ...state,
        templateData: {
          ...initialState.templateData,
        },
      };
    case '@@Templates/setTemplateContentArray':
      return {
        ...state,
        templateContentArray: action.data,
      };
    case '@@Templates/setTemplateChangesMade':
      return {
        ...state,
        changesMade: action.changesMade,
      };
    case '@@Pagination/setTemplatePagination':
      return {
        ...state,
        pagination: action.data,
      };
    case '@@Pagination/setTemplateCurrentPage':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.currentPage,
        },
      };
    case '@@Pagination/resetTemplatePagination':
      return {
        ...state,
        pagination: { ...initialState.pagination },
      };

    default:
      return state;
  }
}
