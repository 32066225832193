import { LoadingActions } from './action';
import { LoadingState } from './state';

const maxRow = Math.floor((window.innerHeight - 58 - 190 - 64) / 40);

export const initialState: LoadingState = {
  isLoading: false,
  maxRow: Math.max(1, maxRow),
  userPreferredRow: null,
  messageModal: {
    isOpen: false,
    content: '',
  },
};

export default function loadingReducer(state: LoadingState = initialState, action: LoadingActions) {
  switch (action.type) {
    case '@@Loading/setIsLoading':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case '@@Loading/setMaxRow':
      return {
        ...state,
        maxRow: action.row,
      };
    case '@@Loading/setUserPreferredRow':
      return {
        ...state,
        userPreferredRow: action.row,
      };
    case '@@Loading/setMessageModal':
      return {
        ...state,
        messageModal: {
          isOpen: action.data.isOpen,
          content: action.data.content ?? initialState.messageModal.content,
        },
      };
    default:
      return state;
  }
}
