import axios from "axios";
import { CloseIcon, DownCaret } from "components/IconsOnly";
import { DISTRICTS, DistrictType } from "models/districtModels";
import { FetchedDistrict } from "models/responseModels";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Modal, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";

interface DistrictModalProps {
  isOpen: boolean;
  handleSelectDistrict: (item: DistrictType) => void;
  handleClose: (args0: boolean) => void;
}

type OpenType = "HK" | "KLN" | "NT" | "OTHER" | "";

export default function DistrictModal(props: DistrictModalProps) {
  const { isOpen, handleSelectDistrict, handleClose } = props;
  const [areaOpen, setAreaOpen] = useState<OpenType>("");
  const [areas, setAreas] = useState<{
    defaultArea: DistrictType[];
    hkArea: DistrictType[];
    klnArea: DistrictType[];
    ntArea: DistrictType[];
  }>({
    defaultArea: [],
    hkArea: [],
    klnArea: [],
    ntArea: [],
  });
  const dispatch = useDispatch();

  const fetchAllDistricts = async () => {
    try {
      const res = await axios.get<{ data: FetchedDistrict }>(`/static/districts`);
      const result = await res.data;
      setAreas({
        defaultArea: result.data.others,
        hkArea: result.data.HONG_KONG,
        klnArea: result.data.KOWLOON,
        ntArea: result.data.NEW_TERRITORIES,
      });
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const handleOpen = (openType: OpenType) => {
    if (openType === areaOpen) {
      setAreaOpen("");
    } else {
      setAreaOpen(openType);
    }
  };

  useEffect(() => {
    fetchAllDistricts();
  }, []);

  return (
    <Modal isOpen={isOpen}>
      <Container className="p-3">
        <Row className="d-flex justify-content-end">
          <Col className="d-flex justify-content-end pointer" onClick={() => handleClose(false)}>
            <CloseIcon />
          </Col>
        </Row>
        <Col
          md={2}
          className="my-1 py-1 flex-column-center pointer"
          style={{ width: "100%", background: "#222" }}
          onClick={() => {
            handleOpen("HK");
          }}
        >
          <div className="flex-center">
            <h6 className="m-0" style={{ color: "#EEE" }}>
              {"香港區"}
            </h6>
            <div
              className="mx-2"
              style={{ transform: areaOpen === "HK" ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.3s" }}
            >
              <DownCaret color="#EEE" />
            </div>
          </div>
        </Col>
        <div className="flex-column-center">
          {areaOpen === "HK" &&
            (!areas.hkArea.length ? (
              <div className="flex-center" style={{ height: "32px", color: "#AAA" }}>
                沒有資料
              </div>
            ) : (
              areas.hkArea.map((item) => {
                return (
                  <div
                    key={item}
                    className="my-1 py-1 districtSelectionItem full-width flex-center"
                    onClick={() => handleSelectDistrict(item)}
                  >
                    {DISTRICTS[item]}
                  </div>
                );
              })
            ))}
        </div>
        <Col
          md={2}
          className="my-1 py-1 flex-column-center pointer"
          style={{ width: "100%", background: "#222" }}
          onClick={() => {
            handleOpen("KLN");
          }}
        >
          <div className="flex-center">
            <h6 className="m-0" style={{ color: "#EEE" }}>
              {"九龍區"}
            </h6>
            <div
              className="mx-2"
              style={{ transform: areaOpen === "KLN" ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.3s" }}
            >
              <DownCaret color="#EEE" />
            </div>
          </div>
        </Col>
        <div className="flex-column-center">
          {areaOpen === "KLN" &&
            (!areas.klnArea.length ? (
              <div className="flex-center" style={{ height: "32px", color: "#AAA" }}>
                沒有資料
              </div>
            ) : (
              areas.klnArea.map((item) => {
                return (
                  <div
                    key={item}
                    className="my-1 py-1 districtSelectionItem full-width flex-center"
                    onClick={() => handleSelectDistrict(item)}
                  >
                    {DISTRICTS[item]}
                  </div>
                );
              })
            ))}
        </div>
        <Col
          md={2}
          className="my-1 py-1 flex-column-center pointer"
          style={{ width: "100%", background: "#222" }}
          onClick={() => {
            handleOpen("NT");
          }}
        >
          <div className="flex-center">
            <h6 className="m-0" style={{ color: "#EEE" }}>
              {"新界區"}
            </h6>
            <div
              className="mx-2"
              style={{ transform: areaOpen === "NT" ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.3s" }}
            >
              <DownCaret color="#EEE" />
            </div>
          </div>
        </Col>
        <div className="flex-column-center">
          {areaOpen === "NT" &&
            (!areas.ntArea.length ? (
              <div className="flex-center" style={{ height: "32px", color: "#AAA" }}>
                沒有資料
              </div>
            ) : (
              areas.ntArea.map((item) => {
                return (
                  <div
                    key={item}
                    className="my-1 py-1 districtSelectionItem full-width flex-center"
                    onClick={() => handleSelectDistrict(item)}
                  >
                    {DISTRICTS[item]}
                  </div>
                );
              })
            ))}
        </div>
        <Col
          md={2}
          className="my-1 py-1 flex-column-center pointer"
          style={{ width: "100%", background: "#222" }}
          onClick={() => {
            handleOpen("OTHER");
          }}
        >
          <div className="flex-center">
            <h6 className="m-0" style={{ color: "#EEE" }}>
              {"其他區域"}
            </h6>
            <div
              className="mx-2"
              style={{ transform: areaOpen === "OTHER" ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.3s" }}
            >
              <DownCaret color="#EEE" />
            </div>
          </div>
        </Col>
        <div className="flex-column-center">
          {areaOpen === "OTHER" &&
            (!areas.defaultArea.length ? (
              <div className="flex-center pointer-events-none" style={{ height: "32px", color: "#AAA" }}>
                沒有資料
              </div>
            ) : (
              areas.defaultArea.map((item) => {
                return (
                  <div
                    key={item}
                    className="my-1 py-1 districtSelectionItem full-width flex-center"
                    onClick={() => handleSelectDistrict(item)}
                  >
                    {DISTRICTS[item]}
                  </div>
                );
              })
            ))}
        </div>
      </Container>
    </Modal>
  );
}
