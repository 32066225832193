import axios from "axios";
import "css/GuestSatisfactory.css";
import { formatDateAndTime } from "helpers/date";
import { IOrderSatisfactory } from "models/responseModels";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { StarIcon } from "./IconsOnly";
import FormHeader from "./parts/FormHeader";

const MAX_CANVAS_WIDTH = `${window.innerWidth / 1.8}px`;
interface IImagesRecord {
  header: string;
}

export default function GuestSatisfactory({ header }: IImagesRecord) {
  const defaultOrderSatisfactory = {
    communication: 0,
    createdAt: "",
    id: 0,
    safety: 0,
    serviceQuality: 0,
    technical: 0,
    updatedAt: "",
  };
  const [orderSatisfactory, setOrderSatisfactory] = useState<IOrderSatisfactory>(defaultOrderSatisfactory);
  const [signatureData, setSignatureData] = useState("");
  const pathname = window.location.pathname.split("/");
  const orderId = pathname[pathname.length - 1];
  const dispatch = useDispatch();

  const fetchGuestSatisfactory = async () => {
    try {
      const res = await axios.get(`order-survey/${orderId}`);
      const result = await res.data;
      setOrderSatisfactory(result.data);
      const res2 = await axios.get(`order-signature/${orderId}`);
      const result2 = await res2.data;
      setSignatureData(result2.signature);
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  useEffect(() => {
    fetchGuestSatisfactory();
    return () => {
      setOrderSatisfactory(defaultOrderSatisfactory);
    };
  }, []);

  return (
    <div className="p-3">
      <Row>
        <FormHeader offset={false} header={header} />
      </Row>
      {orderSatisfactory ? (
        <div className="flex-column-start full-width" style={{ position: "relative", height: "100vh" }}>
          <StarCard title={"服務質量"} score={orderSatisfactory.serviceQuality} />
          <StarCard title={"　　技術"} score={orderSatisfactory.technical} />
          <StarCard title={"　　溝通"} score={orderSatisfactory.communication} />
          <StarCard title={"　　安全"} score={orderSatisfactory.safety} />
          <div className="pb-4 flex-column-start full-width">
            <h5 style={{ fontSize: "1.2em", textAlign: "center" }}>客戶簽署</h5>
            <div className="my-3 flex-center full-width signatureContainer" style={{ maxWidth: MAX_CANVAS_WIDTH }}>
              <div className="absolute" style={{ background: "white", right: 0, top: 0, padding: "0 16px" }}>
                {formatDateAndTime(orderSatisfactory.createdAt)}
              </div>
              <img
                src={signatureData}
                style={{ maxHeight: "100%", height: "auto", maxWidth: MAX_CANVAS_WIDTH }}
                alt="Signature"
              />
            </div>
          </div>
        </div>
      ) : (
        <Row className="my-4 d-flex justify-content-center" style={{ minHeight: "64px" }}>
          <Col className="d-flex justify-content-center">
            <h5 className="disableText">尚未評分</h5>
          </Col>
        </Row>
      )}
    </div>
  );
}

interface StarCardProps {
  title: string;
  score: number;
}

export const StarCard = (props: StarCardProps) => {
  const { title, score } = props;
  const satisfactoryText = ["極不滿意", "　", "一般", "　", "非常滿意"];

  return (
    <section className="my-4 full-width flex-center" style={{ minHeight: "64px" }}>
      <Col className="full-width flex-center">
        <div
          style={{
            width: "max-content",
            fontSize: "1.2em",
            marginLeft: "0.5em",
            marginRight: "1.5em",
            whiteSpace: "nowrap",
          }}
          className="flex-row-end"
        >
          {title}
        </div>
        <div className="flex-row-start" style={{ position: "relative" }}>
          <div className="px-1 flex-row-around starContainer">
            {Array(5)
              .fill(0)
              .map((item, idx) => {
                return (
                  <div
                    key={idx + 10}
                    className="p-1 flex-column-center mx-1"
                    style={{ minWidth: "64px", maxWidth: "64px" }}
                  >
                    <StarIcon position={`${title}_${idx}`} offset={Math.max(0, Math.min(1, score - idx))} />
                    <div className="my-2" style={{ color: "#888", whiteSpace: "nowrap" }}>
                      {satisfactoryText[idx]}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Col>
    </section>
  );
};
