import axios from "axios";
import { DISTRICTS, DISTRICTS_MAPPING, DistrictType } from "models/districtModels";
import { IWorkers } from "models/responseModels";
import { DIVISIONS, DIVISIONS_MAPPING, DivisionType, ROLES, ROLES_MAPPING, RoleType } from "models/variables";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading, setMessageModal } from "redux/Loading/action";
import { setWorkerChangesMade } from "redux/Worker/action";
import { CloseIcon } from "./IconsOnly";
import FormHeader from "./parts/FormHeader";

interface IWorkReport {
  header: string;
}

const workerInfoDefault: IWorkers = {
  chiName: "",
  engName: "",
  email: "",
  mobile: "",
  staffNumber: "",
  residenceDistrict: "CENTRAL_AND_WESTERN",
  role: "WORKER",
  division: "PROJECT",
  companyId: -1,
  greencardRefNo: "",
  id: -1,
  updatedAt: "",
  company: "",
  area: "HONG_KONG",
};

type ModalType = "" | "COMPANY" | "DIVISION" | "DISTRICT" | "ROLE";

export default function EditWorker({ header }: IWorkReport) {
  const [selectModalOpen, setSelectModalOpen] = useState<{ isOpen: boolean; type: ModalType }>({
    isOpen: false,
    type: "",
  });
  const [allCompanies, setAllCompanies] = useState<Array<{ id: number; name: string }>>([]);
  const [workerInfo, setWorkerInfo] = useState<IWorkers>(workerInfoDefault);

  const handleExit = () => {
    history.goBack();
    setWorkerInfo(workerInfoDefault);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const pathname = window.location.pathname.split("/");
  const workerId = pathname[pathname.length - 1];

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.put(`/users/${workerId}`, {
        user: {
          chiName: workerInfo.chiName,
          engName: workerInfo.engName,
          email: workerInfo.email,
          mobile: workerInfo.mobile,
          staffNumber: workerInfo.staffNumber,
          residenceDistrict: workerInfo.residenceDistrict,
          role: workerInfo.role,
          companyId: workerInfo.companyId,
          division: !workerInfo.division ? null : workerInfo.division,
        },
      });
      dispatch(setWorkerChangesMade(true));
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      handleExit();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  useEffect(() => {
    const fetchWorkerById = async () => {
      dispatch(setIsLoading(true));
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/users`);
        url.searchParams.set("id", workerId);
        const res = await axios.get(url.toString());
        const result = res.data;
        setWorkerInfo(result);
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    fetchWorkerById();
  }, [dispatch, workerId]);

  const handleSelectDistrict = (item: DistrictType) => {
    setWorkerInfo({ ...workerInfo, residenceDistrict: item });
    setSelectModalOpen({ isOpen: false, type: "" });
  };
  const handleSelectDivisions = (item: DivisionType) => {
    setWorkerInfo({ ...workerInfo, division: item });
    setSelectModalOpen({ isOpen: false, type: "" });
  };
  const handleSelectRoles = (item: RoleType) => {
    setWorkerInfo({ ...workerInfo, role: item });
    setSelectModalOpen({ isOpen: false, type: "" });
  };

  useEffect(() => {
    const fetchAllCompanies = async () => {
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/companies`);
        url.searchParams.set("type", "form");
        const res = await axios.get(url.toString());
        const result = res.data.data;
        setAllCompanies(result);
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    };
    fetchAllCompanies();
  }, []);
  return (
    <Container className="p-3">
      <FormHeader offset={false} header={header} />
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div>員工編號:</div>
          </Col>
          <Col>
            <input
              type="text"
              name="staffNumber"
              value={workerInfo.staffNumber}
              className="inputStyle"
              onChange={(e) => setWorkerInfo({ ...workerInfo, staffNumber: e.target.value })}
            />
          </Col>
        </Row>

        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div>中文姓名:</div>
          </Col>
          <Col>
            <input
              type="text"
              name="chiName"
              value={workerInfo.chiName}
              className="inputStyle"
              onChange={(e) => setWorkerInfo({ ...workerInfo, chiName: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 英文姓名:</div>
          </Col>
          <Col>
            <input
              type="text"
              name="engName"
              value={workerInfo.engName}
              className="inputStyle"
              onChange={(e) => setWorkerInfo({ ...workerInfo, engName: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 所屬公司:</div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="pointer">{!workerInfo.companyId ? "選擇公司" : workerInfo.company}</div>
            <div className="chooseButton" onClick={() => setSelectModalOpen({ isOpen: true, type: "COMPANY" })}>
              選擇
            </div>
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 所屬部門:</div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="pointer">{!workerInfo.division ? "選擇部門" : DIVISIONS[workerInfo.division]}</div>
            <div className="chooseButton" onClick={() => setSelectModalOpen({ isOpen: true, type: "DIVISION" })}>
              選擇
            </div>
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 電郵地址:</div>
          </Col>
          <Col>
            <input
              type="text"
              name="email"
              value={workerInfo.email}
              className="inputStyle"
              onChange={(e) => setWorkerInfo({ ...workerInfo, email: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div> 手機號碼:</div>
          </Col>
          <Col>
            <input
              type="text"
              name="mobile"
              value={workerInfo.mobile}
              className="inputStyle"
              onChange={(e) => setWorkerInfo({ ...workerInfo, mobile: e.target.value })}
            />
          </Col>
        </Row>

        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div>地區:</div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="pointer">{DISTRICTS[workerInfo.residenceDistrict] ?? "選擇地區"}</div>
            <div className="chooseButton" onClick={() => setSelectModalOpen({ isOpen: true, type: "DISTRICT" })}>
              選擇
            </div>
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2}>
            <div>員工職位: </div>
          </Col>
          <Col className="d-flex align-items-center">
            <div className="pointer">{!workerInfo.role ? "選擇職位" : workerInfo.role}</div>
            <div className="chooseButton" onClick={() => setSelectModalOpen({ isOpen: true, type: "ROLE" })}>
              選擇
            </div>
          </Col>
        </Row>

        <Row className="d-flex flex-center">
          <Button style={{ width: "50%", margin: "8px" }} type="submit">
            {"修改"}
          </Button>
        </Row>
      </form>

      <Modal isOpen={selectModalOpen.isOpen}>
        <Container className="p-3">
          <div className="d-flex justify-content-end">
            <div className="pointer" onClick={() => setSelectModalOpen({ isOpen: false, type: "" })}>
              <CloseIcon />
            </div>
          </div>
          {selectModalOpen.type === "ROLE" &&
            Object.values(ROLES).map((item) => {
              return (
                <Col
                  key={item}
                  md={2}
                  className="py-1 my-1 d-flex flex-center districtSelectionItem"
                  style={{ width: "100%" }}
                  onClick={() => {
                    handleSelectRoles(ROLES_MAPPING.get(item));
                  }}
                >
                  {item}
                </Col>
              );
            })}
          {selectModalOpen.type === "COMPANY" &&
            allCompanies.map((item) => {
              return (
                <Col
                  key={item.id}
                  md={2}
                  className="py-1 my-1 d-flex flex-center districtSelectionItem"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setSelectModalOpen({ isOpen: false, type: "" });
                    setWorkerInfo({ ...workerInfo, companyId: item.id, company: item.name });
                  }}
                >
                  {item.name}
                </Col>
              );
            })}
          {selectModalOpen.type === "DISTRICT" &&
            Object.values(DISTRICTS).map((item) => {
              return (
                <Col
                  key={item}
                  md={2}
                  className="py-1 my-1 d-flex flex-center districtSelectionItem"
                  style={{ width: "100%" }}
                  onClick={() => {
                    handleSelectDistrict(DISTRICTS_MAPPING.get(item));
                  }}
                >
                  {item}
                </Col>
              );
            })}
          {selectModalOpen.type === "DIVISION" &&
            Object.values(DIVISIONS).map((item) => {
              return (
                <Col
                  key={item}
                  md={2}
                  className="py-1 my-1 d-flex flex-center districtSelectionItem"
                  style={{ width: "100%" }}
                  onClick={() => {
                    handleSelectDivisions(DIVISIONS_MAPPING.get(item));
                  }}
                >
                  {item}
                </Col>
              );
            })}
        </Container>
      </Modal>
    </Container>
  );
}
