import axios from "axios";
import { DownCaret } from "components/IconsOnly";
import { EditOrderProps } from "components/WorkInformation";
import { DISTRICTS } from "models/districtModels";
import { IClients } from "models/responseModels";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { handleClientChange } from "redux/Order/action";
import { IRootState } from "store";
import SelectClientModal from "./SelectClientModal";

export default function ClientDetails(props: EditOrderProps) {
  const { setChanged } = props;
  const [clientOpen, setClientOpen] = useState(false);
  const [showClientDetails, setShowClientDetails] = useState(true);
  const data = useSelector((state: IRootState) => state.orderById.orderById);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchClientById = async () => {
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/clients`);
        url.searchParams.set("id", String(data.clientId));
        const res = await axios.get<IClients>(url.toString());
        const result = res.data;
        dispatch(
          handleClientChange({
            name: result.name,
            district: result.district,
            address: result.address,
            contactPerson: result.contactPerson,
            contactNumber: result.tel,
            email: result.email,
          })
        );
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    };
    fetchClientById();
  }, [dispatch, data.clientId]);

  const handleSelectClient = (id: number) => {
    dispatch(handleClientChange({ id }));
    setClientOpen(false);
    if (setChanged) {
      setChanged(true);
    }
  };

  return (
    <>
      <Row className={`flex-row-start`}>
        <Col className="flex-row-start" style={{ maxWidth: "40px" }}>
          <div
            className="flex-center pointer"
            style={{ transform: showClientDetails ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.3s" }}
            onClick={() => setShowClientDetails(!showClientDetails)}
          >
            <DownCaret />
          </div>
        </Col>
        <Col md={1} style={{ width: "fit-content" }}>
          <h5 style={{ marginBottom: 0 }}>客戶資料</h5>
        </Col>
        <Col md={1} style={{ width: "fit-content" }}>
          <Button
            color="primary"
            className="m-3 full-width"
            onClick={() => {
              setClientOpen(true);
            }}
            disabled={data.progressStatus === "COMPLETED"}
          >
            更改
          </Button>
        </Col>
        <SelectClientModal isOpen={clientOpen} closeModalAction={setClientOpen} handleSelect={handleSelectClient} />
      </Row>
      <div>
        <Row
          className={`my-1`}
          style={{
            opacity: showClientDetails ? 1 : 0,
            height: showClientDetails ? "48px" : "0px",
            transition: "all 0.3s",
          }}
        >
          <Col className={`p-2 flex-row-start`}>
            <h6 className="m-0 mx-2">名稱:</h6>
            <div>{data.clientName || "-"}</div>
          </Col>
          <Col className={`p-2 flex-row-start`}>
            <h6 className="m-0 mx-2"> 區域: </h6>
            <div>{data.district ? DISTRICTS[data.district] : " - "}</div>
          </Col>
          <Col className={`p-2 flex-row-start`}>
            <h6 className="m-0 mx-2" style={{ whiteSpace: "nowrap" }}>
              地址:
            </h6>
            <div>{data.clientAddress || "-"}</div>
          </Col>
        </Row>
        <Row
          className={showClientDetails ? `my-1 mb-4` : ""}
          style={{
            opacity: showClientDetails ? 1 : 0,
            height: showClientDetails ? "48px" : "0px",
            transition: "all 0.3s",
          }}
        >
          <Col className={`p-2 flex-row-start`}>
            <h6 className="m-0 mx-2"> 聯絡人: </h6>
            <div>{data.clientContactPerson || "-"}</div>
          </Col>
          <Col className={`p-2 flex-row-start`}>
            <h6 className="m-0 mx-2"> 聯絡電話: </h6>
            <div>{data.clientTel || "-"}</div>
          </Col>
          <Col className={`p-2 flex-row-start`}>
            <h6 className="m-0 mx-2" style={{ whiteSpace: "nowrap" }}>
              {" "}
              電郵地址:{" "}
            </h6>
            <div>{data.clientEmail || "-"}</div>
          </Col>
        </Row>
      </div>
    </>
  );
}
