import axios from "axios";
import FormHeader from "components/parts/FormHeader";
import { ICompanies } from "models/responseModels";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setCompanyChangesMade } from "redux/Companies/action";
import { setIsLoading, setMessageModal } from "redux/Loading/action";

interface NewCompanyProps {
  isNewCompany?: boolean;
}

export interface INewCompany {
  name: string;
  email: string;
  tel: string;
  contactPerson: string;
}

export default function NewCompany({ isNewCompany = true }: NewCompanyProps) {
  const defaultCompanyInfo = {
    name: "",
    email: "",
    tel: "",
    contactPerson: "",
  };
  const [companyInfo, setCompanyInfo] = useState<Partial<ICompanies>>(defaultCompanyInfo);

  const handleExit = () => {
    history.goBack();
    setCompanyInfo(defaultCompanyInfo);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isNewCompany) {
      try {
        await axios.post(`/companies`, {
          newCompany: companyInfo,
        });
        dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
        dispatch(setCompanyChangesMade(true));
        handleExit();
      } catch (error) {
        dispatch(handleAxiosError(error));
        dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
      }
    } else {
      try {
        const { id, updatedAt, usersCount, ...company } = companyInfo as ICompanies;
        await axios.put(`/companies/${id}`, {
          company,
        });
        dispatch(setCompanyChangesMade(true));
        dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
        handleExit();
      } catch (error) {
        dispatch(handleAxiosError(error));
        dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
      }
    }
  };

  const redMark = (identifier: boolean) => {
    return {
      color: "red",
      width: !identifier ? "16px" : "0px",
      transition: "all 0.3s",
      opacity: !identifier ? 1 : 0,
    };
  };

  useEffect(() => {
    if (!isNewCompany) {
      const fetchAllCompaniesButActuallyINeedFetchById = async () => {
        const pathname = window.location.pathname.split("/");
        const companyId = pathname[pathname.length - 1];
        dispatch(setIsLoading(true));
        try {
          const url = new URL(`${process.env.REACT_APP_API_PATH}/companies`);
          const res = await axios.get<{ data: ICompanies[] }>(url.toString());
          const result = res.data;
          const thisCompany = result.data.filter((i) => i.id === Number(companyId));
          setCompanyInfo(thisCompany[0]);
        } catch (error) {
          dispatch(handleAxiosError(error));
        } finally {
          dispatch(setIsLoading(false));
        }
      };
      fetchAllCompaniesButActuallyINeedFetchById();
    } else {
      setCompanyInfo(defaultCompanyInfo);
    }
  }, [dispatch]);

  const disabled = companyInfo && Object.values(companyInfo).includes("");

  return (
    <Container className="p-3">
      <FormHeader offset={false} header={isNewCompany ? "新增合作公司" : "更改合作公司資料"} />
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2} className="d-flex">
            <div style={redMark(!!companyInfo?.name)}>{"*"}</div>
            <div>公司名稱:</div>
          </Col>
          <Col>
            <input
              type="text"
              name="name"
              value={companyInfo?.name}
              className="inputStyle"
              onChange={(e) => setCompanyInfo({ ...companyInfo, name: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2} className="d-flex">
            <div style={redMark(!!companyInfo?.contactPerson)}>{"*"}</div>
            <div>聯絡人:</div>
          </Col>
          <Col>
            <input
              type="text"
              name="contactPerson"
              value={companyInfo?.contactPerson}
              className="inputStyle"
              onChange={(e) => setCompanyInfo({ ...companyInfo, contactPerson: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2} className="d-flex">
            <div style={redMark(!!companyInfo?.tel)}>{"*"}</div>
            <div> 電話號碼:</div>
          </Col>
          <Col>
            <input
              type="text"
              name="tel"
              value={companyInfo?.tel}
              className="inputStyle"
              onChange={(e) => setCompanyInfo({ ...companyInfo, tel: e.target.value })}
            />
          </Col>
        </Row>
        <Row className={`d-flex-row m-3 align-items-center`} style={{ height: "40px" }}>
          <Col md={2} className="d-flex">
            <div style={redMark(!!companyInfo?.email)}>{"*"}</div>
            <div> 電郵地址:</div>
          </Col>
          <Col>
            <input
              type="email"
              name="email"
              value={companyInfo?.email}
              className="inputStyle"
              onChange={(e) => setCompanyInfo({ ...companyInfo, email: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="d-flex flex-center">
          <Button style={{ width: "50%", margin: "8px" }} type="submit" color="primary" disabled={disabled}>
            {isNewCompany ? "新增" : "儲存更改"}
          </Button>
        </Row>
      </form>
    </Container>
  );
}
