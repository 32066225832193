import EditWorker from "components/EditWorker";
import Layout from "./Layout";

export default function NewWorkerPage() {
  return (
    <Layout>
      <EditWorker header="修改員工資料" />
    </Layout>
  );
}
