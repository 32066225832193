import axios from "axios";
import ViewOrder from "components/ViewOrder";
import { push } from "connected-react-router";
import { OrderById } from "models/responseModels";
import Layout from "pages/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAxiosError } from "redux/Auth/action";
import {
  clearOrderByIdData,
  setAssignedWorkers,
  setFetchedImage,
  setOrderByIdData,
  setOrderSatisfactoryData,
  setSignatureData,
  setWorkChangesMade,
} from "redux/Order/action";
import { IRootState } from "store";

export default function ViewOrderPage() {
  const changesMade = useSelector((state: IRootState) => state.orderById.changesMade);
  const pathname = window.location.pathname.substr(1).split("/");
  const orderId = pathname[pathname.length - 1];
  const dispatch = useDispatch();

  const fetchOrderById = async () => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders/${orderId}`);
      const res = await axios.get<OrderById>(url.toString());
      if (!res.data) {
        dispatch(push("/error"));
      }
      const result = res.data;
      dispatch(setOrderByIdData(result));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };
  const fetchAllMediaByOrderId = async () => {
    try {
      const res = await axios.get(`/order-media/${orderId}`);
      const result = res.data;
      dispatch(setFetchedImage(result.data));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };
  const fetchGuestSatisfactory = async () => {
    try {
      const res = await axios.get(`order-survey/${orderId}`);
      const result = await res.data;
      dispatch(setOrderSatisfactoryData(result.data));
      const res2 = await axios.get(`order-signature/${orderId}`);
      const result2 = await res2.data;
      dispatch(setSignatureData(result2.signature));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };
  const fetchAssignedWorkers = async () => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/order-staffs/${orderId}`);
      url.searchParams.set("page", "1");
      const res = await axios.get(url.toString());
      const result = res.data;
      dispatch(setAssignedWorkers(result.data));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  useEffect(() => {
    fetchOrderById();
    fetchAllMediaByOrderId();
    fetchGuestSatisfactory();
    fetchAssignedWorkers();
    return () => {
      dispatch(clearOrderByIdData());
      dispatch(setAssignedWorkers([]));
    };
  }, [dispatch, orderId]);

  useEffect(() => {
    if (changesMade) {
      fetchOrderById();
      dispatch(setWorkChangesMade(false));
    }
  }, [changesMade]);

  return (
    <Layout>
      <ViewOrder />
    </Layout>
  );
}
