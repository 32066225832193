import { DistrictType } from '../../models/districtModels';
import { FetchAssignedWorkers, IOrderSatisfactory, IPagination, OrderById } from '../../models/responseModels';
import { NewOrderData } from './state';

export const setOrderByIdData = (data: OrderById) => {
  return {
    type: '@@OrderById/setOrderByIdData' as const,
    data,
  };
};

export const clearOrderByIdData = () => {
  return {
    type: '@@OrderById/clearData' as const,
  };
};

export const handleClientChange = (data: {
  id?: number;
  name?: string;
  district?: DistrictType;
  address?: string;
  contactPerson?: string;
  contactNumber?: string;
  email?: string;
}) => {
  return {
    type: '@@OrderById/handleClientChange' as const,
    data,
  };
};
export const setNewOrderData = (data: NewOrderData) => {
  return {
    type: '@@NewServiceOrder/setNewOrderData' as const,
    data,
  };
};

export const clearNewOrderData = () => {
  return {
    type: '@@NewServiceOrder/clearNewOrderData' as const,
  };
};

export const assignWorkerToList = (id: number) => {
  return {
    type: '@@OrderById/assignWorkerToList' as const,
    id,
  };
};

export const removeWorkerFromList = (id: number) => {
  return {
    type: '@@OrderById/removeWorkerFromList' as const,
    id,
  };
};

export const resetWorkerList = () => {
  return {
    type: '@@OrderById/resetWorkerList' as const,
  };
};

export const setImageModalOpen = (isOpen: boolean) => {
  return {
    type: '@@OrderById/setImageModalOpen' as const,
    isOpen,
  };
};
export const setImageModalDescription = (description: string) => {
  return {
    type: '@@OrderById/setImageModalDescription' as const,
    description,
  };
};

export const setServiceReport = (data: { serviceSubject?: string; serviceReport?: string }) => {
  return {
    type: '@@OrderById/setServiceReport' as const,
    data,
  };
};

export const setFetchedImage = (data: []) => {
  return {
    type: '@@OrderById/setFetchedImage' as const,
    data,
  };
};
export const setSignatureData = (data: string) => {
  return {
    type: '@@OrderById/setSignatureData' as const,
    data,
  };
};
export const setOrderSatisfactoryData = (data: IOrderSatisfactory) => {
  return {
    type: '@@OrderById/setOrderSatisfactoryData' as const,
    data,
  };
};
export const setAssignedWorkers = (data: FetchAssignedWorkers[]) => {
  return {
    type: '@@OrderById/setAssignedWorkers' as const,
    data,
  };
};
export const setNextNewOrderNumber = (orderNumber: string) => {
  return {
    type: '@@OrderById/setNextNewOrderNumber' as const,
    orderNumber,
  };
};

//pagination
export const setWorkPagination = (data: IPagination) => {
  return {
    type: '@@Pagination/setWorkPagination' as const,
    data,
  };
};

export const setWorkCurrentPage = (currentPage: number) => {
  return {
    type: '@@Pagination/setWorkCurrentPage' as const,
    currentPage,
  };
};
export const resetWorkPagination = () => {
  return {
    type: '@@Pagination/resetWorkPagination' as const,
  };
};
export const setWorkChangesMade = (changesMade: boolean) => {
  return {
    type: '@@Pagination/setWorkChangesMade' as const,
    changesMade,
  };
};
//end of pagination

type ActionCreators =
  | typeof setOrderByIdData
  | typeof clearOrderByIdData
  | typeof setWorkPagination
  | typeof setWorkCurrentPage
  | typeof setWorkChangesMade
  | typeof resetWorkPagination
  | typeof handleClientChange
  | typeof setNewOrderData
  | typeof clearNewOrderData
  | typeof assignWorkerToList
  | typeof removeWorkerFromList
  | typeof resetWorkerList
  | typeof setImageModalOpen
  | typeof setImageModalDescription
  | typeof setServiceReport
  | typeof setFetchedImage
  | typeof setSignatureData
  | typeof setAssignedWorkers
  | typeof setOrderSatisfactoryData
  | typeof setNextNewOrderNumber;

export type OrderByIdActions = ReturnType<ActionCreators>;
