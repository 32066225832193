import axios from "axios";
import { push } from "connected-react-router";
import { isWithin1Week } from "helpers/date";
import { DISTRICTS } from "models/districtModels";
import { IClients } from "models/responseModels";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setNewOrderData } from "redux/Order/action";
import { IRootState } from "store";
import SelectClientModal from "../SelectClientModal";

export default function NewClientDetails() {
  const [modalProps, setModalProps] = useState<WarningModalProps | null>({
    division: "",
    id: 0,
    message: "",
    onClose: () => {},
  });

  const [clientOpen, setClientOpen] = useState(false);
  const data = useSelector((state: IRootState) => state.orderById.newOrderData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data.clientId) return;
    const fetchClientById = async () => {
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/clients`);
        url.searchParams.set("id", String(data.clientId));
        const res = await axios.get<IClients>(url.toString());
        const result = res.data;
        dispatch(
          setNewOrderData({
            ...data,
            name: result.name,
            district: result.district,
            address: result.address,
            contactPerson: result.contactPerson,
            contactNumber: result.tel,
            email: result.email,
          })
        );
        const orderUrl = new URL(`${process.env.REACT_APP_API_PATH}/orders`);
        orderUrl.searchParams.set("clientId", `${data.clientId}`);
        orderUrl.searchParams.set("order", `createdAt`);
        orderUrl.searchParams.set("direction", `desc`);
        orderUrl.searchParams.set("rows", "1");
        const res2 = await axios.get(orderUrl.toString());
        const result2 = res2.data;
        const previousOrder = result2.data[0];
        if (isWithin1Week(previousOrder.createdAt)) {
          setModalProps({
            id: previousOrder.id,
            division: previousOrder.division === "MAINTENANCE" ? "maintenance" : "service",
            message: `此客戶一星期內曾新增工作單 ${previousOrder.orderNumber}`,
            onClose: () => setModalProps(null),
          });
        }
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    };
    fetchClientById();
  }, [data.clientId]);

  const redMark = (identifier: boolean) => {
    return {
      color: "red",
      width: !identifier ? "16px" : "0px",
      transition: "all 0.3s",
      opacity: !identifier ? 1 : 0,
    };
  };

  const handleSelectClient = (id: number) => {
    dispatch(setNewOrderData({ ...data, clientId: id }));
    setClientOpen(false);
  };

  return (
    <>
      <Row className={`d-flex align-items-center`}>
        <div className="d-flex align-items-center" style={{ width: "max-content" }}>
          <div style={redMark(!!data.name)}>{"*"}</div>
          <h5 style={{ marginBottom: 0, width: "max-content" }}>客戶資料</h5>
        </div>
        <Col>
          <Button
            className="m-3"
            onClick={() => {
              setClientOpen(true);
            }}
          >
            新增
          </Button>
        </Col>
        <SelectClientModal isOpen={clientOpen} closeModalAction={setClientOpen} handleSelect={handleSelectClient} />
      </Row>
      <Row
        className={`m-1`}
        style={{
          height: data.clientId !== 0 ? "68px" : "0px",
          opacity: data.clientId !== 0 ? 1 : 0,
          transition: "all 0.3s 0.3s",
        }}
      >
        <Col className={`p-2`}>
          <h6>名稱:</h6>
          <div>{data.name || " - "}</div>
        </Col>
        <Col className={`p-2 align-items-center`}>
          <h6> 區域: </h6>
          <div>{!!data.district ? DISTRICTS[data.district] : " - "}</div>
        </Col>
        <Col className={`p-2`}>
          <h6> 地址: </h6>
          <div>{data.address || " - "}</div>
        </Col>
      </Row>
      <Row
        className={`m-1 mb-4`}
        style={{
          height: data.clientId !== 0 ? "68px" : "0px",
          opacity: data.clientId !== 0 ? 1 : 0,
          transition: "all 0.3s 0.3s",
        }}
      >
        <Col className={`p-2`}>
          <h6> 聯絡人: </h6>
          <div>{data.contactPerson || " - "}</div>
        </Col>
        <Col className={`p-2`}>
          <h6> 聯絡電話: </h6>
          <div>{data.contactNumber || " - "}</div>
        </Col>
        <Col className={`p-2`}>
          <h6> 電郵地址: </h6>
          <div>{data.email || " - "}</div>
        </Col>
      </Row>

      {modalProps && <WarningModal {...modalProps} />}
    </>
  );
}

interface WarningModalProps {
  division: string;
  id: number;
  message: string;
  onClose: () => void;
}

function WarningModal(props: WarningModalProps) {
  const dispatch = useDispatch();
  return (
    <Modal centered isOpen={!!props.message}>
      <Container className="p-3">
        <Row className="flex-column-center">
          <Col className="mb-3" md={8} style={{ textAlign: "center" }}>
            {props.message}
          </Col>
          <Col md={12} className="flex-row-around">
            <Button
              color="primary"
              style={{ width: "40%", cursor: "pointer" }}
              onClick={() => dispatch(push(`/${props.division}/view/${props.id}`))}
            >
              檢視工作單
            </Button>
            <Button style={{ width: "40%", cursor: "pointer" }} onClick={props.onClose}>
              略過
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
