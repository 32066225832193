import "css/Document.css";
import { formatDate } from "helpers/date";
import { IDocumentById, ITemplateById } from "models/responseModels";
import { PostContent } from "pages/Admin/DocumentById";
import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { useSelector } from "react-redux";
import { IRootState } from "store";

type PostContentType = {
  docName: string;
  docTypeId: number;
  fields: PostContent[];
};

interface JobCardProps {
  isSelectionMode: boolean;
  allFields?: IDocumentById[] | ITemplateById[];
  postContent?: PostContentType;
  setPostContent?: React.Dispatch<
    React.SetStateAction<{
      docName: string;
      docTypeId: number;
      fields: PostContent[];
    }>
  >;
}

type ServiceCategory = "MAINTENANCE" | "E-CALL" | "OTHERS" | "";

function JobCardMaintenanceAgreement(props: JobCardProps) {
  const { isSelectionMode, allFields, postContent } = props;
  const [selectServiceType, setSelectServiceType] = useState<ServiceCategory>("");

  const handleEditContent = (idx: number, input: string) => {
    if (postContent) {
      const newArr = postContent.fields.slice();
      newArr[idx] = {
        ...postContent.fields[idx],
        content: input,
      };
      props.setPostContent &&
        props.setPostContent({ docName: postContent.docName, docTypeId: postContent.docTypeId, fields: newArr });
    }
  };

  const handleSelectServiceType = (service: ServiceCategory, idx: number) => {
    if (postContent) {
      const newArr = postContent.fields.slice();
      newArr[idx] = {
        ...postContent.fields[idx],
        content: service,
      };
      props.setPostContent &&
        props.setPostContent({ docName: postContent.docName, docTypeId: postContent.docTypeId, fields: newArr });
    }
    setSelectServiceType(service);
  };
  const data = useSelector((state: IRootState) => state.orderById.orderById);

  useEffect(() => {
    if (data.serviceType === "M1" || data.serviceType === "M2") {
      setSelectServiceType("MAINTENANCE");
      return;
    }
    if (data.serviceType === "M2(E-CALL)") {
      setSelectServiceType("E-CALL");
      return;
    }
  }, [data.serviceType]);

  useEffect(() => {
    const getDefaultInput = () => {
      if (postContent && props.setPostContent) {
        const newArr = postContent.fields.slice();
        newArr.map((item) => {
          if (item.fieldName === "CUSTOMER\n客戶") {
            item.content = data.clientName ?? "";
          }
          if (item.fieldName === "JOB NO\n工作單編號") {
            item.content = data.acjNo ?? "";
          }
          if (item.fieldName === "ADDRESS\n地址") {
            item.content = data.clientAddress ?? "";
          }
          if (item.fieldName === "DATE\n日期") {
            item.content = formatDate(new Date().toString());
          }
          if (item.fieldName === "TELEPHONE NO\n電話") {
            item.content = data.clientTel ?? "";
          }
          if (item.fieldName === "ISSUE BY\n發單者") {
            item.content = data.issuedByUserName ?? "";
          }
          if (item.fieldName === "DIVISION\n員工單位") {
            if (data.division === "MAINTENANCE") {
              item.content = "維修部";
            } else item.content = "工程部";
          }
          if (item.fieldName === "A. INFORMED BY\n消息來源") {
            item.content = data.informedBy ?? "";
          }
          if (item.fieldName === "C. SERVICE SUBJECT\n服務事項") {
            item.content = data.serviceSubject ?? "";
          }
          if (item.fieldName === "D. SERVICE REPORT\n服務報告") {
            item.content = data.serviceReport ?? "";
          }
          if (item.fieldName === "E. COMPLETION DATE\n完工日期") {
            if (data.completeDate) {
              item.content = formatDate(data.completeDate);
            }
          }
          if (item.fieldName === "B. TYPE OF SERVICE\n服務性質") {
            if (data.serviceType === "M1" || data.serviceType === "M2") {
              setSelectServiceType("MAINTENANCE");
              item.content = "MAINTENANCE";
            } else if (data.serviceType === "M2(E-CALL)") {
              setSelectServiceType("E-CALL");
              item.content = "E-CALL";
            }
          }
        });
        props.setPostContent({ ...postContent, fields: newArr });
      }
    };
    getDefaultInput();
  }, [postContent?.fields.length]);

  return (
    <>
      {!isSelectionMode ? (
        <div className="full-width">
          <tr
            className="my-1 "
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 1fr",
              alignItems: "flex-end",
              pageBreakInside: "avoid",
            }}
          >
            {allFields?.map((item, idx) => {
              if (idx < 9) {
                return (
                  <div key={idx} className="flex-row-start" style={{ width: "95%" }}>
                    <div
                      style={{
                        minWidth: "max-content",
                        color: "#888",
                        fontSize: "0.85em",
                      }}
                    >
                      {item.fieldName + " :"}
                    </div>
                    <input
                      className="inputStyle full-width"
                      readOnly
                      value={""}
                      style={{ pointerEvents: "none", marginLeft: "8px" }}
                    />
                  </div>
                );
              }
            })}
          </tr>
          {allFields?.map((item, idx) => {
            if (idx >= 9) {
              if (item.fieldName === `B. TYPE OF SERVICE\n服務性質`) {
                return (
                  <tr
                    key={idx}
                    className="my-1 d-flex full-width"
                    style={{
                      alignItems: "flex-end",
                      pageBreakInside: "avoid",
                    }}
                  >
                    <div className="my-2 flex-row-start full-width" style={{ alignItems: "flex-start" }}>
                      <div
                        style={{
                          minWidth: "max-content",
                          color: "#888",
                          fontSize: "0.85em",
                        }}
                      >
                        {item.fieldName + " :"}
                      </div>
                      <form className="full-width" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        <label
                          className="flex-center"
                          style={{ justifyContent: "flex-start" }}
                          onClick={() => handleSelectServiceType("MAINTENANCE", idx)}
                        >
                          <input
                            type="radio"
                            checked={selectServiceType === "MAINTENANCE"}
                            style={{ pointerEvents: "none", marginLeft: "8px" }}
                          />
                          <div className="mx-2 align-items-center" style={{ fontSize: "0.9em" }}>
                            {"Maintenance"}
                          </div>
                        </label>
                        <label className="flex-center" style={{ justifyContent: "flex-start" }}>
                          <input
                            type="radio"
                            checked={selectServiceType === "E-CALL"}
                            style={{ pointerEvents: "none", marginLeft: "8px" }}
                            onClick={() => handleSelectServiceType("E-CALL", idx)}
                          />
                          <div className="mx-2" style={{ fontSize: "0.9em" }}>
                            {"Emergency Call"}
                          </div>
                        </label>
                        <div className="flex-center" style={{ justifyContent: "flex-start" }}>
                          <div style={{ color: "#888" }}>{"Others: "}</div>
                          <input className="inputStyle" contentEditable={selectServiceType === "OTHERS"} value={""} />
                        </div>
                      </form>
                    </div>
                  </tr>
                );
              } else
                return (
                  <tr
                    key={idx}
                    className="my-1 d-flex full-width"
                    style={{
                      alignItems: "flex-end",
                      pageBreakInside: "avoid",
                    }}
                  >
                    <div className="flex-row-start full-width">
                      <div
                        style={{
                          minWidth: "max-content",
                          color: "#888",
                          fontSize: "0.85em",
                        }}
                      >
                        {item.fieldName + " :"}
                      </div>
                      <input
                        className="inputStyle"
                        readOnly
                        value={""}
                        style={{ pointerEvents: "none", marginLeft: "8px" }}
                      />
                    </div>
                  </tr>
                );
            }
          })}
        </div>
      ) : (
        <div className="full-width">
          <tr
            className="my-1 "
            style={{
              display: "grid",
              gridTemplateColumns: "1.7fr 1fr",
              alignItems: "flex-end",
              pageBreakInside: "avoid",
            }}
          >
            {postContent?.fields?.map((item, idx) => {
              if (idx < 9) {
                return (
                  <div key={item.docFieldId} className="flex-row-start" style={{ width: "95%" }}>
                    <div
                      style={{
                        minWidth: "max-content",
                        color: "#888",
                        fontSize: "0.85em",
                      }}
                    >
                      {item.fieldName + " :"}
                    </div>
                    <TextareaAutosize
                      id={item.content ? "textAreaMargin" : "empty"}
                      className="inputStyle p-0 px-1 mx-1"
                      value={item.content}
                      onChange={(e) => handleEditContent(idx, e.currentTarget.value)}
                    />
                  </div>
                );
              }
            })}
          </tr>
          {postContent?.fields?.map((item, idx) => {
            if (idx >= 9) {
              if (item.fieldName === `B. TYPE OF SERVICE\n服務性質`) {
                return (
                  <tr
                    key={item.docFieldId}
                    className="my-1 d-flex full-width"
                    style={{
                      alignItems: "flex-end",
                      pageBreakInside: "avoid",
                    }}
                  >
                    <div className="my-2 flex-row-start full-width" style={{ alignItems: "flex-start" }}>
                      <div
                        style={{
                          minWidth: "max-content",
                          color: "#888",
                          fontSize: "0.85em",
                        }}
                      >
                        {item.fieldName + " :"}
                      </div>
                      <form className="full-width" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        <label
                          className="flex-center"
                          style={{ justifyContent: "flex-start" }}
                          onClick={() => {
                            setSelectServiceType("MAINTENANCE");
                            handleEditContent(idx, "MAINTENANCE");
                          }}
                        >
                          <input
                            type="radio"
                            checked={selectServiceType === "MAINTENANCE" || item.content === "MAINTENANCE"}
                            style={{ pointerEvents: "none", marginLeft: "8px" }}
                          />
                          <div className="mx-2 align-items-center" style={{ fontSize: "0.9em" }}>
                            {"Maintenance"}
                          </div>
                        </label>
                        <label className="flex-center" style={{ justifyContent: "flex-start" }}>
                          <input
                            type="radio"
                            checked={selectServiceType === "E-CALL" || item.content === "E-CALL"}
                            style={{ pointerEvents: "none", marginLeft: "8px" }}
                            onClick={() => {
                              setSelectServiceType("E-CALL");
                              handleEditContent(idx, "E-CALL");
                            }}
                          />
                          <div className="mx-2" style={{ fontSize: "0.9em" }}>
                            {"Emergency Call"}
                          </div>
                        </label>
                        <div className="flex-center" style={{ justifyContent: "flex-start" }}>
                          <div style={{ color: "#888" }}>{"Others: "}</div>
                          <input
                            onFocus={() => setSelectServiceType("OTHERS")}
                            className="mx-1 inputStyle"
                            readOnly={selectServiceType !== "OTHERS"}
                            value={item.content === "MAINTENANCE" ? "" : item.content === "E-CALL" ? "" : item.content}
                            onChange={(e) => handleEditContent(idx, e.target.value)}
                          />
                        </div>
                      </form>
                    </div>
                  </tr>
                );
              } else if (idx < postContent.fields.length - 3)
                return (
                  <tr
                    key={item.docFieldId}
                    className="my-1 d-flex full-width"
                    style={{
                      pageBreakInside: "avoid",
                    }}
                  >
                    <div className="flex-row-start full-width" style={{ alignItems: "flex-start" }}>
                      <div
                        style={{
                          paddingTop: "4px",
                          minWidth: "max-content",
                          color: "#888",
                          fontSize: "0.85em",
                        }}
                      >
                        {item.fieldName + " :"}
                      </div>
                      <TextareaAutosize
                        id={item.content ? "textAreaMargin" : "empty"}
                        className="inputStyle p-0 px-1 mx-1"
                        value={item.content}
                        onChange={(e) => handleEditContent(idx, e.currentTarget.value)}
                      />
                    </div>
                  </tr>
                );
            }
          })}
        </div>
      )}
    </>
  );
}

export default JobCardMaintenanceAgreement;

interface SelectedJobCardProps {
  allFields: ITemplateById[];
  handleEdit: (idx: number, value: string) => void;
}

export const SelectedJobCardMaintenanceAgreement = (props: SelectedJobCardProps) => {
  const { allFields, handleEdit } = props;
  const [selectServiceType, setSelectServiceType] = useState<ServiceCategory>("");

  const handleSelectServiceType = (service: ServiceCategory, idx: number) => {
    handleEdit(idx, service);
    setSelectServiceType(service);
  };

  return (
    <>
      <div className="full-width">
        <tr
          className="my-1 "
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            alignItems: "flex-end",
            pageBreakInside: "avoid",
          }}
        >
          {allFields?.map((item, idx) => {
            if (idx < 9) {
              return (
                <div key={item.docFieldId} className="flex-row-start" style={{ width: "95%" }}>
                  <div
                    style={{
                      minWidth: "max-content",
                      color: "#888",
                      fontSize: "0.85em",
                    }}
                  >
                    {item.fieldName + " :"}
                  </div>
                  <TextareaAutosize
                    id={item.content ? "textAreaMargin" : "empty"}
                    className="inputStyle p-0 px-1 mx-1"
                    value={item.content}
                    onChange={(e) => handleEdit(idx, e.currentTarget.value)}
                  />
                </div>
              );
            }
          })}
        </tr>
        {allFields?.map((item, idx) => {
          if (idx >= 9) {
            if (item.fieldName === `B. TYPE OF SERVICE\n服務性質`) {
              return (
                <tr
                  key={item.docFieldId}
                  className="my-1 d-flex full-width"
                  style={{
                    alignItems: "flex-end",
                    pageBreakInside: "avoid",
                  }}
                >
                  <div className="my-2 flex-row-start full-width" style={{ alignItems: "flex-start" }}>
                    <div
                      style={{
                        minWidth: "max-content",
                        color: "#888",
                        fontSize: "0.85em",
                      }}
                    >
                      {item.fieldName + " :"}
                    </div>
                    <form className="full-width" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                      <label
                        className="flex-center"
                        style={{ justifyContent: "flex-start" }}
                        onClick={() => handleSelectServiceType("MAINTENANCE", idx)}
                      >
                        <input
                          type="radio"
                          checked={selectServiceType === "MAINTENANCE" || item.content === "MAINTENANCE"}
                          style={{ pointerEvents: "none", marginLeft: "8px" }}
                        />
                        <div className="mx-2 align-items-center" style={{ fontSize: "0.9em" }}>
                          {"Maintenance"}
                        </div>
                      </label>
                      <label className="flex-center" style={{ justifyContent: "flex-start" }}>
                        <input
                          type="radio"
                          checked={selectServiceType === "E-CALL" || item.content === "E-CALL"}
                          style={{ pointerEvents: "none", marginLeft: "8px" }}
                          onClick={() => handleSelectServiceType("E-CALL", idx)}
                        />
                        <div className="mx-2" style={{ fontSize: "0.9em" }}>
                          {"Emergency Call"}
                        </div>
                      </label>
                      <div className="flex-center" style={{ justifyContent: "flex-start" }}>
                        <div style={{ color: "#888" }}>{"Others: "}</div>
                        <input
                          onFocus={() => setSelectServiceType("OTHERS")}
                          className="mx-1 inputStyle"
                          readOnly={selectServiceType !== "OTHERS"}
                          value={item.content === "MAINTENANCE" ? "" : item.content === "E-CALL" ? "" : item.content}
                          onChange={(e) => handleEdit(idx, e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </tr>
              );
            } else if (idx < allFields.length - 3)
              return (
                <tr
                  key={item.docFieldId}
                  className="my-1 d-flex full-width"
                  style={{
                    pageBreakInside: "avoid",
                  }}
                >
                  <div className="flex-row-start full-width" style={{ alignItems: "flex-start" }}>
                    <div
                      style={{
                        paddingTop: "4px",
                        minWidth: "max-content",
                        color: "#888",
                        fontSize: "0.85em",
                      }}
                    >
                      {item.fieldName + " :"}
                    </div>
                    <TextareaAutosize
                      id={item.content ? "textAreaMargin" : "empty"}
                      className="inputStyle p-0 px-1 mx-1"
                      value={item.content}
                      onChange={(e) => handleEdit(idx, e.currentTarget.value)}
                    />
                  </div>
                </tr>
              );
          }
        })}
      </div>
    </>
  );
};
