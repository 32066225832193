// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImagesRecord_content__22L5Z {\n  overflow-y: scroll;\n}\n.ImagesRecord_content__22L5Z img {\n  max-height: 240px;\n  max-width: 100%;\n}", "",{"version":3,"sources":["webpack://src/css/ImagesRecord.module.scss"],"names":[],"mappings":"AAAA;EAKI,kBAAA;AAHJ;AADI;EACI,iBAAA;EACA,eAAA;AAGR","sourcesContent":[".content{\n    img{\n        max-height: 240px;\n        max-width: 100%;\n    }\n    overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "ImagesRecord_content__22L5Z"
};
export default ___CSS_LOADER_EXPORT___;
