import React from "react";
import ReactApexChart from "react-apexcharts";

interface IPieChart {
  title?: string;
  labels: string[];
  series: number[];
  height: string;
  width: string;
}

const SimplePieChart: React.FC<IPieChart> = (props) => {
  const options = {
    chart: {
      type: "pie",
      redrawOnParentResize: true,
      height: props.height,
      width: props.width,
    },
    title: {
      text: props.title,
      align: "center",
    },
    dataLabels: {
      enabled: false,
    },
    labels: props.labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "400",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <>
      {/* @ts-ignore */}
      <ReactApexChart type="pie" options={options} series={props.series} width={props.width} height={props.height} />
    </>
  );
};

export default SimplePieChart;
