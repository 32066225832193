// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".global_actions__2GMf8 :hover {\n  cursor: pointer;\n}\n\n.global_navItem__taaTm :hover {\n  cursor: pointer;\n}\n\n.global_navMainItem__389s8 {\n  width: 30%;\n  min-width: 140px;\n}\n.global_navMainItem__389s8 :hover {\n  cursor: pointer;\n}\n\n.global_newDataButton__14zzB {\n  cursor: pointer;\n}\n\n.global_backButton__231uI {\n  cursor: pointer;\n}\n\n.global_alert__1yjJO {\n  color: red;\n}\n\n.global_moveButton__2LsfO {\n  color: #eee;\n  margin: 0 2px;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/css/global.module.scss"],"names":[],"mappings":"AAKE;EACE,eAAA;AAJJ;;AASE;EACE,eAAA;AANJ;;AASA;EACE,UAAA;EACA,gBAAA;AANF;AAOE;EACE,eAAA;AALJ;;AASA;EACE,eAAA;AANF;;AASA;EACE,eAAA;AANF;;AASA;EACE,UAAA;AANF;;AAQA;EACE,WAAA;EACA,aAAA;EACA,eAAA;AALF","sourcesContent":["$jira-blue: #6099f8;\n$jira-white: #ffffff;\n$topNavHeight: 72px;\n\n.actions {\n  :hover {\n    cursor: pointer;\n  }\n}\n\n.navItem {\n  :hover {\n    cursor: pointer;\n  }\n}\n.navMainItem {\n  width: 30%;\n  min-width: 140px;\n  :hover {\n    cursor: pointer;\n  }\n}\n\n.newDataButton {\n  cursor: pointer;\n}\n\n.backButton {\n  cursor: pointer;\n}\n\n.alert {\n  color: red;\n}\n.moveButton {\n  color: #eee;\n  margin: 0 2px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "global_actions__2GMf8",
	"navItem": "global_navItem__taaTm",
	"navMainItem": "global_navMainItem__389s8",
	"newDataButton": "global_newDataButton__14zzB",
	"backButton": "global_backButton__231uI",
	"alert": "global_alert__1yjJO",
	"moveButton": "global_moveButton__2LsfO"
};
export default ___CSS_LOADER_EXPORT___;
