import InfoBar from "components/InfoBar";
import NavigationBar from "components/NavigationBar";

interface ILayout {
  children?: JSX.Element[] | JSX.Element;
}

function Layout({ children }: ILayout) {
  return (
    <div>
      <NavigationBar />
      <div style={{ display: "flex", flexFlow: "row nowrap" }}>
        <InfoBar />
        <div
          style={{
            transition: "all 0.4s",
            width: "100%",
            maxHeight: "98vh",
            overflowX: "hidden",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
