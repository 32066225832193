import WorkInfo from "components/WorkInfo";
import Layout from "pages/Layout";

export default function AllOrdersLobbyPage() {
  return (
    <Layout>
      <WorkInfo header="所有工作單" type={null} />
    </Layout>
  );
}
