import { ProgressStatusType } from './variables';

export const getStatusDescription = (progressStatus: ProgressStatusType) => {
  switch (progressStatus) {
    case 'NEW':
      return '*新加入工作單*';
    case 'ASSIGNED':
      return '*已安排前線員工處理';
    case 'PROCESSING':
      return '*前線員工已在現場處理';
    case 'FOLLOWING_UP':
      return '*仍有部分項目需要前線員工跟進';
    case 'FRONTLINE_COMPLETED':
      return '*前線員工已檢查/維修相關項目';
    case '待報價':
      return '*經檢查後需另作報價維修/更換零件';
    case '已報價':
      return '*報價已呈交予客方';
    case '需上單':
      return '*項目需收費及安排發票呈交予客方';
    case '已上單':
      return '*發票已呈交予客方';
    case 'COMPLETED':
      return '*項目已完成檢查/維修，有關費用已向客方收費';
    case '客方取消':
      return '*客方要求取消工作單';
    case 'CANCELLED':
      return '*輸入錯誤資料需要取消工作單';
  }
};

// 新增 = 新加入工作單
// 已安排 = 已安排前線員工處理
// 現場工作中 = 前線員工已在現場處理
// 前線跟進中 = 仍有部分項目需要前線員工跟進
// 前線完成 = 前線員工已檢查/維修相關項目
// 待報價 = 經檢查後需另作報價維修/更換零件
// 已報價 = 報價已呈交予客方
// 需上單 = 項目需收費及安排發票呈交予客方
// 已上單 = 發票已呈交予客方
// 已完成 = 項目已完成檢查/維修，有關費用已向客方收費
// 客方取消 = 客方要求取消工作單
// 錯單取消 = 輸入錯誤資料需要取消工作單
