import { ViewWorkerModalActions } from './action';
import { ViewWorkerModalState } from './state';

export const initialState: ViewWorkerModalState = {
  isOpen: false,
  data: {
    chiName: '',
    company: '',
    companyId: 0,
    createdAt: '',
    division: null,
    email: '',
    engName: '',
    greencardRefNo: '',
    id: 0,
    mobile: '',
    residenceDistrict: 'CENTRAL_AND_WESTERN',
    role: '',
    staffNumber: '',
    updatedAt: '',
    userIsActive: false,
    area: 'OTHERS',
  },
  history: [],
};

export default function viewWorkerModalReducer(
  state: ViewWorkerModalState = initialState,
  action: ViewWorkerModalActions
) {
  switch (action.type) {
    case '@@ViewWorkerModal/setIsOpen':
      return {
        ...state,
        isOpen: action.isOpen,
      };
    case '@@ViewWorkerModal/setViewWorkerModalData':
      return {
        ...state,
        data: action.item,
      };
    case '@@ViewWorkerModal/setViewWorkerHistory':
      return {
        ...state,
        history: action.item,
      };
    case '@@ViewWorkerModal/clearViewWorkerModalData':
      return {
        ...state,
        data: initialState.data,
      };
    case '@@ViewWorkerModal/clearViewWorkerHistory':
      return {
        ...state,
        history: initialState.history,
      };

    default:
      return state;
  }
}
