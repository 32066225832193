import axios from "axios";
import { CloseIcon } from "components/IconsOnly";
import { formatDate } from "helpers/date";
import { OrderById } from "models/responseModels";
import { DIVISIONS, DivisionType, PROGRESS_STATUS, SERVICE_TYPES } from "models/variables";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Modal, Row, Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setClientModalOpen } from "redux/Client/action";
import { setIsLoading } from "redux/Loading/action";
import { setOrderByIdData } from "redux/Order/action";
import { IRootState } from "store";
import TableHeaders from "./TableHeaders";

export default function ViewClientModal() {
  const history = useHistory();
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const viewClientModal = useSelector((state: IRootState) => state.client);
  const clientHistory = viewClientModal.clientHistory;
  const isOpen = viewClientModal.clientModalIsOpen;
  const data = viewClientModal.clientData;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setClientModalOpen(false));
  };

  const fetchOrderById = async (orderId: number, division: DivisionType) => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders/${orderId}`);
      const res = await axios.get<OrderById>(url.toString());
      const result = res.data;
      const res2 = await axios.get(`order-survey/${orderId}`);
      const result2 = await res2.data;
      dispatch(setOrderByIdData({ ...result, surveyCompleted: !!result2.data }));
      history.push(`/${division === "PROJECT" ? "service" : "maintenance"}/view/${orderId}`);
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const handleSelect = (orderId: number, division: DivisionType) => {
    dispatch(setIsLoading(true));
    fetchOrderById(orderId, division);
  };

  const tableHeaders = ["工作單編號", "部門", "進度", "服務性質", "開始日期", "結束日期", "開單日期"];

  return (
    <Modal size="xl" isOpen={isOpen}>
      <div className="p-3 pb-4 relative">
        <div style={{ position: "absolute", top: "1em", right: "1em", cursor: "pointer" }} onClick={handleClose}>
          <CloseIcon />
        </div>
        <div className="p-2 full-width flex-center">
          <h4 style={{ textAlign: "center" }}>客戶記錄</h4>
        </div>
        <SectionRow keyText={"客戶名稱"} valueText={data.name || "-"} />
        <SectionRow keyText={"聯絡人"} valueText={data.contactPerson || "-"} />
        <SectionRow keyText={"聯絡電話"} valueText={data.tel || "-"} />
        <SectionRow keyText={"電郵地址"} valueText={data.email || "-"} />
        <SectionRow keyText={"客戶地址"} valueText={data.address || "-"} />
        <SectionRow keyText={"Customer Type"} valueText={data.clientType || "-"} />
        <SectionRow keyText={"工作單數目"} valueText={clientHistory.length.toString() || "-"} />

        {!clientHistory.length ? (
          <div className="my-2 flex-center full-width disableText">沒有工作單記錄</div>
        ) : (
          <div className="flex-center full-width" style={{ overflowX: "auto" }}>
            <div className="p-2 viewCoTable">
              <TableHeaders headers={tableHeaders} />
              {isLoading ? (
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              ) : (
                <>
                  <div className="tableBody relative">
                    {clientHistory.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`flex-center tableRow cursor-default pointer`}
                          onClick={() => handleSelect(item.id, item.division)}
                        >
                          <div className="flex-center tdItem">{item.orderNumber}</div>
                          <div className="flex-center tdDistrict">{DIVISIONS[item.division]}</div>
                          <div className="flex-center tdItem">{PROGRESS_STATUS[item.progressStatus]}</div>
                          <div className="flex-center tdItem">{SERVICE_TYPES[item.serviceType]}</div>
                          <div className="flex-center tdItem">
                            {item.startDate ? formatDate(item.startDate) : " - "}
                          </div>
                          <div className="flex-center tdItem">
                            {item.completeDate ? formatDate(item.completeDate) : " - "}
                          </div>
                          <div className="flex-center tdItem">{formatDate(item.createdAt)}</div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

interface OrderRowProps {
  keyText: string;
  valueText: string;
}

export function SectionRow(props: OrderRowProps) {
  const { keyText, valueText } = props;

  return (
    <Row className="my-2 flex-center" style={{ minHeight: "24px", alignItems: "flex-start" }}>
      <Col
        md={2}
        className="flex-center"
        style={{
          overflowWrap: "anywhere",
          alignItems: "flex-start",
          justifyContent: "flex-end",
          textAlign: "left",
        }}
      >
        <div>{keyText + ": "}</div>
      </Col>
      <Col
        md={9}
        className="flex-row-start"
        style={{
          alignItems: "flex-start",
          overflowWrap: "anywhere",
          textAlign: "left",
        }}
      >
        <div>{valueText}</div>
      </Col>
    </Row>
  );
}
