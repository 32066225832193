import globalStyle from "css/global.module.scss";
import React, { ReactElement } from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface IFormHeader {
  offset: boolean;
  header: string;
  includeExtraButton?: boolean;
  children?: ReactElement;
}
export default function FormHeader({ header, includeExtraButton, children }: IFormHeader) {
  const history = useHistory();
  const URL = window.location.pathname;
  const noBackButtonPages = [
    "/service",
    "/maintenance",
    "/service/information/worker",
    "/service/new-work",
    "/maintenance/information/worker",
    "/service/information/client",
    "/maintenance/information/client",
    "/admin/worker",
    "/admin/template",
    "/admin/client",
    "/admin/document",
    "/admin/area",
    "/all-orders",
    "/order-history",
    "/admin/dashboard",
    "/admin/company",
  ];

  return (
    <>
      <div style={{ flexWrap: "nowrap" }} className="mb-2 mx-0 flex-row-between full-width">
        <div className="flex-row-start flex1">
          {!noBackButtonPages.includes(URL) && (
            <FaArrowCircleLeft size={24} onClick={() => history.goBack()} className={globalStyle["backButton"]} />
          )}
        </div>
        <div className="flex-center flex1" style={{ minWidth: "max-content" }}>
          <h3 style={{ whiteSpace: "nowrap", marginBottom: 0 }}>{header}</h3>
        </div>
        {includeExtraButton ? (
          <div className="d-flex justify-content-end flex1">{children}</div>
        ) : (
          <div className="flex1"></div>
        )}
      </div>
    </>
  );
}
