import axios from "axios";
import AssignWorker from "components/AssignWorker";
import GuestSatisfactory from "components/GuestSatisfactory";
import { InformationIcon } from "components/IconsOnly";
import ImagesRecord from "components/ImagesRecord";
import InformationModal from "components/parts/InformationModal";
import ReactstrapTabNavLink from "components/parts/ReactstrapTabNavLink";
import WorkInformation from "components/WorkInformation";
import WorkReport from "components/WorkReport";
import { push } from "connected-react-router";
import { OrderById } from "models/responseModels";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Nav, TabContent } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { clearOrderByIdData, setOrderByIdData, setWorkChangesMade } from "redux/Order/action";
import { IRootState } from "store";
import ServiceDocument from "./Admin/ServiceDocument";
import Layout from "./Layout";

export default function PageFramework() {
  const [changed, setChanged] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  const [activeTab, setActiveTab] = useState("工作單資料");

  function toggle(tab: string) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }
  const pathname = window.location.pathname.split("/");
  const currentCategory = pathname[pathname.length - 3];
  const orderId = pathname[pathname.length - 1];
  const data = useSelector((state: IRootState) => state.orderById);
  const changesMade = data.changesMade;

  const tabs = ["工作單資料", "工作報告", "員工資料", "圖片紀錄", "文件", "客戶滿意度"];
  const dispatch = useDispatch();

  const fetchOrderById = async () => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders/${orderId}`);
      const res = await axios.get<OrderById>(url.toString());
      if (!res.data) {
        dispatch(push("/error"));
      }
      const result = res.data;
      fetchGuestSatisfactory(result);
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const fetchGuestSatisfactory = async (result: OrderById) => {
    try {
      const res = await axios.get(`order-survey/${orderId}`);
      const result1 = await res.data;
      dispatch(
        setOrderByIdData({
          ...result,
          serviceType: result.serviceType ?? "M1",
          progressStatus: result.progressStatus ?? "NEW",
          surveyCompleted: !!result1.data,
        })
      );
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  useEffect(() => {
    fetchOrderById();
    return () => {
      dispatch(clearOrderByIdData());
      setChanged(false);
    };
  }, [dispatch]);

  useEffect(() => {
    if (changesMade) {
      fetchOrderById();
      dispatch(setWorkChangesMade(false));
    }
  }, [changesMade]);

  return (
    <Layout>
      <div className="px-4 py-2">
        {currentCategory === "service" ? (
          <>
            <Nav tabs>
              {tabs.map((element) => {
                const props = { tabContent: element, activeTab, toggle };
                return <ReactstrapTabNavLink key={element} {...props} changed={changed} />;
              })}
            </Nav>

            <TabContent
              activeTab={activeTab}
              style={{
                maxHeight: "89.5vh",
                overflowY: "auto",
                paddingBottom: "10em",
              }}
            >
              {
                {
                  工作單資料: <WorkInformation header="工作單資料" setChanged={setChanged} changed={changed} />,
                  工作報告: <WorkReport header="工程單工作報告" />,
                  員工資料: <AssignWorker header="工程單員工資料" />,
                  圖片紀錄: <ImagesRecord header="工程單圖片紀錄" />,
                  文件: <ServiceDocument />,
                  客戶滿意度: <GuestSatisfactory header="工程單滿意度" />,
                }[activeTab]
              }
            </TabContent>
          </>
        ) : (
          <>
            <div
              className="absolute pointer informationIconContainer"
              style={{ right: 40 }}
              onClick={() => setShowInformation(true)}
            >
              <InformationIcon />
            </div>
            <Nav tabs>
              {tabs.map((element) => {
                const props = { tabContent: element, activeTab, toggle };
                return <ReactstrapTabNavLink key={element} {...props} changed={changed} />;
              })}
            </Nav>

            <TabContent
              activeTab={activeTab}
              style={{
                maxHeight: "89.5vh",
                overflowY: "auto",
                paddingBottom: "3em",
              }}
            >
              {
                {
                  工作單資料: <WorkInformation header="工作單資料" setChanged={setChanged} changed={changed} />,
                  工作報告: <WorkReport header="維修保養單工作報告" />,
                  員工資料: <AssignWorker header="維修保養單員工資料" />,
                  圖片紀錄: <ImagesRecord header="維修保養單圖片紀錄" />,
                  文件: <ServiceDocument />,
                  客戶滿意度: <GuestSatisfactory header="維修保養單滿意度" />,
                }[activeTab]
              }
            </TabContent>
          </>
        )}
      </div>
      <InformationModal isOpen={showInformation} closeAction={() => setShowInformation(false)} />
    </Layout>
  );
}
