import axios from "axios";
import { useEffect, useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setMessageModal } from "redux/Loading/action";
import { setServiceReport, setWorkChangesMade } from "redux/Order/action";
import { IRootState } from "store";
import FormHeader from "./parts/FormHeader";

interface IWorkReport {
  header: string;
}

export default function WorkReport({ header }: IWorkReport) {
  const [original, setOriginal] = useState<{ serviceSubject: string | undefined; serviceReport: string | undefined }>({
    serviceSubject: "",
    serviceReport: "",
  });
  const data = useSelector((state: IRootState) => state.orderById.orderById);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const pathname = window.location.pathname.split("/");
    const orderId = pathname[pathname.length - 1];
    try {
      await axios.put(`/orders/${orderId}`, {
        order: data,
      });
      setOriginal({ serviceSubject: data.serviceSubject, serviceReport: data.serviceReport });
      dispatch(setWorkChangesMade(true));
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  useEffect(() => {
    setOriginal({ serviceSubject: data.serviceSubject, serviceReport: data.serviceReport });
  }, []);

  const disabled = original.serviceSubject === data.serviceSubject && original.serviceReport === data.serviceReport;

  return (
    <div className="p-3" style={{ position: "relative" }}>
      <FormHeader
        offset={false}
        header={header}
        includeExtraButton={true}
        children={
          <div className="d-flex justify-content-end">
            <Button style={{ width: "100%" }} color="primary" onClick={handleSubmit} disabled={disabled}>
              儲存更改
            </Button>
          </div>
        }
      />
      <Row className="d-flex my-3">
        <h5>服務事項</h5>
        <TextareaAutosize
          rows={4}
          value={data.serviceSubject}
          onChange={(e) => dispatch(setServiceReport({ serviceSubject: e.currentTarget.value }))}
        />
      </Row>
      <Row className="d-flex my-3">
        <h5>服務報告</h5>
        <TextareaAutosize
          rows={6}
          value={data.serviceReport}
          onChange={(e) => dispatch(setServiceReport({ serviceReport: e.currentTarget.value }))}
        />
      </Row>
    </div>
  );
}
