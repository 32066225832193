import Page from "components/404page";
import { useEffect, useState } from "react";
import Layout from "./Layout";

export default function ErrorPage() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  return <Layout>{isLoading ? <></> : <Page />}</Layout>;
}
