import { FetchAssignedWorkers, FetchStaffHistory } from 'models/responseModels';

export const setViewWorkerModalOpen = (isOpen: boolean) => {
  return {
    type: '@@ViewWorkerModal/setIsOpen' as const,
    isOpen,
  };
};
export const setViewWorkerModalData = (item: FetchAssignedWorkers) => {
  return {
    type: '@@ViewWorkerModal/setViewWorkerModalData' as const,
    item,
  };
};

export const setViewWorkerHistory = (item: FetchStaffHistory[]) => {
  return {
    type: '@@ViewWorkerModal/setViewWorkerHistory' as const,
    item,
  };
};
export const clearViewWorkerModalData = () => {
  return {
    type: '@@ViewWorkerModal/clearViewWorkerModalData' as const,
  };
};
export const clearViewWorkerHistory = () => {
  return {
    type: '@@ViewWorkerModal/clearViewWorkerHistory' as const,
  };
};

type ActionCreators =
  | typeof setViewWorkerModalOpen
  | typeof setViewWorkerModalData
  | typeof setViewWorkerHistory
  | typeof clearViewWorkerModalData
  | typeof clearViewWorkerHistory;

export type ViewWorkerModalActions = ReturnType<ActionCreators>;
