import axios from "axios";
import "css/Form.css";
import { formatDate, formatDateAndTime } from "helpers/date";
import { A_DELETE_ORDER } from "models/authorities";
import { IOrders, OrderById } from "models/responseModels";
import { enableUrgentIcon } from "models/urgentIcon";
import { AREAS, DivisionType, progressColor, PROGRESS_STATUS, SERVICE_TYPES } from "models/variables";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading } from "redux/Loading/action";
import { resetWorkPagination, setOrderByIdData, setWorkChangesMade, setWorkCurrentPage } from "redux/Order/action";
import { IRootState } from "store";
import { DeleteIcon, EditIcon, ServiceTypeIcon, VeryUrgentIcon } from "./IconsOnly";
import { ActionRow } from "./parts/ActionRow";
import DeleteModal from "./parts/DeleteModal";
import FormHeader from "./parts/FormHeader";
import ProgressIndicator from "./parts/ProgressIndicator";
import SortableTableHeaders from "./parts/SortableTableHeaders";

interface IWorkInfo {
  header: string;
  type: DivisionType | null;
}

export default function WorkInfo({ header, type }: IWorkInfo) {
  const [sortByDirection, setSortByDirection] = useState<{ sortType: string; isDesc: boolean }>({
    sortType: "createdAt",
    isDesc: true,
  });
  const [toolTip, setToolTip] = useState({ idx: -1, posX: -1 });
  const [modal, setModal] = useState(false);
  const [hovered, setHovered] = useState({ idx: 0, hovered: false });
  const [searchText, setSearchText] = useState("");
  const [editTarget, setEditTarget] = useState<{ name: string; id: number }>({ name: "", id: -1 });
  const [fetchedOrders, setFetchedOrders] = useState<Array<IOrders>>([]);
  const [lastPage, setLastPage] = useState(1);
  const [searchByStaff, setSearchByStaff] = useState(false);
  const userRole = useSelector((state: IRootState) => state.auth.role);

  const sortableHeaders = [
    { headerText: "動作", headerKey: "" },
    { headerText: "單編號", headerKey: "orderNumber" },
    { headerText: "ACJ 號碼 / P.O. 號碼", headerKey: "acjNo" },
    { headerText: "客戶", headerKey: "clientName" },
    { headerText: "區域", headerKey: "area" },
    { headerText: "服務性質", headerKey: "serviceType" },
    { headerText: "開始日期", headerKey: "startDate" },
    { headerText: "完工日期", headerKey: "completeDate" },
    { headerText: "狀態", headerKey: "progressStatus" },
    { headerText: "媒體", headerKey: "mediaCount" },
    { headerText: "建立日期", headerKey: "createdAt" },
  ];

  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const pagination = useSelector((state: IRootState) => state.orderById.pagination);
  const changesMade = useSelector((state: IRootState) => state.orderById.changesMade);
  const global = useSelector((state: IRootState) => state.loading);
  const maxRow = global.maxRow;
  const userPreferredRow = global.userPreferredRow;

  const fetchAllOrders = async (pageNumber: number) => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders`);
      if (!!type) {
        url.searchParams.set("division", `${type}`);
      }
      if (userPreferredRow) {
        url.searchParams.set("rows", userPreferredRow.toString());
      } else {
        url.searchParams.set("rows", maxRow.toString());
      }
      if (userRole !== "SUPER_ADMIN" && userRole !== "ADMIN") {
        url.searchParams.set("assigned", "1");
      }
      url.searchParams.set("page", pageNumber.toString());
      if (searchByStaff) {
        url.searchParams.set("staff", searchText);
      } else {
        url.searchParams.set("search", searchText);
      }
      url.searchParams.set("order", `${sortByDirection.sortType}`);
      url.searchParams.set("direction", `${sortByDirection.isDesc ? "desc" : "asc"}`);
      const res = await axios.get(url.toString());
      const result = res.data;
      setFetchedOrders(result.data);
      setLastPage(result.pagination.lastPage);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchAllOrders(pagination.currentPage);
  }, []);

  useEffect(() => {
    fetchAllOrders(pagination.currentPage);
  }, [dispatch, pagination.currentPage, maxRow, userPreferredRow]);

  useEffect(() => {
    if (!changesMade) return;
    fetchAllOrders(1);
    dispatch(setWorkChangesMade(false));
  }, [dispatch, changesMade]);

  const fetchOrderById = async (orderId: number, division: DivisionType, isEdit: boolean) => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders/${orderId}`);
      const res = await axios.get<OrderById>(url.toString());
      const result = res.data;
      const res2 = await axios.get(`order-survey/${orderId}`);
      const result2 = await res2.data;
      dispatch(setOrderByIdData({ ...result, surveyCompleted: !!result2.data }));
      if (isEdit) {
        history.push(`/${division === "PROJECT" ? "service" : "maintenance"}/work/${orderId}`);
      } else {
        history.push(`/${division === "PROJECT" ? "service" : "maintenance"}/view/${orderId}`);
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const handleSelect = (item: IOrders, isEdit: boolean) => {
    dispatch(setIsLoading(true));
    fetchOrderById(item.id, item.division, isEdit);
  };
  // const selectEditItem = (item: IOrders) => {
  //   dispatch(setIsLoading(true));
  //   fetchOrderById(item.id, item.division, true);
  // };

  const handleSearch = () => {
    dispatch(resetWorkPagination());
    fetchAllOrders(1);
  };

  const handleControlSort = (sortType: string) => {
    if (sortByDirection.sortType === sortType) {
      setSortByDirection({ sortType, isDesc: !sortByDirection.isDesc });
    } else {
      setSortByDirection({ sortType, isDesc: true });
    }
  };

  useEffect(() => {
    fetchAllOrders(1);
  }, [sortByDirection.sortType, sortByDirection.isDesc]);

  return (
    <div
      className="p-3 relative"
      style={{ overflowX: "hidden", marginBottom: userPreferredRow && userPreferredRow > maxRow ? "80px" : "unset" }}
    >
      <FormHeader offset={false} header={header} />
      <ActionRow
        lastPage={lastPage}
        currentPage={pagination.currentPage}
        addItem={type === "MAINTENANCE" ? "保養單" : "工作單"}
        setCurrentPageFunction={setWorkCurrentPage}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
        placeholderText={"ACJ 號碼 / P.O. 號碼 / 工作單編號 / 客戶"}
        searchByStaff={searchByStaff}
        setSearchByStaff={setSearchByStaff}
      />
      <ProgressIndicator />
      {!fetchedOrders.length ? (
        <div className="my-2 flex-center full-width disableText">沒有工作單</div>
      ) : (
        <div className="flex-center full-width" style={{ overflowX: "auto" }}>
          <div className="p-2 viewCoTable">
            <SortableTableHeaders
              headers={sortableHeaders}
              sortControl={handleControlSort}
              currentSorting={sortByDirection}
            />
            <div className="tableBody relative">
              {isLoading ? (
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              ) : (
                fetchedOrders.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      onMouseEnter={() => setHovered({ idx, hovered: true })}
                      onMouseLeave={() => setHovered({ idx, hovered: false })}
                      className={`flex-center tableRow`}
                      style={{
                        background:
                          hovered.idx === idx
                            ? progressColor(item.progressStatus, hovered.hovered)
                            : progressColor(item.progressStatus),
                      }}
                    >
                      <div className="flex-row-around tdActionContainer">
                        <div
                          className="flex-center tdIconContainer full-height"
                          onClick={() => {
                            handleSelect(item, true);
                          }}
                        >
                          <EditIcon />
                        </div>
                        {A_DELETE_ORDER.includes(userRole) && (
                          <div
                            className="flex-center tdIconContainer full-height"
                            onClick={() => {
                              setModal(!modal);
                              setEditTarget({ name: item.orderNumber, id: item.id });
                            }}
                          >
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                      <div className="flex-center tdDateItem relative" onClick={() => handleSelect(item, false)}>
                        {String(SERVICE_TYPES[item.serviceType]).match("e-call") &&
                          enableUrgentIcon.includes(item.progressStatus) && (
                            <div className="absolute" style={{ left: "8px" }}>
                              <VeryUrgentIcon />
                            </div>
                          )}
                        {item.orderNumber || "-"}
                      </div>
                      <div className="flex-center tdMainItem" onClick={() => handleSelect(item, false)}>
                        {item.acjNo || "-"}
                      </div>
                      <div
                        className="tdClientNameItem p-2"
                        onClick={() => handleSelect(item, false)}
                        onMouseEnter={(e) => {
                          setToolTip({ idx, posX: e.pageX });
                        }}
                        onMouseLeave={() => setToolTip({ idx: -1, posX: -1 })}
                      >
                        {item.clientName || "-"}
                      </div>
                      {toolTip.idx === idx && (
                        <div
                          className="p-2"
                          style={{
                            zIndex: 10,
                            position: "absolute",
                            background: "#444",
                            border: "solid 1px #5555",
                            borderRadius: "4px",
                            left: `${toolTip.posX * 0.65}px`,
                            top: -16,
                            color: "#EEE",
                          }}
                        >
                          {item.clientName}
                        </div>
                      )}
                      <div className="flex-center tdDistrict relative" onClick={() => handleSelect(item, false)}>
                        {!type && item.serviceType && (
                          <div className="absolute" style={{ left: 8 }}>
                            <ServiceTypeIcon isMaintenance={item.serviceType.startsWith("M")} />
                          </div>
                        )}
                        {AREAS[item.area] || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item, false)}>
                        {SERVICE_TYPES[item.serviceType] || "-"}
                      </div>
                      <div className="flex-center tdDateItem" onClick={() => handleSelect(item, false)}>
                        {item.startDate ? formatDate(item.startDate) : " - "}
                      </div>
                      <div className="flex-center tdDateItem" onClick={() => handleSelect(item, false)}>
                        {item.completeDate ? formatDate(item.completeDate) : " - "}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item, false)}>
                        {PROGRESS_STATUS[item.progressStatus]}
                      </div>
                      <div className="flex-center tdShortItem" onClick={() => handleSelect(item, false)}>
                        {item.mediaCount}
                      </div>
                      <div className="flex-center tdDateItem" onClick={() => handleSelect(item, false)}>
                        {formatDateAndTime(item.createdAt)}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            <DeleteModal isOpen={modal} deleteTarget={editTarget} setModal={setModal} addItemString={"工作單"} />
          </div>
        </div>
      )}
    </div>
  );
}
