import Layout from "pages/Layout";
import DocumentById from "./DocumentById";

export default function DocumentByIdPage() {
  return (
    <Layout>
      <DocumentById />
    </Layout>
  );
}
