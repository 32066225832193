import axios from "axios";
import { ConnectedRouter } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { checkLogin } from "redux/Auth/action";
import { setMaxRow } from "redux/Loading/action";
import "./App.css";
import Main from "./Main";
import { history, IRootState, store } from "./store";

export const infoBarStatus = React.createContext<boolean | null>(null);
export const infoBarStatusUpdate = React.createContext<React.Dispatch<React.SetStateAction<boolean>> | null>(null);

function App() {
  const [infoBarState, setInfoBarState] = useState(true);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: IRootState) => state.auth.isAuthenticated);
  const userId = useSelector((state: IRootState) => state.auth.userId);

  useEffect(() => {
    if (!isLoggedIn || !userId) {
      dispatch(checkLogin());
    }
  }, [dispatch, isLoggedIn, userId]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const maxRow = Math.floor((window.innerHeight - 58 - 190 - 64) / 40);
      dispatch(setMaxRow(maxRow));
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [dispatch]);

  // Axios setups
  axios.defaults.baseURL = `${process.env.REACT_APP_API_PATH}`;
  axios.defaults.headers.get["Content-Type"] = "application/json; charset=utf-8";
  axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";
  axios.defaults.withCredentials = true;

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <infoBarStatus.Provider value={infoBarState}>
          <infoBarStatusUpdate.Provider value={setInfoBarState}>
            <Main />
          </infoBarStatusUpdate.Provider>
        </infoBarStatus.Provider>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
