import Layout from "pages/Layout";
import React from "react";
import NewTemplate from "./NewTemplate";

export default function NewTemplatePage() {
  return (
    <Layout>
      <NewTemplate />
    </Layout>
  );
}
