import { IDocumentList } from "components/DocumentList";
import { DeleteIcon } from "components/IconsOnly";
import { ActionRow } from "components/parts/ActionRow";
import DeleteModal from "components/parts/DeleteModal";
import FormHeader from "components/parts/FormHeader";
import SortableTableHeaders from "components/parts/SortableTableHeaders";
import { formatDateAndTime } from "helpers/date";
import { ITemplates } from "models/responseModels";
import { DIVISIONS } from "models/variables";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { setTemplateCurrentPage, setTemplateData } from "redux/Template/action";
import { IRootState } from "store";

export default function TemplateList(props: IDocumentList) {
  const {
    header,
    isSelectionMode = false,
    searchText,
    setSearchText,
    handleSearch,
    sortByDirection,
    setSortByDirection,
  } = props;
  const [modal, setModal] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<{ name: string; id: number }>({ name: "", id: -1 });
  const sortableHeaders = isSelectionMode
    ? [
        { headerText: "文件類型編號", headerKey: "formNo" },
        { headerText: "範本名稱", headerKey: "name" },
        { headerText: "註解", headerKey: "docTypeDescription" },
        { headerText: "部門", headerKey: "division" },
        { headerText: "更新日期", headerKey: "updatedAt" },
      ]
    : [
        { headerText: "動作", headerKey: "" },
        { headerText: "文件類型編號", headerKey: "formNo" },
        { headerText: "範本名稱", headerKey: "name" },
        { headerText: "註解", headerKey: "docTypeDescription" },
        { headerText: "部門", headerKey: "division" },
        { headerText: "更新日期", headerKey: "updatedAt" },
      ];

  const data = useSelector((state: IRootState) => state.templates.templateDataArray);
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const pagination = useSelector((state: IRootState) => state.templates.pagination);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSelect = (item: ITemplates) => {
    dispatch(setTemplateData(item));
    history.push(`/admin/template/${item.id}`);
  };

  const placeholderText = "文件類型編號 / 範本名稱 / 註解";

  const handleControlSort = (sortType: string) => {
    if (sortByDirection && setSortByDirection) {
      if (sortByDirection.sortType === sortType) {
        setSortByDirection({ sortType, isDesc: !sortByDirection.isDesc });
      } else {
        setSortByDirection({ sortType, isDesc: true });
      }
    }
  };

  return (
    <div className="p-3 relative" style={{ overflowX: "hidden" }}>
      <FormHeader offset={false} header={header} />
      <ActionRow
        lastPage={pagination.lastPage}
        addItem="文件類型"
        currentPage={pagination.currentPage}
        setCurrentPageFunction={setTemplateCurrentPage}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
        placeholderText={placeholderText}
        isSelectionMode={true}
      />
      {!data.length ? (
        <div className="my-2 flex-center full-width disableText">沒有範本</div>
      ) : (
        <div className="flex-center full-width" style={{ overflowX: "auto" }}>
          <div className="p-2 viewCoTable">
            <SortableTableHeaders
              headers={sortableHeaders}
              sortControl={handleControlSort}
              currentSorting={sortByDirection}
            />
            <div className="tableBody relative">
              {isLoading ? (
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              ) : (
                data.map((item, idx) => {
                  return (
                    <div key={idx} className={`flex-center tableRow`}>
                      <div className="flex-row-around tdActionContainer">
                        <div
                          className="flex-center tdIconContainer full-height"
                          onClick={() => {
                            setModal(!modal);
                            setDeleteTarget({ name: item.name, id: item.id });
                          }}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                        {item.formNo || "-"}
                      </div>
                      <div className="tdLongItem" onClick={() => handleSelect(item)}>
                        {item.name || "-"}
                      </div>
                      <div className="tdLongItem" onClick={() => handleSelect(item)}>
                        {item.docTypeDescription || "-"}
                      </div>
                      <div className="flex-center tdDistrict" onClick={() => handleSelect(item)}>
                        {(item.division && DIVISIONS[item.division]) || "-"}
                      </div>
                      <div className="flex-center tdDateItem" onClick={() => handleSelect(item)}>
                        {formatDateAndTime(item.updatedAt) || "-"}
                      </div>
                    </div>
                  );
                })
              )}
              <DeleteModal isOpen={modal} deleteTarget={deleteTarget} setModal={setModal} addItemString={"文件"} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
