import axios from "axios";
import { AllWorkersType } from "components/AssignWorker";
import { CloseIcon, DeleteIcon, SearchIcon } from "components/IconsOnly";
import { DISTRICTS } from "models/districtModels";
import { FetchAssignedWorkers } from "models/responseModels";
import { DIVISIONS } from "models/variables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Modal, Nav, Row, Spinner, TabContent } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading } from "redux/Loading/action";
import { clearNewOrderData, resetWorkerList, setNewOrderData } from "redux/Order/action";
import { IRootState } from "store";
import ReactstrapTabNavLink from "../ReactstrapTabNavLink";
import SortableTableHeaders from "../SortableTableHeaders";
import { AssignList } from "./NewAdminDetails";

function NewOrderAssignWorker() {
  const [hovered, setHovered] = useState(false);
  const [assignUserOpen, setAssignUserOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("WORKER");
  const [allUsers, setAllUsers] = useState<AllWorkersType[]>([]);
  const [workerLists, setWorkerLists] = useState<{
    supervisorList: AllWorkersType[];
    foremenList: AllWorkersType[];
    workerList: AllWorkersType[];
  }>({ supervisorList: [], foremenList: [], workerList: [] });
  const [assignedWorkers, setAssignedWorkers] = useState<FetchAssignedWorkers[]>([]);
  const [modalSearchText, setModalSearchText] = useState("");
  const [sortByDirection, setSortByDirection] = useState<{ sortType: string; isDesc: boolean }>({
    sortType: "role",
    isDesc: true,
  });

  const data = useSelector((state: IRootState) => state.orderById.newOrderData);
  const dispatch = useDispatch();

  const sortableHeaders = [
    { headerText: "動作", headerKey: "" },
    { headerText: "員工編號", headerKey: "staffNumber" },
    { headerText: "中文姓名", headerKey: "chiName" },
    { headerText: "英文姓名", headerKey: "engName" },
    { headerText: "聯絡電話", headerKey: "mobile" },
    { headerText: "居住區域", headerKey: "residenceDistrict" },
    { headerText: "所屬公司", headerKey: "company" },
    { headerText: "所屬部門", headerKey: "division" },
    { headerText: "員工職位", headerKey: "role" },
  ];
  const tabs = ["WORKER", "FOREMEN", "SUPERVISOR"];
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const orderById = useSelector((state: IRootState) => state.orderById);
  const assignWorkers = orderById.assignWorkers;

  function toggle(tab: string) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const fetchAllUsers = async () => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/users`);
      url.searchParams.set("type", "form");
      if (!!modalSearchText) url.searchParams.set("search", modalSearchText);
      const res = await axios.get(url.toString());
      const result = res.data;
      setAllUsers(result.data);
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
    dispatch(setIsLoading(false));
  };

  const handleSelect = async (item: FetchAssignedWorkers) => {
    if (data.staffIds?.includes(item.id)) {
      const tempArray = assignedWorkers.slice();
      tempArray.splice(tempArray.indexOf(item), 1);
      dispatch(setNewOrderData({ ...data, staffIds: tempArray.map((item) => item.id) }));
      setAssignedWorkers(tempArray);
    } else {
      if (data.staffIds) {
        dispatch(setNewOrderData({ ...data, staffIds: [...data.staffIds, item.id] }));
        setAssignedWorkers([...assignedWorkers, item]);
      }
    }
  };

  useEffect(() => {
    setWorkerLists({
      supervisorList: allUsers.filter((i) => i.role === "SUPERVISOR"),
      foremenList: allUsers.filter((i) => i.role === "FOREMEN"),
      workerList: allUsers.filter((i) => i.role === "WORKER"),
    });
  }, [allUsers, assignedWorkers, assignWorkers, assignUserOpen]);

  useEffect(() => {
    dispatch(resetWorkerList());
    fetchAllUsers();
  }, [dispatch, assignUserOpen]);

  const handleControlSort = (sortType: string) => {
    if (sortByDirection.sortType === sortType) {
      setSortByDirection({ sortType, isDesc: !sortByDirection.isDesc });
    } else {
      setSortByDirection({ sortType, isDesc: true });
    }
  };

  useEffect(() => {
    return () => {
      setAssignedWorkers([]);
      dispatch(clearNewOrderData());
    };
  }, []);

  const redMark = (identifier: boolean) => {
    return {
      color: "red",
      width: !identifier ? "16px" : "0px",
      transition: "all 0.3s",
      opacity: !identifier ? 1 : 0,
    };
  };

  return (
    <>
      <Row className={`my-3 d-flex`}>
        <Col className={`p-2 flex-row-start flex1`}>
          <div style={redMark(!!data.staffIds?.length)}>{"*"}</div>
          <div className={`p-2 w-10`}>
            <h5 className="m-0">員工</h5>
          </div>
          <div className="w-10">
            <Button color="primary" onClick={() => setAssignUserOpen(true)}>
              加入員工
            </Button>
          </div>
        </Col>
      </Row>
      {!assignedWorkers.length ? (
        <Row className="my-4 d-flex justify-content-center" style={{ minHeight: "64px" }}>
          <Col className="d-flex justify-content-center">
            <h5 className="disableText">尚未加入員工</h5>
          </Col>
        </Row>
      ) : (
        <div className="flex-center full-width" style={{ overflowX: "auto" }}>
          <div className="p-2 viewCoTable">
            <SortableTableHeaders
              isNewOrderMode
              headers={sortableHeaders}
              sortControl={handleControlSort}
              currentSorting={sortByDirection}
            />
            <div className="tableBody relative">
              {isLoading ? (
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              ) : (
                assignedWorkers.map((item, idx) => {
                  return (
                    <div key={idx} className={`flex-center tableRow`} style={{ cursor: "default" }}>
                      <div className="flex-row-around tdActionContainer">
                        <div
                          className="flex-center tdIconContainer full-height pointer"
                          onClick={() => {
                            handleSelect(item);
                          }}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                      <div className="flex-center tdItem">{item.staffNumber || "-"}</div>
                      <div className="flex-center tdItem">{item.chiName || "-"}</div>
                      <div className="flex-center tdItem">{item.engName || "-"}</div>
                      <div className="flex-center tdItem">{item.mobile || "-"}</div>
                      <div className="flex-center tdDistrict">{DISTRICTS[item.residenceDistrict]}</div>
                      <div className="tdMainItem">{item.company}</div>
                      <div className="flex-center tdItem">{item.division ? DIVISIONS[item.division] : "-"}</div>
                      <div className="flex-center tdItem">{item.role}</div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={assignUserOpen}
        className="d-flex justify-content-center"
        style={{ minWidth: "70vw", maxHeight: "90vh" }}
      >
        <Container className="p-3 relative" style={{ overflowY: "scroll" }}>
          <div className="flex-row-start">
            <div className="flex1" />
            <h2 className="fit-content">{`加入員工`}</h2>
            <div
              className="flex1 flex-row-end"
              onClick={() => {
                setAssignUserOpen(false);
              }}
            >
              <div
                className={`closeButtonContainer ${hovered && "buttonHovered"}`}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <CloseIcon color="#EEE" />
              </div>
            </div>
          </div>
          <Row className="px-2 flex-row-between m-4">
            <Col md={6} className="d-flex">
              <input
                className="searchInput"
                style={{ width: "100%" }}
                value={modalSearchText}
                placeholder={"員工編號 / 中文姓名 / 手機號碼"}
                onChange={(e) => setModalSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") fetchAllUsers();
                }}
              ></input>
              <div className="mx-3" onClick={fetchAllUsers}>
                <SearchIcon />
              </div>
            </Col>
            <Col className="flex-row-end">
              <Button color="primary" onClick={() => setAssignUserOpen(false)}>
                確認加入員工
              </Button>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Nav tabs style={{ height: "40px" }}>
              {tabs.map((element) => {
                const props = { tabContent: element, activeTab, toggle };
                return <ReactstrapTabNavLink key={element} {...props} />;
              })}
            </Nav>

            <TabContent activeTab={activeTab} className="flex-center" style={{ width: "100%" }}>
              <form className="full-width">
                {
                  {
                    SUPERVISOR: (
                      <AssignList
                        assignList={workerLists.supervisorList}
                        closeAction={setAssignUserOpen}
                        isNewOrderMode
                        assignWorkerToNewOrder={handleSelect}
                      />
                    ),
                    FOREMEN: (
                      <AssignList
                        assignList={workerLists.foremenList}
                        closeAction={setAssignUserOpen}
                        isNewOrderMode
                        assignWorkerToNewOrder={handleSelect}
                      />
                    ),
                    WORKER: (
                      <AssignList
                        assignList={workerLists.workerList}
                        closeAction={setAssignUserOpen}
                        isNewOrderMode
                        assignWorkerToNewOrder={handleSelect}
                      />
                    ),
                  }[activeTab]
                }
              </form>
            </TabContent>
          </div>
        </Container>
      </Modal>
    </>
  );
}

export default NewOrderAssignWorker;
