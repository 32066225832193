import { progressColor, PROGRESS_STATUS } from "models/variables";
import React from "react";
import { Row } from "reactstrap";

function ProgressIndicator() {
  return (
    <Row className="pointer-events-none">
      <div className="my-1 flex-row-around">
        <div className="mx-1 flex-center">
          <div
            style={{
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              background: progressColor("NEW"),
              border: "solid 1px #AAA",
            }}
          />
          <div className="mx-1">{PROGRESS_STATUS["NEW"]}</div>{" "}
        </div>
        <div className="mx-1 flex-center">
          <div
            style={{
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              background: progressColor("ASSIGNED"),
              border: "solid 1px #AAA",
            }}
          />
          <div className="mx-1">{"已安排 / 現場工作中 / 前線跟進中 / 前線完成"}</div>
        </div>
        <div className="mx-1 flex-center">
          <div
            style={{
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              background: progressColor("待報價"),
              border: "solid 1px #AAA",
            }}
          />
          <div className="mx-1">{"待報價 / 已報價 / 需上單 / 已上單"}</div>
        </div>
        <div className="mx-1 flex-center">
          <div
            style={{
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              background: progressColor("COMPLETED"),
              border: "solid 1px #AAA",
            }}
          />
          <div className="mx-1">{PROGRESS_STATUS["COMPLETED"]}</div>
        </div>
        <div className="mx-1 flex-center">
          <div
            style={{
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              background: progressColor("客方取消"),
              border: "solid 1px #AAA",
            }}
          />
          <div className="mx-1">{"客方取消 / 錯單取消"}</div>
        </div>
      </div>
    </Row>
  );
}

export default ProgressIndicator;
