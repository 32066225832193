import axios from "axios";
import { SelectedJobCardMaintenanceAgreement } from "components/documents/JobCardMaintenanceAgreement";
import { DeleteIcon, DocumentEditIcon, NewTemplateIcon, PrintIcon } from "components/IconsOnly";
import DeleteModal from "components/parts/DeleteModal";
import FormHeader from "components/parts/FormHeader";
import NewDocumentModal from "components/parts/NewOrder/NewDocumentModal";
import PreviewNewDocumentModal from "components/parts/NewOrder/PreviewNewDocument";
import TableHeaders from "components/parts/TableHeaders";
import ViewCoHeader, { ViewCoFooter } from "components/parts/ViewcoHeader";
import { JobCompletionSignatureFields } from "components/ViewOrderHistoryClone";
import { pageStyle } from "css/printCss";
import { formatDateAndTime } from "helpers/date";
import { ITemplateById } from "models/responseModels";
import { DivisionType } from "models/variables";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { Button, Col, Container, Modal, Row, Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { resetDocumentData, setChangesMade } from "redux/Document/action";
import { setMessageModal } from "redux/Loading/action";
import {
  setNewDocumentChangesMade,
  setNewDocumentModalOpen,
  setSelectedDocumentModalOpen,
} from "redux/ServiceDocument/action";
import { setTemplateContentArray, setTemplateData, setTemplatePagination } from "redux/Template/action";
import { IRootState } from "store";

interface IServiceDocument {
  id: number;
  name: string;
  division: DivisionType | null;
  createdAt: string;
  formNo: string;
  updatedAt: string;
  docTypeId: number;
  docTypeName: string;
  docTypeDescription: string;
  docTypeIsActive: boolean;
  date: string;
  isTemplate: boolean;
}

function ServiceDocument() {
  const [selectModal, setSelectModal] = useState(false);
  const [saveDocumentModalOpen, setSaveDocumentModalOpen] = useState(false);
  const [allFields, setAllFields] = useState<Array<ITemplateById>>([]);
  const [isSignatureNeeded, setIsSignatureNeeded] = useState(false);
  const [serviceDocument, setServiceDocument] = useState<IServiceDocument[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<{ name: string; id: number }>({ name: "", id: -1 });
  const [originalName, setOriginalName] = useState("");
  const printRef = useRef<HTMLTableElement>(null);
  const changesMade = useSelector((state: IRootState) => state.newDocument.changesMade);
  const documentChangesMade = useSelector((state: IRootState) => state.documents.changesMade);
  const dispatch = useDispatch();
  const pagination = useSelector((state: IRootState) => state.templates.pagination);
  const orderData = useSelector((state: IRootState) => state.orderById.orderById);
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const templates = useSelector((state: IRootState) => state.templates);
  const templateContentArray = templates.templateContentArray;
  const pathname = window.location.pathname.split("/");
  const orderId = pathname[pathname.length - 1];

  const fetchAllDocuments = async (pageNumber: number) => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/doc`);
      url.searchParams.set("orderId", orderId.toString());
      url.searchParams.set("rows", "10");
      url.searchParams.set("page", pageNumber.toString());
      const res = await axios.get(url.toString());
      const result = res.data;
      setServiceDocument(result.data);
      dispatch(setTemplatePagination(result.pagination));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };
  const fetchAllFields = async (serviceDocumentId: number) => {
    try {
      const res = await axios.get(`/doc-content/${serviceDocumentId}`);
      const result = await res.data.data;
      setAllFields(result);
      dispatch(setTemplateContentArray(result));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  // const handleSearch = async () => {
  //   // console.log("hihi");
  // };

  const handleSelectDocument = (item: IServiceDocument) => {
    dispatch(setTemplateData(item));
    setOriginalName(item.name);
    fetchAllFields(item.id);
    setSelectModal(true);
  };

  const handleEdit = (idx: number, value: string) => {
    const newArr = allFields.slice();
    newArr[idx] = {
      ...newArr[idx],
      content: value,
    };
    setAllFields(newArr);
  };

  const putEditDocumentName = async () => {
    try {
      await axios.put(`/doc/${templates.templateData.id}`, {
        docInfo: {
          name: templates.templateData.name,
        },
      });
      setOriginalName(templates.templateData.name);
      setSaveDocumentModalOpen(false);
      dispatch(setChangesMade(true));
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };
  const putEditedContent = async () => {
    const postContent = allFields.map((i) => {
      return { contentId: i.contentId, content: i.content };
    });
    try {
      await axios.put(`/doc-content`, {
        content: postContent,
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  useEffect(() => {
    if (changesMade) {
      fetchAllDocuments(1);
      dispatch(setNewDocumentChangesMade(false));
    } else {
      fetchAllDocuments(pagination.currentPage);
    }
    return () => {
      dispatch(setNewDocumentModalOpen(false));
      dispatch(setSelectedDocumentModalOpen(false));
      dispatch(resetDocumentData());
      setOriginalName("");
    };
  }, [dispatch, pagination.currentPage, changesMade]);

  useEffect(() => {
    fetchAllDocuments(pagination.currentPage);
    dispatch(setChangesMade(false));
  }, [dispatch, pagination.currentPage, documentChangesMade, changesMade]);

  const header = ["動作", "文件編號", "文件名稱", "註解", "加入日期"];

  return (
    <div className="p-3" style={{ overflowY: "scroll", position: "relative" }}>
      <FormHeader
        offset={false}
        header={orderData.orderNumber + "的文件"}
        includeExtraButton
        children={
          <Button color="primary" onClick={() => dispatch(setNewDocumentModalOpen(true))}>
            新增文件
          </Button>
        }
      />
      <div className="mt-4 flex-center">
        {!serviceDocument.length ? (
          <Row className="my-4 d-flex justify-content-center" style={{ minHeight: "64px" }}>
            <Col className="d-flex justify-content-center">
              <h5 className="disableText">暫無資料</h5>
            </Col>
          </Row>
        ) : isLoading ? (
          <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
        ) : (
          <div className="flex-center full-width" style={{ overflowX: "auto" }}>
            <div className="p-2 viewCoTable">
              <TableHeaders headers={header} />
              <div className="tableBody relative">
                {serviceDocument.map((item, idx) => {
                  return (
                    <div key={idx} className={`flex-center tableRow`}>
                      <div className="flex-row-around tdActionContainer">
                        <div
                          className="flex-center tdIconContainer full-height"
                          onClick={() => {
                            setDeleteModal(!deleteModal);
                            setDeleteTarget({ name: item.name, id: item.id });
                          }}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelectDocument(item)}>
                        {item.formNo || "-"}
                      </div>
                      <div className="tdLongItem" onClick={() => handleSelectDocument(item)}>
                        {item.name || "-"}
                      </div>
                      <div className="tdLongItem" onClick={() => handleSelectDocument(item)}>
                        {item.docTypeDescription || "-"}
                      </div>
                      <div className="flex-center tdItem" onClick={() => handleSelectDocument(item)}>
                        {formatDateAndTime(item.updatedAt) || "-"}
                      </div>
                    </div>
                  );
                })}
                <DeleteModal
                  isOpen={deleteModal}
                  deleteTarget={deleteTarget}
                  setModal={setDeleteModal}
                  addItemString={"文件"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal size="xl" isOpen={selectModal && templateContentArray !== []}>
        <Container className="p-4 flex-center flex-column">
          <Row className="mb-3 flex-row-between viewDocumentPageTopRow">
            <Col md={4} className="d-flex align-items-center">
              <FaArrowCircleLeft size={32} onClick={() => setSelectModal(false)} className="pointer" />
            </Col>
            <Col md={4}>
              <div className="d-flex flex-column align-items-center" style={{ position: "relative" }}>
                <h5 className="flex-center" style={{ marginBottom: 0, textAlign: "center", position: "relative" }}>
                  <div
                    className="printButtonContainer"
                    style={{
                      position: "absolute",
                      left: "-80px",
                      top: "-12px",
                      height: "max-content",
                    }}
                    onClick={() => {
                      setSaveDocumentModalOpen(true);
                      setOriginalName(templates.templateData.name);
                    }}
                  >
                    <div className="flex-center printIconContainer">
                      <DocumentEditIcon />
                    </div>
                  </div>
                  {templates.templateData.name}
                </h5>
              </div>
            </Col>
            <Col md={4} className="buttonContainer">
              <div className="signatureButtonContainer mx-2">
                <div className="toggleButtonContainer" onClick={() => setIsSignatureNeeded(!isSignatureNeeded)}>
                  <div
                    className="fullToggleButton"
                    style={{
                      left: isSignatureNeeded ? "20px" : "-26px",
                    }}
                  >
                    <div style={{ height: "100%", marginRight: "8px" }}>ON</div>
                    <div className="toggleButton" style={{ background: isSignatureNeeded ? "#306ef4" : "#888" }} />
                    <div style={{ height: "100%", marginLeft: "8px" }}>OFF</div>
                  </div>
                </div>
                <div className="toggleButtonText">簽名欄</div>
              </div>
              <ReactToPrint
                bodyClass={"contentContainer"}
                pageStyle={pageStyle}
                content={() => printRef.current}
                trigger={() => (
                  <div className="printButtonContainer mx-2">
                    <div className="flex-center printIconContainer">
                      <PrintIcon />
                    </div>
                    <div className="toggleButtonText">列印文件</div>
                  </div>
                )}
              />
              <div className="printButtonContainer mx-2" onClick={putEditedContent}>
                <div className="flex-center newTemplateButtonContainer">
                  <NewTemplateIcon />
                </div>
                <div className="toggleButtonText">儲存更改</div>
              </div>
            </Col>
          </Row>
          <div className="A4paper">
            <table ref={printRef} id="viewcoTable" style={{ width: "100%" }}>
              <thead>
                <div id="viewCoHeader" className="full-width flex-row-start viewCoHeader">
                  <ViewCoHeader />
                </div>
              </thead>
              <tbody className="flex-center" id="viewcoContent" style={{ width: "100%" }}>
                <div id="viewcoContent" style={{ width: "80%" }}>
                  {isSignatureNeeded && <h5 className="my-4 flex-center">{templates.templateData.docTypeName}</h5>}
                  {templates.templateData.docTypeName === "JOB CARD – MAINTENANCE AGREEMENT 維修保養工作單" ? (
                    <SelectedJobCardMaintenanceAgreement allFields={allFields} handleEdit={handleEdit} />
                  ) : (
                    allFields.map((item, idx) => {
                      return (
                        <tr
                          key={item.docFieldId}
                          className="my-1 d-flex formFields"
                          style={{ alignItems: "flex-start", pageBreakInside: "avoid" }}
                        >
                          <div
                            id={"fieldNameMargin"}
                            style={{
                              minWidth: "max-content",
                              color: "#888",
                            }}
                          >
                            {item.fieldName + " :"}
                          </div>
                          <TextareaAutosize
                            id={item.content ? "textAreaMargin" : "empty"}
                            className="inputStyle p-0 px-1 mx-1"
                            value={item.content}
                            onChange={(e) => handleEdit(idx, e.currentTarget.value)}
                          />
                        </tr>
                      );
                    })
                  )}
                  <div style={{ height: "40px" }} />
                  {/* {isSignatureNeeded && <SignatureSection />} */}
                  {isSignatureNeeded && <JobCompletionSignatureFields />}
                </div>
              </tbody>
              <tfoot>
                <div style={{ height: "100px" }}></div>
                <div id="viewCoFooter" className="full-width flex-row-start viewCoHeader">
                  <ViewCoFooter />
                </div>
              </tfoot>
            </table>
          </div>
        </Container>
      </Modal>
      <NewDocumentModal />
      <PreviewNewDocumentModal />
      {saveDocumentModalOpen && (
        <Modal size="md" isOpen={saveDocumentModalOpen}>
          <Container size="lg" className="p-4 d-flex flex-column justify-content-around" style={{ minHeight: "200px" }}>
            <Row>
              <Col className="flex-center">
                <h5>更改文件名稱</h5>
              </Col>
            </Row>
            <Row>
              <Col md={1} style={{ width: "max-content" }}>
                文件名稱 :
              </Col>
              <Col>
                <input
                  className="inputStyle"
                  value={templates.templateData.name ?? ""}
                  onChange={(e) => {
                    dispatch(setTemplateData({ ...templates.templateData, name: e.target.value }));
                  }}
                />
              </Col>
            </Row>
            <Row className="flex-center mt-3">
              <Col md={6} className="flex-center">
                <Button
                  style={{ width: "100%" }}
                  onClick={() => {
                    setSaveDocumentModalOpen(false);
                    dispatch(setTemplateData({ ...templates.templateData, name: originalName }));
                  }}
                >
                  取消
                </Button>
              </Col>
              <Col md={6} className="flex-center">
                <Button
                  disabled={originalName === templates.templateData.name}
                  color="primary"
                  style={{ width: "100%" }}
                  onClick={putEditDocumentName}
                >
                  儲存
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>
      )}
    </div>
  );
}

export default ServiceDocument;
