import "css/Document.css";
import React, { ReactNode } from "react";
import pFooter from "../../media/v-portrait-footer.png";
import image from "../../media/v-portrait-header.png";

function ViewCoHeader() {
  return <img className="mb-4 full-width" src={image} alt={"viewco header"} />;
}

export default ViewCoHeader;

export const ViewCoFooter = () => {
  return <img className="full-width" src={pFooter} alt={"viewco footer"}></img>;
};

interface IViewCoA4Paper {
  children: ReactNode;
}
export const ViewCoA4Paper = ({ children }: IViewCoA4Paper) => {
  return (
    <div id="eachPage">
      <div id="viewCoHeader" className="full-width flex-row-start viewCoHeader">
        <ViewCoHeader />
      </div>
      <div className="documentContent" id="documentContent">
        {children}
      </div>
      <div id="viewCoFooter" className="full-width flex-row-start viewCoHeader">
        <ViewCoFooter />
      </div>
    </div>
  );
};
