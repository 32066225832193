import axios from "axios";
import FormHeader from "components/parts/FormHeader";
import "css/AreaPage.css";
import { DISTRICTS, DistrictType } from "models/districtModels";
import { FetchedDistrict } from "models/responseModels";
import Layout from "pages/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setMessageModal } from "redux/Loading/action";

export default function AreaPage() {
  return (
    <Layout>
      <AreaManagement />
    </Layout>
  );
}

export const AreaManagement = () => {
  const [origin, setOrigin] = useState<keyof typeof areas | null>(null);
  const [selected, setSelected] = useState<number | null>(null);
  const [areas, setAreas] = useState<{
    defaultArea: DistrictType[];
    hkArea: DistrictType[];
    klnArea: DistrictType[];
    ntArea: DistrictType[];
    A區域: DistrictType[];
    B區域: DistrictType[];
    C區域: DistrictType[];
  }>({
    defaultArea: [],
    hkArea: [],
    klnArea: [],
    ntArea: [],
    A區域: [],
    B區域: [],
    C區域: [],
  });
  const dispatch = useDispatch();

  const handleSwitchToArea = (toDirection: keyof typeof areas) => {
    if (origin && selected !== null) {
      if (origin === toDirection || areas[toDirection].includes(areas[origin][selected])) return;
      const tempOriginArray = areas[origin].slice();
      const item = tempOriginArray.splice(selected, 1);
      const tempTargetArray = areas[toDirection].slice();
      tempTargetArray.push(item[0]);
      setAreas({ ...areas, [origin]: tempOriginArray, [toDirection]: tempTargetArray });
      setSelected(null);
      setOrigin(null);
    }
  };

  const fetchAllDistricts = async () => {
    try {
      const res = await axios.get<{ data: FetchedDistrict }>(`/static/districts`);
      const result = await res.data;
      setAreas({
        defaultArea: result.data.others,
        hkArea: result.data.HONG_KONG,
        klnArea: result.data.KOWLOON,
        ntArea: result.data.NEW_TERRITORIES,
        A區域: result.data.A區域,
        B區域: result.data.B區域,
        C區域: result.data.C區域,
      });
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const handleSubmit = async () => {
    const otherArray = areas.defaultArea.map((i) => ({
      district: i,
      area: null,
    }));
    const hkAreaArray = areas.hkArea.map((i) => ({
      district: i,
      area: "HONG_KONG",
    }));
    const klnAreaArray = areas.klnArea.map((i) => ({
      district: i,
      area: "KOWLOON",
    }));
    const ntAreaArray = areas.ntArea.map((i) => ({
      district: i,
      area: "NEW_TERRITORIES",
    }));
    const AAreaArray = areas.A區域.map((i) => ({
      district: i,
      area: "A區域",
    }));
    const BAreaArray = areas.B區域.map((i) => ({
      district: i,
      area: "B區域",
    }));
    const CAreaArray = areas.C區域.map((i) => ({
      district: i,
      area: "C區域",
    }));
    try {
      await axios.put(`/static/districts`, {
        districts: hkAreaArray
          .concat(klnAreaArray)
          .concat(ntAreaArray)
          .concat(otherArray as [])
          .concat(AAreaArray)
          .concat(BAreaArray)
          .concat(CAreaArray),
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      fetchAllDistricts();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  useEffect(() => {
    fetchAllDistricts();
  }, []);

  return (
    <div className="p-3 relative flex-column-center" style={{ overflowX: "hidden" }}>
      <FormHeader
        offset={false}
        header={"地區管理"}
        includeExtraButton
        children={
          <Button color="primary" className="w-40" onClick={handleSubmit}>
            儲存更改
          </Button>
        }
      />
      <div className="my-5 flex-column-center w-90">
        <section className="flex-column-start full-width relative districtSection">
          <h5 className="px-3 absolute" style={{ top: "-0.7em", left: 16, background: "#FFF" }}>
            尚未列入區域
          </h5>
          <Button
            color="primary"
            className={`px-3 absolute flex-center overflowButton ${
              (selected === null || origin === "defaultArea") && "disabledButton"
            }`}
            onClick={() => {
              handleSwitchToArea("defaultArea");
            }}
          >
            加入
          </Button>
          <div className="p-4 full-width flex-row-start" style={{ flexWrap: "wrap" }}>
            {!areas.defaultArea.length ? (
              <div className="flex-center full-width mt-2 pointer-events-none" style={{ color: "#AAA" }}>
                所有地區已分配區域
              </div>
            ) : (
              areas.defaultArea.map((item, idx) => {
                return (
                  <div
                    key={item}
                    className={`py-1 px-3 my-2 mx-1 flex-center areaTag ${
                      selected === idx && origin === "defaultArea" && "selected"
                    }`}
                    onClick={() => {
                      setSelected(idx);
                      setOrigin("defaultArea");
                    }}
                  >
                    {DISTRICTS[item]}
                  </div>
                );
              })
            )}
          </div>
        </section>
        <div className="mt-3 flex-row-around full-width align-items-start">
          <section className="flex1 my-4 flex-column-start relative full-width areaSection">
            <h5 className="px-3 absolute" style={{ top: "-0.7em", left: 16, background: "#FFF" }}>
              香港區域
            </h5>
            <Button
              color="primary"
              className={`px-3 w-20 absolute overflowButton ${
                (selected === null || origin === "hkArea") && "disabledButton"
              }`}
              onClick={() => {
                handleSwitchToArea("hkArea");
              }}
            >
              加入
            </Button>
            <div className="p-3 full-width flex-row-start" style={{ flexWrap: "wrap" }}>
              {!areas.hkArea.length ? (
                <div className="flex-center full-width mt-2 pointer-events-none" style={{ color: "#AAA" }}>
                  沒有列入地區
                </div>
              ) : (
                areas.hkArea.map((item, idx) => {
                  return (
                    <div
                      key={item}
                      className={`py-1 px-3 my-2 mx-1 flex-center areaTag ${
                        selected === idx && origin === "hkArea" && "selected"
                      }`}
                      onClick={() => {
                        setSelected(idx);
                        setOrigin("hkArea");
                      }}
                    >
                      {DISTRICTS[item]}
                    </div>
                  );
                })
              )}
            </div>
          </section>
          <section className="flex1 m-4 flex-column-start relative full-width areaSection">
            <h5 className="px-3 absolute" style={{ top: "-0.7em", left: 16, background: "#FFF" }}>
               九龍區域
            </h5>
            <Button
              color="primary"
              className={`px-3 w-20 absolute overflowButton ${
                (selected === null || origin === "klnArea") && "disabledButton"
              }`}
              onClick={() => handleSwitchToArea("klnArea")}
            >
              加入
            </Button>
            <div className="p-3 full-width flex-row-start" style={{ flexWrap: "wrap" }}>
              {!areas.klnArea.length ? (
                <div className="flex-center full-width mt-2 pointer-events-none" style={{ color: "#AAA" }}>
                  沒有列入地區
                </div>
              ) : (
                areas.klnArea.map((item, idx) => {
                  return (
                    <div
                      key={item}
                      className={`py-1 px-3 my-2 mx-1 flex-center areaTag ${
                        selected === idx && origin === "klnArea" && "selected"
                      }`}
                      onClick={() => {
                        setSelected(idx);
                        setOrigin("klnArea");
                      }}
                    >
                      {DISTRICTS[item]}
                    </div>
                  );
                })
              )}
            </div>
          </section>
          <section className="flex1 my-4 flex-column-start relative full-width areaSection">
            <h5 className="px-3 absolute" style={{ top: "-0.7em", left: 16, background: "#FFF" }}>
              新界區域
            </h5>
            <Button
              color="primary"
              className={`px-3 w-20 absolute overflowButton ${
                (selected === null || origin === "ntArea") && "disabledButton"
              }`}
              onClick={() => handleSwitchToArea("ntArea")}
            >
              加入
            </Button>
            <div className="p-3 full-width flex-row-start" style={{ flexWrap: "wrap" }}>
              {!areas.ntArea.length ? (
                <div className="flex-center full-width mt-2 pointer-events-none" style={{ color: "#AAA" }}>
                  沒有列入地區
                </div>
              ) : (
                areas.ntArea.map((item, idx) => {
                  return (
                    <div
                      key={item}
                      className={`py-1 px-3 my-2 mx-1 flex-center areaTag ${
                        selected === idx && origin === "ntArea" && "selected"
                      }`}
                      onClick={() => {
                        setSelected(idx);
                        setOrigin("ntArea");
                      }}
                    >
                      {DISTRICTS[item]}
                    </div>
                  );
                })
              )}
            </div>
          </section>
        </div>
        <div className="my-1 flex-row-around full-width align-items-start">
          <section className="flex1 my-4 flex-column-start relative full-width areaSection">
            <h5 className="px-3 absolute" style={{ top: "-0.7em", left: 16, background: "#FFF" }}>
              A 區域
            </h5>
            <Button
              color="primary"
              className={`px-3 w-20 absolute overflowButton ${
                (selected === null || origin === "A區域") && "disabledButton"
              }`}
              onClick={() => handleSwitchToArea("A區域")}
            >
              加入
            </Button>
            <div className="p-3 full-width flex-row-start" style={{ flexWrap: "wrap" }}>
              {areas.A區域.map((item, idx) => {
                return (
                  <div
                    key={item}
                    className={`py-1 px-3 my-2 mx-1 flex-center areaTag ${
                      selected === idx && origin === "A區域" && "selected"
                    }`}
                    onClick={() => {
                      setSelected(idx);
                      setOrigin("A區域");
                    }}
                  >
                    {DISTRICTS[item]}
                  </div>
                );
              })}
            </div>
          </section>
          <section className="flex1 m-4 flex-column-start relative full-width areaSection">
            <h5 className="px-3 absolute" style={{ top: "-0.7em", left: 16, background: "#FFF" }}>
              B 區域
            </h5>
            <Button
              color="primary"
              className={`px-3 w-20 absolute overflowButton ${
                (selected === null || origin === "B區域") && "disabledButton"
              }`}
              onClick={() => handleSwitchToArea("B區域")}
            >
              加入
            </Button>
            <div className="p-3 full-width flex-row-start" style={{ flexWrap: "wrap" }}>
              {areas.B區域.map((item, idx) => {
                return (
                  <div
                    key={item}
                    className={`py-1 px-3 my-2 mx-1 flex-center areaTag ${
                      selected === idx && origin === "B區域" && "selected"
                    }`}
                    onClick={() => {
                      setSelected(idx);
                      setOrigin("B區域");
                    }}
                  >
                    {DISTRICTS[item]}
                  </div>
                );
              })}
            </div>
          </section>
          <section className="flex1 my-4 flex-column-start relative full-width areaSection">
            <h5 className="px-3 absolute" style={{ top: "-0.7em", left: 16, background: "#FFF" }}>
              C 區域
            </h5>
            <Button
              color="primary"
              className={`px-3 w-20 absolute overflowButton ${
                (selected === null || origin === "C區域") && "disabledButton"
              }`}
              onClick={() => handleSwitchToArea("C區域")}
            >
              加入
            </Button>
            <div className="p-3 full-width flex-row-start" style={{ flexWrap: "wrap" }}>
              {areas.C區域.map((item, idx) => {
                return (
                  <div
                    key={item}
                    className={`py-1 px-3 my-2 mx-1 flex-center areaTag ${
                      selected === idx && origin === "C區域" && "selected"
                    }`}
                    onClick={() => {
                      setSelected(idx);
                      setOrigin("C區域");
                    }}
                  >
                    {DISTRICTS[item]}
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
