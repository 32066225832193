import EditClient from "components/EditClient";
import Layout from "./Layout";

export default function EditClientPage() {
  return (
    <Layout>
      <EditClient header="修改客戶資料" />
    </Layout>
  );
}
