import { AscIcon, DescIcon } from "components/IconsOnly";
import React from "react";

interface TableHeadersProps {
  tableWidth?: string;
  maxTableWidth?: string;
  headers: { headerText: string; headerKey: string }[];
  sortControl: (args0: string) => void;
  currentSorting?: { sortType: string; isDesc: boolean };
  isNewOrderMode?: boolean;
}

const longItem = ["地址", "電郵地址", "註解", "範本名稱", "文件名稱"];
const mainItem = ["所屬公司", "公司名稱", "文件類型名稱", "ACJ 號碼 / P.O. 號碼"];
const districtItem = ["地區", "居住區域", "部門", "區域"];
const actionItem = ["選擇", "動作", "排序"];
const dateItem = ["單編號", "建立日期", "更新日期", "開始日期", "完工日期", "加入日期"];
const clientNameItem = ["客戶", "客戶名稱"];
const shortItem = ["媒體"];

function SortableTableHeaders(props: TableHeadersProps) {
  const { headers, sortControl, currentSorting, isNewOrderMode = false } = props;

  return (
    <div className="flex-center tableHeader full-height">
      {headers.map((item, idx) => {
        if (longItem.includes(item.headerText)) {
          return (
            <div
              key={item.headerText + idx}
              className="flex-center thLongItem full-height pointer relative"
              onClick={() => sortControl(item.headerKey)}
              style={{ pointerEvents: isNewOrderMode ? "none" : "auto" }}
            >
              {item.headerText}
              {!isNewOrderMode && (
                <div className="flex-center p-0 absolute" style={{ maxHeight: "100%", right: "8px" }}>
                  {item.headerKey === currentSorting!.sortType && !currentSorting!.isDesc ? (
                    <AscIcon />
                  ) : item.headerKey === currentSorting!.sortType && currentSorting!.isDesc ? (
                    <DescIcon />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          );
        } else if (mainItem.includes(item.headerText)) {
          return (
            <div
              key={item.headerText + idx}
              className="flex-center thMainItem full-height pointer relative"
              onClick={() => sortControl(item.headerKey)}
              style={{ pointerEvents: isNewOrderMode ? "none" : "auto" }}
            >
              {item.headerText}
              {!isNewOrderMode && (
                <div className="flex-center p-0 absolute" style={{ maxHeight: "100%", right: "8px" }}>
                  {item.headerKey === currentSorting!.sortType && !currentSorting!.isDesc ? (
                    <AscIcon />
                  ) : item.headerKey === currentSorting!.sortType && currentSorting!.isDesc ? (
                    <DescIcon />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          );
        } else if (districtItem.includes(item.headerText)) {
          return (
            <div
              key={item.headerText + idx}
              className="flex-center thDistrict full-height pointer relative"
              onClick={() => sortControl(item.headerKey)}
              style={{ pointerEvents: isNewOrderMode ? "none" : "auto" }}
            >
              {item.headerText}
              {!isNewOrderMode && (
                <div className="flex-center p-0 absolute" style={{ maxHeight: "100%", right: "8px" }}>
                  {item.headerKey === currentSorting!.sortType && !currentSorting!.isDesc ? (
                    <AscIcon />
                  ) : item.headerKey === currentSorting!.sortType && currentSorting!.isDesc ? (
                    <DescIcon />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          );
        } else if (actionItem.includes(item.headerText)) {
          return (
            <div key={item.headerText + idx} className="flex-center thAction full-height relative">
              {item.headerText}
            </div>
          );
        } else if (shortItem.includes(item.headerText)) {
          return (
            <div
              key={item.headerText + idx}
              className="flex-center thShortItem full-height pointer relative"
              onClick={() => sortControl(item.headerKey)}
              style={{ pointerEvents: isNewOrderMode ? "none" : "auto" }}
            >
              {item.headerText}
              {!isNewOrderMode && (
                <div className="flex-center p-0 absolute" style={{ maxHeight: "100%", right: "8px" }}>
                  {item.headerKey === currentSorting!.sortType && !currentSorting!.isDesc ? (
                    <AscIcon />
                  ) : item.headerKey === currentSorting!.sortType && currentSorting!.isDesc ? (
                    <DescIcon />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          );
        } else if (dateItem.includes(item.headerText)) {
          return (
            <div
              key={item.headerText + idx}
              className="flex-center thDate full-height pointer relative"
              onClick={() => sortControl(item.headerKey)}
              style={{ pointerEvents: isNewOrderMode ? "none" : "auto" }}
            >
              {item.headerText}
              {!isNewOrderMode && (
                <div className="flex-center p-0 absolute" style={{ maxHeight: "100%", right: "8px" }}>
                  {item.headerKey === currentSorting!.sortType && !currentSorting!.isDesc ? (
                    <AscIcon />
                  ) : item.headerKey === currentSorting!.sortType && currentSorting!.isDesc ? (
                    <DescIcon />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          );
        } else if (clientNameItem.includes(item.headerText)) {
          return (
            <div
              key={item.headerText + idx}
              className="flex-center thClientName full-height pointer relative"
              onClick={() => sortControl(item.headerKey)}
              style={{ pointerEvents: isNewOrderMode ? "none" : "auto" }}
            >
              {item.headerText}
              {!isNewOrderMode && (
                <div className="flex-center p-0 absolute" style={{ maxHeight: "100%", right: "8px" }}>
                  {item.headerKey === currentSorting!.sortType && !currentSorting!.isDesc ? (
                    <AscIcon />
                  ) : item.headerKey === currentSorting!.sortType && currentSorting!.isDesc ? (
                    <DescIcon />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          );
        }
        return (
          <div
            key={item.headerText + idx}
            className="flex-center thItem full-height pointer relative"
            onClick={() => sortControl(item.headerKey)}
            style={{ pointerEvents: isNewOrderMode ? "none" : "auto" }}
          >
            {item.headerText}
            {!isNewOrderMode && (
              <div className="flex-center p-0 absolute" style={{ right: "8px" }}>
                {item.headerKey === currentSorting!.sortType && !currentSorting!.isDesc ? (
                  <AscIcon />
                ) : item.headerKey === currentSorting!.sortType && currentSorting!.isDesc ? (
                  <DescIcon />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default SortableTableHeaders;
