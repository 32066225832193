import axios from "axios";
import { MoveDownIcon, MoveUpIcon } from "components/IconsOnly";
import FormHeader from "components/parts/FormHeader";
import TableHeaders from "components/parts/TableHeaders";
import { push } from "connected-react-router";
import globalStyle from "css/global.module.scss";
import { IDocumentById, IDocumentTypesById } from "models/responseModels";
import { DivisionType } from "models/variables";
import { useEffect, useState } from "react";
import { ImBin } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setChangesMade } from "redux/Document/action";
import { IRootState } from "store";

interface IBasicData {
  header: string;
}

interface NewDoc {
  name: string;
  formNo: string;
  description: string;
  division: DivisionType | null;
}

// const TABLE_WIDTH = "70%";

export default function BasicData({ header }: IBasicData) {
  const [content, setContent] = useState<IDocumentById[]>([]);
  const [modal, setModal] = useState(false);
  const [editDocType, setEditDocType] = useState<NewDoc>({
    name: "",
    formNo: "",
    description: "",
    division: null,
  });
  const pathname = window.location.pathname.split("/");
  const documentId = pathname[pathname.length - 1];
  const dispatch = useDispatch();
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);

  useEffect(() => {
    const fetchDocumentById = async () => {
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/doc-types`);
        url.searchParams.set("id", String(documentId));
        const res = await axios.get<IDocumentTypesById>(url.toString());
        const result = await res.data;
        setEditDocType({
          name: result.name,
          formNo: result.formNo,
          description: result.description,
          division: result.division,
        });
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    };
    fetchDocumentById();
  }, []);

  useEffect(() => {
    const fetchAllFields = async () => {
      try {
        const res = await axios.get(`/doc-type-fields/${documentId}`);
        const result = res.data.data;
        setContent(result);
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    };
    fetchAllFields();
  }, []);

  const handleDeleteRow = (idx: number) => {
    const newArr = content.slice();
    newArr.splice(idx, 1);
    setContent(newArr);
  };

  const setFieldOrders = () => {
    const newArr = content.slice();
    for (let i = 0; i < newArr.length; i++) {
      content[i].fieldOrder = i + 1;
    }
    setContent(newArr);
  };

  const handleSave = async () => {
    if (content.length === 0) {
      return;
    }
    setFieldOrders();
    try {
      await axios.put(`/doc-types/${documentId}`, { docType: editDocType });
      for (const i of content) {
        await axios.put(`/doc-type-fields/${i.id}`, {
          field: { fieldName: i.fieldName, fieldOrder: i.fieldOrder },
        });
      }
      dispatch(setChangesMade(true));
      setModal(false);
      dispatch(push("/admin/document"));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const handleMoveUp = (idx: number) => {
    if (idx === 0) return;
    const newArr = content.slice();
    newArr.splice(idx - 1, 0, ...newArr.splice(idx, 1));
    setContent(newArr);
  };
  const handleMoveDown = (idx: number) => {
    if (idx === content.length - 1) return;
    const newArr = content.slice();
    newArr.splice(idx + 1, 0, ...newArr.splice(idx, 1));
    setContent(newArr);
  };

  const headers = ["排序", "資料欄顯示", "動作"];
  const hasContent = content.length > 0;

  return (
    <Container className="p-3" style={{ overflowY: "scroll", maxHeight: "90vh" }}>
      <FormHeader offset={false} header={header} />
      <div className="viewCoTable" style={{ width: "100%" }}>
        <TableHeaders headers={headers} />
        <div className="tableBody">
          {isLoading ? (
            <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
          ) : (
            content &&
            content.map((element, idx) => {
              return (
                <div key={idx} className="flex-center documentTableRow" style={{ position: "relative" }}>
                  <div className="flex-row-around tdActionContainer">{idx + 1 || "-"}</div>
                  <div className="flex-center tdItem">{element.fieldName || "-"}</div>
                  <div className="flex-row-around tdActionContainer">
                    <div className={globalStyle["moveButton"]} onClick={() => handleMoveUp(idx)}>
                      <MoveUpIcon color={idx === 0 ? "#AAA" : "#444"} />
                    </div>
                    <div className={globalStyle["moveButton"]} onClick={() => handleMoveDown(idx)}>
                      <MoveDownIcon color={idx === content.length - 1 ? "#AAA" : "#444"} />
                    </div>
                    <div className={globalStyle["moveButton"]} onClick={() => handleDeleteRow(idx)}>
                      <ImBin size={24} />
                    </div>
                  </div>
                  <div className="d-flex" style={{ position: "absolute", right: 0 }}></div>
                </div>
              );
            })
          )}
        </div>
      </div>

      <Row className="my-4 pb-5 justify-content-center">
        <Button style={{ width: "70%" }} color={"primary"} disabled={!hasContent} onClick={() => setModal(!modal)}>
          儲存更改
        </Button>
      </Row>

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader>文件類型名稱</ModalHeader>
        <ModalBody>
          <Row className="align-items-center">
            <Col md={3} className="my-2 d-flex align-items-center">
              <Label>文件編號</Label>
            </Col>
            <Col>
              <input
                style={{ width: "100%" }}
                value={editDocType.formNo}
                onChange={(e) => setEditDocType({ ...editDocType, formNo: e.target.value })}
              />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={3} className="my-2 d-flex align-items-center">
              <Label>文件類型名稱</Label>
            </Col>
            <Col>
              <input
                style={{ width: "100%" }}
                value={editDocType.name}
                onChange={(e) => setEditDocType({ ...editDocType, name: e.target.value })}
              />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={3} className="my-2 d-flex align-items-center">
              <Label>描述</Label>
            </Col>
            <Col>
              <input
                style={{ width: "100%" }}
                value={editDocType.description}
                onChange={(e) => setEditDocType({ ...editDocType, description: e.target.value })}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            儲存
          </Button>
          <Button color="secondary" onClick={() => setModal(!modal)}>
            取消
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}
