import axios from "axios";
import ViewCoHeader, { ViewCoFooter } from "components/parts/ViewcoHeader";
import "css/Document.css";
import { IDocumentTypesById } from "models/responseModels";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setDocumentData } from "redux/Document/action";
import { setMessageModal } from "redux/Loading/action";
import { IRootState } from "store";

interface DocFields {
  id: number;
  fieldName: string;
  fieldOrder: number;
  content: string;
}

function NewTemplate() {
  const printRef = useRef<HTMLTableElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [allFields, setAllFields] = useState<Array<DocFields>>([]);
  const pathname = window.location.pathname.split("/");
  const docTypeId = pathname[pathname.length - 2];
  const history = useHistory();
  const dispatch = useDispatch();
  const documentData = useSelector((state: IRootState) => state.documents.documentData);

  useEffect(() => {
    const fetchDocumentById = async () => {
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/doc-types`);
        url.searchParams.set("id", String(docTypeId));
        const res = await axios.get<IDocumentTypesById>(url.toString());
        const result = await res.data;
        dispatch(
          setDocumentData({
            formNo: result.formNo,
            name: result.name,
            division: result.division,
            docCount: result.docCount,
            description: result.description,
          })
        );
      } catch (e) {
        dispatch(handleAxiosError(e));
      }
    };
    fetchDocumentById();
  }, []);

  useEffect(() => {
    const fetchAllFields = async () => {
      try {
        const res = await axios.get(`/doc-type-fields/${docTypeId}`);
        const result = res.data.data;
        setAllFields(
          result.map((i: DocFields) => {
            return { ...i, content: "" };
          })
        );
      } catch (e) {
        dispatch(handleAxiosError(e));
      }
    };
    fetchAllFields();
    return () => {
      setAllFields([]);
      setDocumentName("");
    };
  }, []);

  const handlePostDocContent = async () => {
    try {
      const res = await axios.post(`/doc`, {
        docInfo: {
          name: documentName,
          docTypeId: docTypeId,
        },
      });
      const documentId = await res.data.id;
      const docFields = allFields.map((i) => {
        return { content: i.content, docFieldId: i.id };
      });
      await axios.post(`/doc-content/${documentId}`, {
        docFields: docFields,
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      handleExit();
    } catch (e) {
      dispatch(handleAxiosError(e));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  const handleEditContent = (idx: number, input: string) => {
    const newArr = allFields.slice();
    newArr[idx] = {
      ...allFields[idx],
      content: input,
    };
    setAllFields(newArr);
  };

  const handleExit = () => {
    setModalIsOpen(false);
    setDocumentName("");
  };

  return (
    <Container className="p-4 flex-column-start viewDocumentPageContainer">
      <Row className="mb-3 flex-row-between viewDocumentPageTopRow">
        <Col md={4} className="d-flex align-items-center">
          <FaArrowCircleLeft size={32} onClick={() => history.goBack()} className="pointer" />
        </Col>
        <Col md={4}>
          <div className="d-flex justify-content-center">
            <h5 style={{ marginBottom: 0, textAlign: "center" }}>
              {"從 " + documentData.formNo + " 建立範本"}
              <br />
              {documentData.name}
            </h5>
          </div>
        </Col>
        <Col md={4} />
      </Row>
      <div className="A4paper">
        <table ref={printRef} id="viewcoTable" style={{ width: "100%" }}>
          <thead>
            <div id="viewCoHeader" className="full-width flex-row-start viewCoHeader">
              <ViewCoHeader />
            </div>
          </thead>

          <tbody className="flex-center" id="viewcoContent" style={{ width: "100%" }}>
            <div id="viewcoContent" style={{ width: "80%" }}>
              {allFields.map((item, idx) => {
                return (
                  <div key={item.id} className="d-flex align-items-center formFields">
                    <div
                      style={{
                        minWidth: "max-content",
                        color: "#888",
                        paddingTop: "2px",
                        alignSelf: "flex-start",
                      }}
                    >
                      {item.fieldName + " :"}
                    </div>
                    <TextareaAutosize
                      className="inputStyle"
                      value={item.content}
                      style={{ marginLeft: "8px" }}
                      onChange={(e) => handleEditContent(idx, e.currentTarget.value)}
                    />
                  </div>
                );
              })}
              <div style={{ height: "40px" }} />
            </div>
          </tbody>
          <tfoot>
            <div style={{ height: "100px" }}></div>
            <div id="viewCoFooter" className="full-width flex-row-start viewCoHeader">
              <ViewCoFooter />
            </div>
          </tfoot>
        </table>
      </div>

      <Row className="my-3 flex-center" style={{ width: "70%" }}>
        <Col className="flex-center">
          <Button style={{ width: "40%" }} onClick={() => setModalIsOpen(true)}>
            確定
          </Button>
        </Col>
      </Row>
      <Modal isOpen={modalIsOpen}>
        <Container className="p-4">
          <Row className="my-3">
            <div className="my-2">文件名稱</div>
            <input
              value={documentName}
              onChange={(e) => {
                setDocumentName(e.target.value);
              }}
            />
          </Row>
          <Row className="flex-center">
            <Col className="flex-row-around">
              <Button style={{ width: "40%" }} onClick={() => setModalIsOpen(false)}>
                取消
              </Button>
              <Button disabled={!documentName} color="primary" style={{ width: "40%" }} onClick={handlePostDocContent}>
                儲存
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal>
    </Container>
  );
}

export default NewTemplate;
