import React from "react";
import ReactApexChart from "react-apexcharts";

interface IStackedColumns {
  series: { name: string; data: number[] }[];
  height: string;
  width: string;
  xAxis: {
    type: string;
    categories: string[];
  };
}

const StackedColumns: React.FC<IStackedColumns> = (props) => {
  const options = {
    chart: {
      type: "bar",
      height: props.height,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    xaxis: props.xAxis,
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <>
      {/* @ts-ignore */}
      <ReactApexChart type="bar" options={options} series={props.series} width={props.width} height={props.height} />
    </>
  );
};

export default StackedColumns;
