import CompanyList from "components/parts/CompanyList";
import Layout from "pages/Layout";

export default function CompanyPage() {
  return (
    <Layout>
      <CompanyList />
    </Layout>
  );
}
