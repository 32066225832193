import NewClient from "components/NewClient";
import Layout from "./Layout";

export default function NewClientPage() {
  return (
    <Layout>
      <NewClient />
    </Layout>
  );
}
